/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '../styles/Favorites.module.css';
import MerchantCard from './MerchantCard';
import getImageURL from '../utils/getImageURL';
import FavoriteCoupon from './FavoriteCoupon';
import ApartmentCard from './ApartmentCard';

export default function FavoritesTabs({
  selectedTab,
  coupons,
  merchants,
  properties,
  openModal,
}) {
  const [activeTab, setActiveTab] = useState(selectedTab || 'businesses');
  const merchantsById = useSelector((state) => state.merchants.indexById);
  const couponsById = useSelector((state) => state.coupons.indexById);
  const propertiesById = useSelector((state) => state.properties.indexById);

  const renderMerchantCard = (favorite) => {
    const merchant = merchantsById[favorite.itemID] || null;
    if (merchant) {
      return (
        <div
          key={merchant.id}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <MerchantCard
            merchantId={merchant.id}
            key={merchant.id}
            name={merchant.name}
            logo={getImageURL(merchant.logo.key)}
            schedule={merchant.schedule}
            phone={merchant.phone}
            address={merchant.address}
            deliveryWebsite={merchant.deliveryWebsite}
            icon="isFavorite"
            category={merchant.categories.items[0].category.name}
            coupons={merchant.coupons}
            type={merchant.type}
          />
        </div>
      );
    }
    return null;
  };

  const renderCouponCard = (favorite) => {
    const coupon = couponsById[favorite.itemID] || null;
    if (coupon) {
      return (
        <div
          key={coupon.id}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <FavoriteCoupon
            couponId={coupon.id}
            key={coupon.id}
            name={coupon.merchant.name}
            title={coupon.title}
            description={coupon.content}
            disclaimer={coupon.disclaimer}
            logo={getImageURL(coupon.merchant.logo.key)}
            icon="isFavorite"
            // category={coupon.merchant.categories.items[0].category.name}
            redeemAction={openModal}
          />
        </div>
      );
    }
    return null;
  };

  const renderPropertyCard = (favorite) => {
    const property = propertiesById[favorite.itemID] || null;
    if (property) {
      return (
        <div key={property.id} style={{}}>
          <ApartmentCard
            id={property.id}
            key={property.id}
            merchantName={property.manager.name}
            address={`${property.address.street}, ${property.address.city}`}
            bedrooms={property.units.items[0].bedrooms}
            bathrooms={property.units.items[0].fullBaths}
            priceRange={
              property.minRent === property.maxRent
                ? `$${property.minRent}`
                : `$${property.minRent} - ${property.maxRent}`
            }
            backgroundImage={
              !property.usesAppFolio
                ? (property.images.items[0] &&
                    property.images.items.length > 0) ||
                  property.coverImageKey
                  ? getImageURL(
                      property.coverImageKey || property.images.items[0].key,
                      500,
                      'cover'
                    )
                  : '/images/default-property.png'
                : property.photoUrls[0]
            }
          />
        </div>
      );
    }
    return null;
  };
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabsHeader}>
        <button
          type="button"
          className={`${styles.tabTitle} ${
            activeTab === 'businesses' ? styles.active : ''
          }`}
          onClick={() => setActiveTab('businesses')}
        >
          Businesses
        </button>
        <button
          type="button"
          className={`${styles.tabTitle} ${
            activeTab === 'offers' ? styles.active : ''
          }`}
          onClick={() => setActiveTab('offers')}
        >
          Offers
        </button>
        <button
          type="button"
          className={`${styles.tabTitle} ${
            activeTab === 'housing' ? styles.active : ''
          }`}
          onClick={() => setActiveTab('housing')}
        >
          Housing
        </button>
      </div>

      <div
        className={styles.tabContent}
        style={{ backgroundColor: 'transparent' }}
      >
        {activeTab === 'businesses' && (
          <div className={styles.grid}>
            {merchants && merchants.length > 0 ? (
              <>{merchants.map((merchant) => renderMerchantCard(merchant))}</>
            ) : (
              <div className={styles.noData}>
                <img src="/icons/noMerchants.svg" alt="" width="40px" />
                <p>
                  You haven&apos;t saved any favorite merchants yet.
                  <br />
                  Simply click on the heart located next to any business name
                  and we&apos;ll save it to this favorite&apos;s page for you.
                </p>
              </div>
            )}
          </div>
        )}
        {activeTab === 'offers' && (
          <div className={styles.grid}>
            {coupons && coupons.length > 0 ? (
              <>{coupons.map((coupon) => renderCouponCard(coupon))}</>
            ) : (
              <div className={styles.noData}>
                <img src="/icons/noMerchants.svg" alt="" width="40px" />
                <p>
                  You haven&apos;t saved any favorite offers yet.
                  <br />
                  Simply click on the heart located within any offer and
                  we&apos;ll save it to this favorite&apos;s page for you.
                </p>
              </div>
            )}
          </div>
        )}
        {activeTab === 'housing' && (
          <div className={styles.couponGrid}>
            {properties ? (
              <>{properties.map((p) => renderPropertyCard(p))}</>
            ) : (
              <div className={styles.noData}>
                <img src="/icons/noMerchants.svg" alt="" width="40px" />
                <p>
                  You haven&apos;t saved any favorite properties yet.
                  <br />
                  Simply click on the white heart located next to any property
                  and we&apos;ll save it to this favorite&apos;s page for you.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

FavoritesTabs.propTypes = {
  selectedTab: PropTypes.string,
  coupons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  merchants: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  openModal: PropTypes.func.isRequired,
  properties: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

FavoritesTabs.defaultProps = {
  selectedTab: 'businesses',
};
