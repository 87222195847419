import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from '@material-ui/core';
import { outboundLinks, storeLinks } from '../globalConfig';
import styles from '../styles/Favorites.module.css';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import useTitle from '../hooks/useTitle';

const Subscribe = () => {
  const router = useHistory();
  const { profile } = useSelector((state) => state.auth);
  const [modalOpen, setOpen] = useState(false);

  useTitle('Subscribe');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openButton = () => {
    return (
      <button
        type="button"
        className={styles.subLink}
        onClick={handleOpen}
        style={{
          outline: 'none',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '0',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          cursor: 'pointer',
        }}
      >
        here
      </button>
    );
  };

  return (
    <div className={styles.sub2Container}>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={styles.modal}
        open={modalOpen}
        onClose={handleClose}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.loginModal}>
          <h1>Must Sign In</h1>
          <div style={{ padding: '1rem', margin: '0rem' }}>
            <p className={styles.modalP}>
              Must be signed in to use this feature.
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '90%',
              marginTop: '-0.25rem',
              fontWeight: '600',
            }}
          >
            <Link
              to={`/auth?formType=signIn&prev=${encodeURIComponent(
                `${router.location.pathname}${router.location.search}`
              )}`}
              className={styles.modalLink}
              style={{ marginRight: '1rem' }}
            >
              LOG IN HERE
            </Link>
            <p
              className={styles.closeModal}
              onClick={handleClose}
              aria-hidden="true"
            >
              NOT NOW
            </p>
          </div>
        </div>
      </Modal>
      <div
        className={styles.header}
        style={{ marginLeft: '1.5rem', marginBottom: '-0.16rem' }}
      >
        <div className={styles.logo}>
          <img alt="" src="/icons/getMag.svg" width="56px" />
        </div>
        <div className={styles.slogan}>
          <p className={styles.title} style={{ color: 'var(--saver-red)' }}>
            Looking for exclusive deals from the best campus businesses?
          </p>
          <span style={{ fontWeight: '600' }}>WE&apos;VE GOT YOU COVERED!</span>
        </div>
      </div>

      <div className={styles.subContent}>
        <p className={styles.subContentInfo}>
          Click{' '}
          {profile ? (
            <Link to="/profile" className={styles.subLink}>
              here
            </Link>
          ) : (
            openButton()
          )}{' '}
          to get special offers from The Saver or update your profile settings .
          <br />
          <span style={{ fontSize: 'small', fontStyle: 'italic' }}>
            Don’t worry, all your information remains only with us. See our
            <a
              href={outboundLinks.termsAndConditions}
              target="_blank"
              rel="noreferrer"
              className={styles.subPrivacy}
              onClick={() =>
                logAnalyticsEvent({
                  label: 'Terms and Conditions',
                  action: ACTIONS.CLICK,
                  category: CATEGORIES.EXTERNAL_LINK,
                })
              }
            >
              Terms and Conditions
            </a>{' '}
            and
            <a
              href={outboundLinks.privacyPolicy}
              target="_blank"
              rel="noreferrer"
              className={styles.subPrivacy}
              onClick={() =>
                logAnalyticsEvent({
                  label: 'Privacy Policy',
                  action: ACTIONS.CLICK,
                  category: CATEGORIES.EXTERNAL_LINK,
                })
              }
            >
              Privacy Policies
            </a>
            .
          </span>
          <br /> <br /> <br />
          <h2 className={styles.sectionTitle}>
            LOOKING FOR OUR PRINTED MAGAZINE?
          </h2>
          <br />
          The Saver Magazine is published at the beginning of Fall & Spring
          Semesters. <br /> Didn’t receive yours in the mail?
          <br />
          You can pick up a copy at:
          <br /> <br />
          <strong>The main entrance of The Ohio Union</strong>
          <br />
          <a
            href="https://www.google.com/maps/place/1739+N+High+St,+Columbus,+OH+43210,+USA/@39.99772,-83.0107637,17z/data=!3m1!4b1!4m5!3m4!1s0x88388eb8cc64683d:0xd72265651851c9bd!8m2!3d39.99772!4d-83.008575"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            1739 North High Street
          </a>
          <br />
          Columbus, OH 43210
          <br /> <br />
          <span style={{ fontSize: 'small' }}>or at</span>
          <br /> <br />
          <strong>The Off-Campus & Commuter Student Engagement Office</strong>
          <br /> 3106 Ohio Union
          <br />{' '}
          <a
            href="https://www.google.com/maps/place/1739+N+High+St,+Columbus,+OH+43210,+USA/@39.99772,-83.0107637,17z/data=!3m1!4b1!4m5!3m4!1s0x88388eb8cc64683d:0xd72265651851c9bd!8m2!3d39.99772!4d-83.008575"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            1739 North High Street
          </a>
          <br /> Columbus, OH 43210
          <br /> <br />
          <strong className={styles.sectionTitle}>
            FOR OUR BEST USER EXPERIENCE WE SUGGEST DOWNLOADING OUR APP AT
          </strong>
        </p>

        <div className={styles.btnHolder}>
          <a
            type="button"
            href={storeLinks.apple}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              logAnalyticsEvent({
                label: storeLinks.apple,
                action: ACTIONS.CLICK,
                category: CATEGORIES.APP_LINKS,
              })
            }
          >
            <img alt="" src="/icons/appStoreBlack.svg" />
          </a>
          <a
            type="button"
            href="https://play.google.com/store/apps/details?id=com.saverapp&hl=en&gl=US"
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              logAnalyticsEvent({
                label: storeLinks.android,
                action: ACTIONS.CLICK,
                category: CATEGORIES.APP_LINKS,
              })
            }
          >
            <img alt="" src="/icons/playstoreBlack.svg" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
