import { Link } from 'react-router-dom';
import FooterElement from './FooterElement';
import FooterImage from './FooterImage';
import styles from '../styles/Footer.module.css';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { outboundLinks } from '../globalConfig';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';

const elements = [
  {
    path: `/offers`,
    dataTestId: 'offers-footer-link',
    label: 'See All Our Offers',
  },
  {
    path: '/magazine',
    dataTestId: 'magazine-footer-link',
    label: 'Digital Magazine',
  },
  {
    path: '/about',
    dataTestId: 'about-us-footer-link',
    label: 'About Us',
  },
  {
    path: '/contact',
    dataTestId: 'contact-us-footer-link',
    label: 'Contact Us',
  },
  {
    path: '/subscribe',
    dataTestId: 'subscribe-footer-link',
    label: 'Subscribe',
  },
];

const storeImages = [
  {
    link: 'https://apps.apple.com/us/app/the-saver/id1528921619',
    src: '/icons/appStore.svg',
    dataTestId: 'appleStore',
    className: '',
  },
  {
    link: 'https://play.google.com/store/apps/details?id=com.saverapp&hl=en&gl=US',
    src: '/icons/playStore.svg',
    dataTestId: 'playStore',
    className: styles.rightStore,
  },
];

const socialMediaImages = [
  {
    link: 'https://twitter.com/thesaverosu',
    src: '/icons/twitter.svg',
    dataTestId: 'twitterIcon',
    title: 'Twitter',
    className: styles.socialIcons,
  },
  {
    link: 'https://www.facebook.com/thesaverosu',
    src: '/icons/facebook.svg',
    dataTestId: 'facebookIcon',
    title: 'Facebook',
    className: styles.socialIcons,
  },
  {
    link: 'https://www.instagram.com/thesaverosu/',
    src: '/icons/instagram.svg',
    dataTestId: 'instagramIcon',
    title: 'Instagram',
    className: styles.socialIcons,
  },
];

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className={styles.content}>
      <h1 data-testid="experience-h1">
        For an even better experience download The Saver App now
      </h1>
      <div className={styles.banner}>
        <div>
          <img
            src="/images/phone.svg"
            className={styles.phone}
            alt=""
            data-testid="phone"
          />
        </div>

        <div className={styles.bannerContent} data-testid="banner-content">
          <div className={styles.stores}>
            {storeImages.map(({ src, dataTestId, link, className }) => (
              <FooterImage
                key={dataTestId}
                src={src}
                dataTestId={dataTestId}
                link={link}
                className={className}
              />
            ))}
          </div>
        </div>
      </div>

      <div className={styles.footerWrapper} data-testid="footer">
        <div className={styles.footerContent}>
          <div className={styles.logoContainer}>
            <Link to="/">
              <img
                alt="saverLogo"
                src="/icons/saverLogo.svg"
                width="113px"
                height="48.3px"
                data-testid="saver-logo"
              />
            </Link>
          </div>
          <div className={styles.optionsContainer}>
            <div className={styles.footerElements}>
              {elements.map(({ path, dataTestId, label }) => (
                <FooterElement
                  key={dataTestId}
                  path={path}
                  dataTestId={dataTestId}
                  label={label}
                />
              ))}
            </div>

            <div className={styles.icons}>
              <p data-testid="follow-us-section">Follow us</p>
              <div>
                {socialMediaImages.map(
                  ({ src, link, title, dataTestId, className }) => (
                    <FooterImage
                      key={dataTestId}
                      src={src}
                      link={link}
                      title={title}
                      dataTestId={dataTestId}
                      className={className}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.copyrightHolder}
          data-testid="copyright-declaration"
        >
          <p>
            Copyright {year} Smart Dollar Advertising, Inc. |{' '}
            <a
              href={outboundLinks.privacyPolicy}
              target="_blank"
              rel="noreferrer"
              className={styles.privacyLink}
              onClick={() =>
                logAnalyticsEvent({
                  label: outboundLinks.privacyPolicy,
                  action: ACTIONS.CLICK,
                  category: CATEGORIES.EXTERNAL_LINK,
                })
              }
            >
              Privacy Policy
            </a>{' '}
            |{' '}
            <a
              href={outboundLinks.termsAndConditions}
              target="_blank"
              rel="noreferrer"
              className={styles.privacyLink}
              onClick={() =>
                logAnalyticsEvent({
                  label: outboundLinks.termsAndConditions,
                  action: ACTIONS.CLICK,
                  category: CATEGORIES.EXTERNAL_LINK,
                })
              }
            >
              Terms and Conditions
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
