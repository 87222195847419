import { API } from 'aws-amplify';

export default async function requestAll(
  requestFunction,
  requestFunctionName,
  variables = {},
  authMode = 'API_KEY'
) {
  const requestedItems = async (
    data = {
      items: [],
      nextToken: null,
      first: true,
    }
  ) => {
    if (!data.nextToken && !data.first) {
      return data.items;
    }
    const request = await API.graphql({
      query: requestFunction,
      variables: {
        ...variables,
        nextToken: data.nextToken,
      },
      authMode,
    });
    return requestedItems({
      items: [...data.items, ...request.data[requestFunctionName].items],
      nextToken: request.data[requestFunctionName].nextToken,
      first: false,
    });
  };
  return requestedItems();
}
