const authActionsTypes = {
  // SIGN IN
  SIGN_IN_START: 'SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAIL: 'SIGN_IN_FAIL',
  SIGN_IN_SMS_PHONE_NUMBER: 'SIGN_IN_SMS_PHONE_NUMBER',
  SIGN_IN_INTERRUPT: 'SIGN_IN_INTERRUPT',

  // SIGN OUT
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAIL: 'SIGN_OUT_FAIL',

  // SESSION
  GET_SESSION_START: 'GET_SESSION_START',
  GET_SESSION_SUCCESS: 'GET_SESSION_SUCCESS',
  GET_SESSION_FAIL: 'GET_SESSION_FAIL',

  // SIGN UP
  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_CONFIRMATION_CODE_SENT: 'SIGN_UP_CONFIRMATION_CODE_SENT',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAIL: 'SIGN_UP_FAIL',
};
export default authActionsTypes;
