import actionTypes from '../constants/actionTypes';

export const initialState = {
  items: [],
  editItem: null,
  loading: false,
  error: false,
  listedByMerchant: {},
  indexById: {},
  filtered: [],
};

function propertiesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SEARCH_PROPERTIES_START:
    case actionTypes.FILTER_PROPERTIES_START:
    case actionTypes.GET_PROPERTIES_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.GET_PROPERTIES_SUCCESS: {
      const byIdTable = action.payload.properties.reduce((obj, property, i) => {
        obj[property.id] = property;
        return obj;
      }, {});
      return {
        ...state,
        items: action.payload.properties,
        listedByMerchant: action.payload.table,
        indexById: byIdTable,
        filtered: action.payload.properties,
        loading: false,
        error: false,
      };
    }
    case actionTypes.SEARCH_PROPERTIES_SUCCESS:
    case actionTypes.FILTER_PROPERTIES_SUCCESS: {
      return {
        ...state,
        filtered: action.payload,
        loading: false,
        error: false,
      };
    }
    case actionTypes.RESET_FILTERS_PROPERTIES: {
      return {
        ...state,
        filtered: state.items,
        loading: false,
        error: false,
      };
    }
    case actionTypes.SELECT_MERCHANT_PROPERTIES: {
      return {
        ...state,
        filtered: state.listedByMerchant[action.payload],
        loading: false,
        error: false,
      };
    }
    case actionTypes.SEARCH_PROPERTIES_FAIL:
    case actionTypes.FILTER_PROPERTIES_FAIL:
    case actionTypes.GET_PROPERTIES_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}

export default propertiesReducer;
