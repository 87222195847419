import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, CircularProgress } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import {
  addItemToFavoritesAction,
  getFavoritesAction,
  removeItemFromFavoritesAction,
} from '../redux/actions/favoriteActions';
import styles from '../styles/Navbar.module.css';
import FavoriteIcon from './FavoriteIcon';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';

function FavoriteItem({ id, type, name }) {
  const dispatch = useDispatch();
  const router = useHistory();
  const { profile } = useSelector((state) => state.auth);
  const favoritesByItemId = useSelector(
    (state) => state.favorites.indexByItemId
  );
  const favorites = useSelector((state) => state.favorites);
  const [isFavorite, setFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFavorite(favoritesByItemId ? Boolean(favoritesByItemId[id]) : false);
  }, [id, favoritesByItemId]);

  const toggleFavorite = async () => {
    if (isFavorite) {
      const ids = favorites.rawList
        .filter(
          (item) =>
            item.itemID === favoritesByItemId[id].itemID &&
            item.customerID === profile.id
        )
        .map((item) => item.id);
      setLoading(true);
      // eslint-disable-next-line no-restricted-syntax
      for (const currentId of ids) {
        // eslint-disable-next-line no-await-in-loop
        dispatch(removeItemFromFavoritesAction(currentId, profile.id));
      }
      setLoading(false);
      dispatch(getFavoritesAction(profile.id));
      setFavorite(false);
    } else {
      dispatch(addItemToFavoritesAction(id, profile.id, type));
      dispatch(getFavoritesAction(profile.id));
      setFavorite(true);
    }
  };

  const dispatchAction = () => {
    if (profile) {
      toggleFavorite();
      // Commenting for now because we are sending events on clicks outside of this, might be useful to move them to here
      // logAnalyticsEvent({
      //   event: 'Merchant_Page',
      //   label: `Heart_tab: ${name}`,
      //   action: 'Heart_tab',
      //   category: 'Business Info',
      // });
    } else {
      handleOpen();
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={styles.modal}
        open={modalOpen}
        onClose={handleClose}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.loginModal}>
          <h1>Must Sign In</h1>
          <div style={{ padding: '1rem', margin: '0rem' }}>
            <p className={styles.modalP}>
              You need to sign in to create your favorites.
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '90%',
              marginTop: '-0.25rem',
              fontWeight: '700',
            }}
          >
            <Link
              to={`/auth?formType=signIn&prev=${encodeURIComponent(
                `${router.location.pathname}${router.location.search}`
              )}`}
              className={styles.modalLink}
              style={{ marginRight: '1rem' }}
            >
              SIGN IN HERE
            </Link>
            <p
              className={styles.closeModal}
              onClick={handleClose}
              aria-hidden="true"
            >
              NO THANKS
            </p>
          </div>
        </div>
      </Modal>
      <button
        className={styles.favButton}
        type="button"
        onClick={dispatchAction}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <FavoriteIcon isFavorite={isFavorite} />
        )}
      </button>
    </>
  );
}

FavoriteItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FavoriteItem;
