import PropTypes from 'prop-types';
import { storeLinks } from '../globalConfig';
import { ACTIONS, CATEGORIES } from './GAConstants';
import logAnalyticsEvent from './logAnalyticsEvent';

const RedeemModal = ({ closeAction }) => {
  return (
    <div className="modalContent">
      <div>
        <button type="button" onClick={closeAction} className="closeButton">
          <img alt="" src="/icons/saverClose.svg" />
        </button>
      </div>
      <div>
        <span>
          Coupons can currently only be <br /> redeemed from within our app.
        </span>
      </div>
      <div className="imageHolder">
        <img
          alt="redeemCoupons"
          src="/images/redeemPhone.svg"
          style={{ transform: 'scale(0.75)' }}
        />
      </div>
      <div>
        <p>DOWNLOAD IT NOW</p>
      </div>
      <div className="buttonHolder">
        <a
          type="button"
          href={storeLinks.apple}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            logAnalyticsEvent({
              label: storeLinks.apple,
              action: ACTIONS.CLICK,
              category: CATEGORIES.APP_LINKS,
            })
          }
        >
          <img
            alt=""
            src="/icons/appStoreBlack.svg"
            style={{ transform: 'scale(0.8)' }}
          />
        </a>
        <a
          type="button"
          href={storeLinks.android}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            logAnalyticsEvent({
              label: storeLinks.android,
              action: ACTIONS.CLICK,
              category: CATEGORIES.APP_LINKS,
            })
          }
        >
          <img
            alt=""
            src="/icons/playstoreBlack.svg"
            style={{ transform: 'scale(0.8)', marginTop: '-0.25rem' }}
          />
        </a>
      </div>
    </div>
  );
};

export default RedeemModal;

RedeemModal.propTypes = {
  closeAction: PropTypes.func.isRequired,
};
