import PropTypes from 'prop-types';
import { useEffect } from 'react';
import styles from '../styles/Coupon.module.css';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import Clickable from './Clickable';
import FavoriteItem from './FavoriteItem';

const Coupon = ({
  title,
  disclaimer,
  description,
  redeem,
  couponId,
  merchantName,
}) => {
  const eventLabel = `${merchantName} - ${title}`;
  const reedemNow = () => {
    logAnalyticsEvent({
      label: eventLabel,
      action: ACTIONS.REDEEM,
      category: CATEGORIES.COUPON,
    });
    redeem();
  };

  const onFavorite = () => {
    logAnalyticsEvent({
      label: eventLabel,
      action: ACTIONS.FAVORITE,
      category: CATEGORIES.COUPON,
    });
  };

  useEffect(() => {
    logAnalyticsEvent({
      label: eventLabel,
      action: ACTIONS.LOADED,
      category: CATEGORIES.COUPON,
      nonInteraction: true,
    });
  }, [eventLabel]);

  return (
    <div className={styles.card}>
      <div className={styles.topBar}>
        <p className={styles.title}>{title}</p>
        <Clickable onClick={onFavorite} className={styles.favorite}>
          <FavoriteItem id={couponId} type="COUPON" />
        </Clickable>
      </div>
      <div className={styles.body}>
        <span className={styles.description}>{description}</span>
        <span className={styles.disclaimer}>{disclaimer}</span>
      </div>
      <button type="button" className={styles.redeemBtn} onClick={reedemNow}>
        Redeem now
      </button>
    </div>
  );
};

export default Coupon;

Coupon.propTypes = {
  couponId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  redeem: PropTypes.func.isRequired,
  merchantName: PropTypes.func.isRequired,
};
