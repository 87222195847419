import { useEffect, useState } from 'react';
import moment from 'moment';
import SaverInput from '../components/SaverInput';
import styles from '../styles/Contact.module.css';
import useTitle from '../hooks/useTitle';

const Contact = () => {
  const initialForm = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    comment: '',
  };

  const [formState, updateFormState] = useState(initialForm);
  const [valid, setIsValid] = useState(true);

  useTitle('Contact Us');

  const handleChange = (e) => {
    console.log(e);
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (
      formState.firstName !== '' &&
      formState.email !== '' &&
      formState.comment.length > 1
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [formState.comment, formState.email, formState.firstName]);

  const href = `mailto:russ@thesaver.com?subject=The Saver Contact Form&body=Message sent on ${moment().format(
    'dddd, MMMM Do YYYY, h:mm:ss a'
  )}%0D%0A%0D%0ACONTACT FORM DETAILS:%0D%0A%0D%0AName: ${`${formState.firstName} ${formState.lastName}`}%0D%0ACompany: ${
    formState.company
  }%0D%0APhone: ${formState.phone}%0D%0AEmail: ${
    formState.email
  }%0D%0A%0D%0AMessage: ${formState.comment}`;

  return (
    <div data-testid="contact-info">
      <section className={styles.container}>
        <div className={styles.header} style={{ marginTop: '2rem' }}>
          <img src="/icons/envelope.svg" alt="" data-testid="saver-mail" />
          <h2 data-testid="get-in-touch">Get in touch</h2>
        </div>

        <h2 className={styles.subtitle} data-testid="questions-comments">
          Questions or comments?
        </h2>
        <p>What&apos;s your favorite feature? </p>
        <p> What feature would you like to see added?</p>
        <p> Have you found something within the site that doesn&apos;t work?</p>
        <p>
          We&apos;d love to get your feedback or questions. Please fill out the
          form below or give us a call at 614.697.2299.
        </p>

        <h2 className={styles.subtitle} data-testid="printed-magazine">
          Looking for a copy of the printed magazine?
        </h2>
        <p>Pick up a print copy of The Saver at either one of two locations:</p>
        <p>
          • One of the bins at the main entrance of The Ohio Union at{' '}
          <a
            href="https://www.google.com/maps/place/1739+N+High+St,+Columbus,+OH+43210,+USA/@39.99772,-83.0107637,17z/data=!3m1!4b1!4m5!3m4!1s0x88388eb8cc64683d:0xd72265651851c9bd!8m2!3d39.99772!4d-83.008575"
            target="_blank"
            rel="noreferrer"
            className={styles.underline}
          >
            1739 North High St.
          </a>
        </p>
        <p>
          • The Off-Campus & Commuter Student Engagement office located in
          <br /> &nbsp; room 3106 of the Ohio Union at{' '}
          <a
            href="https://www.google.com/maps/place/1739+N+High+St,+Columbus,+OH+43210,+USA/@39.99772,-83.0107637,17z/data=!3m1!4b1!4m5!3m4!1s0x88388eb8cc64683d:0xd72265651851c9bd!8m2!3d39.99772!4d-83.008575"
            target="_blank"
            rel="noreferrer"
            className={styles.underline}
          >
            1739 North High St.
          </a>
        </p>
      </section>

      <section className={styles.formContainer}>
        <form className={styles.form} data-testid="form">
          <h1>Contact Us Online</h1>
          <SaverInput
            css={styles.input}
            value={formState.firstName}
            placeholder="First Name*"
            type="text"
            name="firstName"
            icon="/icons/userLogin.svg"
            changeAction={handleChange}
            required
          />
          <SaverInput
            css={styles.input}
            value={formState.lastName}
            placeholder="Last Name"
            type="text"
            name="lastName"
            icon="/icons/userLogin.svg"
            changeAction={handleChange}
          />
          <SaverInput
            css={styles.input}
            value={formState.company}
            placeholder="Company"
            type="text"
            name="company"
            icon="/icons/locationProfile.svg"
            changeAction={handleChange}
          />
          <SaverInput
            css={styles.input}
            value={formState.email}
            placeholder="Email*"
            type="text"
            name="email"
            icon="/icons/emailAuth.svg"
            changeAction={handleChange}
            required
          />
          <SaverInput
            css={styles.input}
            value={formState.phone}
            placeholder="Phone"
            type="text"
            name="phone"
            icon="/icons/phoneNumber.svg"
            changeAction={handleChange}
          />
          <textarea
            className={styles.comment}
            placeholder="Message*"
            type="textarea"
            value={formState.comment}
            name="comment"
            onChange={handleChange}
            required
          />
          <div>
            <a href={href} disabled={valid}>
              <button
                type="button"
                disabled={valid}
                className={valid ? styles.disabled : styles.button}
                data-testid="button"
              >
                Submit
              </button>
            </a>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Contact;
