import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styles from '../styles/SideDrawer.module.css';

const SideDrawer = ({ children, show, dataTestId, drawerToggle }) => {
  return (
    <CSSTransition
      in={show}
      timeout={200}
      classNames="slide-in-left"
      mountOnEnter
      unmountOnExit
      data-testid={dataTestId}
    >
      <aside className={styles.mobileDrawer}>
        <div className={styles.logo}>
          <a href="/">
            <button
              type="button"
              onClick={drawerToggle}
              style={{ backgroundColor: 'transparent' }}
            >
              <img
                src="/icons/saverLogo.svg"
                alt="saverLogo"
                data-testid="logo"
              />
            </button>
          </a>
        </div>
        {children}
      </aside>
    </CSSTransition>
  );
};

SideDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string,
  drawerToggle: PropTypes.func.isRequired,
};

SideDrawer.defaultProps = {
  dataTestId: 'sidedrawer',
};

export default SideDrawer;
