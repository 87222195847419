const actionTypes = {
  // SELECT MERCHANT PROPERTIES
  SELECT_MERCHANT_PROPERTIES: 'SELECT_MERCHANT_PROPERTIES',

  // RESET FILTER PROPERTIES
  RESET_FILTERS_PROPERTIES: 'RESET_FILTERS_PROPERTIES',

  // FILTER PROPERTIES
  FILTER_PROPERTIES_START: 'FILTER_PROPERTIES_START',
  FILTER_PROPERTIES_SUCCESS: 'FILTER_PROPERTIES_SUCCESS',
  FILTER_PROPERTIES_FAIL: 'FILTER_PROPERTIES_FAIL',

  // SEARCH PROPERTIES
  SEARCH_PROPERTIES_START: 'SEARCH_PROPERTIES_START',
  SEARCH_PROPERTIES_SUCCESS: 'SEARCH_PROPERTIES_SUCCESS',
  SEARCH_PROPERTIES_FAIL: 'FILTER_PROPERTIES_FAIL',

  // SEARCH
  SEARCH_START: 'SEARCH_START',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_FAIL: 'SEARCH_FAIL',

  // FAVORITES
  GET_FAVORITES_START: 'GET_FAVORITES_START',
  GET_FAVORITES_SUCCESS: 'GET_FAVORITES_SUCCESS',
  GET_FAVORITES_FAIL: 'GET_FAVORITES_FAIL',

  // ADD TO FAVORITES
  ADD_TO_FAVORITES_START: 'ADD_TO_FAVORITES_START',
  ADD_TO_FAVORITES_SUCCESS: 'ADD_TO_FAVORITES_SUCCESS',
  ADD_TO_FAVORITES_FAIL: 'ADD_TO_FAVORITES_FAIL',

  // REMOVE FROM FAVORITES
  REMOVE_FROM_FAVORITES_START: 'REMOVE_FROM_FAVORITES_START',
  REMOVE_FROM_FAVORITES_SUCCESS: 'REMOVE_FROM_FAVORITES_SUCCESS',
  REMOVE_FROM_FAVORITES_FAIL: 'REMOVE_FROM_FAVORITES_FAIL',

  // GET CURRENT SESSION
  GET_SESSION_START: 'GET_SESSION_START',
  GET_SESSION_SUCCESS: 'GET_SESSION_SUCCESS',
  GET_SESSION_FAIL: 'GET_SESSION_FAIL',

  // GET IMAGES
  GET_IMAGES_START: 'GET_IMAGES_START',
  GET_IMAGES_SUCCESS: 'GET_IMAGES_SUCCESS',
  GET_IMAGES_FAIL: 'GET_IMAGES_FAIL',

  // SAVE IMAGES
  SAVE_IMAGE_START: 'SAVE_IMAGE_START',
  SAVE_IMAGE_SUCCESS: 'SAVE_IMAGE_SUCCESS',
  SAVE_IMAGE_FAIL: 'SAVE_IMAGE_FAIL',

  // GET CATEGORIES
  GET_CATEGORIES_START: 'GET_CATEGORIES_START',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAIL: 'GET_CATEGORIES_FAIL',

  // GET CATEGORY
  GET_CATEGORY_START: 'GET_CATEGORY_START',
  GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_FAIL: 'GET_CATEGORY_FAIL',

  // SAVE CATEGORIES
  SAVE_CATEGORY_START: 'SAVE_CATEGORY_START',
  SAVE_CATEGORY_SUCCESS: 'SAVE_CATEGORY_SUCCESS',
  SAVE_CATEGORY_FAIL: 'SAVE_CATEGORY_FAIL',

  // SET CATEGORY TO EDIT
  SET_CATEGORY_EDIT: 'SET_CATEGORY_EDIT',

  // UPDATE CATEGORY
  UPDATE_CATEGORY_START: 'UPDATE_CATEGORY_START',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAIL: 'UPDATE_CATEGORY_FAIL',

  // GET MERCHANTS
  GET_MERCHANTS_START: 'GET_MERCHANTS_START',
  GET_MERCHANTS_SUCCESS: 'GET_MERCHANTS_SUCCESS',
  GET_MERCHANTS_FAIL: 'GET_MERCHANTS_FAIL',

  // UPDATE MERCHANTS
  UPDATE_MERCHANT_START: 'UPDATE_MERCHANT_START',
  UPDATE_MERCHANT_SUCCESS: 'UPDATE_MERCHANT_SUCCESS',
  UPDATE_MERCHANT_FAIL: 'UPDATE_MERCHANT_FAIL',

  // UPDATE CURRENT MERCHANT
  UPDATE_CURRENT_MERCHANT_START: 'UPDATE_CURRENT_MERCHANT_START',
  UPDATE_CURRENT_MERCHANT_SUCCESS: 'UPDATE_CURRENT_MERCHANT_SUCCESS',
  UPDATE_CURRENT_MERCHANT_FAIL: 'UPDATE_CURRENT_MERCHANT_FAIL',

  // CREATE MERCHANTS
  CREATE_MERCHANT_START: 'CREATE_MERCHANT_START',
  CREATE_MERCHANT_SUCCESS: 'CREATE_MERCHANT_SUCCESS',
  CREATE_MERCHANT_FAIL: 'CREATE_MERCHANT_FAIL',

  // SET MERCHANT TO EDIT
  SET_MERCHANT_EDIT: 'SET_MERCHANT_EDIT',

  // ASSIGN CATEGORIES TO MERCHANT
  ASSIGN_CATEGORIES_START: 'ASSIGN_CATEGORIES_START',
  ASSIGN_CATEGORIES_SUCCESS: 'ASSIGN_CATEGORIES_SUCCESS',
  ASSIGN_CATEGORIES_FAIL: 'ASSIGN_CATEGORIES_FAIL',

  // EDIT MODAL
  OPEN_EDIT_MODAL: 'OPEN_EDIT_MODAL',
  CLOSE_EDIT_MODAL: 'CLOSE_EDIT_MODAL',

  // GET CURRENT MERCHANT INFO
  GET_CURRENT_MERCHANT_START: 'GET_CURRENT_MERCHANT_START',
  GET_CURRENT_MERCHANT_SUCCESS: 'GET_CURRENT_MERCHANT_SUCCESS',
  GET_CURRENT_MERCHANT_FAIL: 'GET_CURRENT_MERCHANT_FAIL',

  // GET MENUS
  GET_MENUS_START: 'GET_MENUS_START',
  GET_MENUS_SUCCESS: 'GET_MENUS_SUCCESS',
  GET_MENUS_FAIL: 'GET_MENUS_FAIL',

  // UPDATE MENU
  UPDATE_MENU_START: 'UPDATE_MENU_START',
  UPDATE_MENU_SUCCESS: 'UPDATE_MENU_SUCCESS',
  UPDATE_MENU_FAIL: 'UPDATE_MENU_FAIL',

  // CREATE MENU
  CREATE_MENU_START: 'CREATE_MENU_START',
  CREATE_MENU_SUCCESS: 'CREATE_MENU_SUCCESS',
  CREATE_MENU_FAIL: 'CREATE_MENU_FAIL',

  // SET MENU TO EDIT
  SET_MENU_EDIT: 'SET_MENU_EDIT',

  // GET PRODUCTS
  GET_PRODUCTS_START: 'GET_PRODUCTS_START',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAIL: 'GET_PRODUCTS_FAIL',

  // UPDATE PRODUCT
  UPDATE_PRODUCT_START: 'UPDATE_PRODUCT_START',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAIL: 'UPDATE_PRODUCT_FAIL',

  // CREATE PRODUCT
  CREATE_PRODUCT_START: 'CREATE_PRODUCT_START',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAIL: 'CREATE_PRODUCT_FAIL',

  // SET PRODUCT TO EDIT
  SET_PRODUCT_EDIT: 'SET_PRODUCT_EDIT',

  // GET ORDERS
  GET_ORDERS_START: 'GET_ORDERS_START',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAIL: 'GET_ORDERS_FAIL',

  // UPDATE ORDER
  UPDATE_ORDER_START: 'UPDATE_ORDER_START',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAIL: 'UPDATE_ORDER_FAIL',

  // UPDATE ORDER LIST
  UPDATE_ORDERS_LIST_START: 'UPDATE_ORDERS_LIST_START',
  UPDATE_ORDERS_LIST_SUCCESS: 'UPDATE_ORDERS_LIST_SUCCESS',
  UPDATE_ORDERS_LIST_FAIL: 'UPDATE_ORDERS_LIST_FAIL',

  // SET ORDER TO EDIT
  SET_ORDER_EDIT: 'SET_ORDER_EDIT',

  // GET CONFIG
  GET_CONFIG_START: 'GET_CONFIG_START',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_FAIL: 'GET_CONFIG_FAIL',

  // UPDATE CONFIG
  UPDATE_CONFIG_START: 'UPDATE_CONFIG_START',
  UPDATE_CONFIG_SUCCESS: 'UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAIL: 'UPDATE_CONFIG_FAIL',

  // GET COUPONS
  GET_COUPONS_START: 'GET_COUPONS_START',
  GET_COUPONS_SUCCESS: 'GET_COUPONS_SUCCESS',
  GET_COUPONS_FAIL: 'GET_COUPONS_FAIL',

  // UPDATE COUPON
  UPDATE_COUPON_START: 'UPDATE_COUPON_START',
  UPDATE_COUPON_SUCCESS: 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAIL: 'UPDATE_COUPON_FAIL',

  // CREATE COUPON
  CREATE_COUPON_START: 'CREATE_COUPON_START',
  CREATE_COUPON_SUCCESS: 'CREATE_COUPON_SUCCESS',
  CREATE_COUPON_FAIL: 'CREATE_COUPON_FAIL',

  // SET COUPON TO EDIT
  SET_COUPON_EDIT: 'SET_COUPON_EDIT',

  // FILTER ORDERS
  FILTER_ORDERS_TODAY: 'FILTER_ORDERS_TODAY',
  FILTER_ORDERS_NOT_CLOSED: 'FILTER_ORDERS_NOT_CLOSED',
  FILTER_ORDERS_CLEAR: 'FILTER_ORDERS_CLEAR',

  // PAYMENT_INTENT
  PAYMENT_INTENT_START: 'PAYMENT_INTENT_START',
  PAYMENT_INTENT_SUCCESS: 'PAYMENT_INTENT_SUCCESS',
  PAYMENT_INTENT_FAIL: 'PAYMENT_INTENT_FAIL',

  // NOTIFY_CUSTOMER_STATUS
  NOTIFY_CUSTOMER_STATUS_START: 'NOTIFY_CUSTOMER_STATUS_START',
  NOTIFY_CUSTOMER_STATUS_SUCCESS: 'NOTIFY_CUSTOMER_STATUS_SUCCESS',
  NOTIFY_CUSTOMER_STATUS_FAIL: 'NOTIFY_CUSTOMER_STATUS_FAIL',

  // GET CUSTOMERS
  GET_CUSTOMERS_START: 'GET_CUSTOMERS_START',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAIL: 'GET_CUSTOMERS_FAIL',

  // GIVE ACCESS TO MERCHANT
  GIVE_ACCESS_START: 'GIVE_ACCESS_START',
  GIVE_ACCESS_SUCCESS: 'GIVE_ACCESS_SUCCESS',
  GIVE_ACCESS_FAIL: 'GIVE_ACCESS_FAIL',

  // REMOVE ACCESS TO MERCHANT
  REMOVE_ACCESS_START: 'REMOVE_ACCESS_START',
  REMOVE_ACCESS_SUCCESS: 'REMOVE_ACCESS_SUCCESS',
  REMOVE_ACCESS_FAIL: 'REMOVE_ACCESS_FAIL',

  // GET PROPERTIES
  GET_PROPERTIES_START: 'GET_PROPERTIES_START',
  GET_PROPERTIES_SUCCESS: 'GET_PROPERTIES_SUCCESS',
  GET_PROPERTIES_FAIL: 'GET_PROPERTIES_FAIL',

  // UPDATE PROPERTY
  UPDATE_PROPERTY_START: 'UPDATE_PROPERTY_START',
  UPDATE_PROPERTY_SUCCESS: 'UPDATE_PROPERTY_SUCCESS',
  UPDATE_PROPERTY_FAIL: 'UPDATE_PROPERTY_FAIL',

  // CREATE PROPERTY
  CREATE_PROPERTY_START: 'CREATE_PROPERTY_START',
  CREATE_PROPERTY_SUCCESS: 'CREATE_PROPERTY_SUCCESS',
  CREATE_PROPERTY_FAIL: 'CREATE_PROPERTY_FAIL',

  UPDATE_PROPERTY_INFO: 'UPDATE_PROPERTY_INFO',

  // ASSIGN IMAGE TO PROPERTY
  ASSIGN_IMAGE_TO_PROPERTY_START: 'ASSIGN_IMAGE_TO_PROPERTY_START',
  ASSIGN_IMAGE_TO_PROPERTY_SUCCESS: 'ASSIGN_IMAGE_TO_PROPERTY_SUCCESS',
  ASSIGN_IMAGE_TO_PROPERTY_FAIL: 'ASSIGN_IMAGE_TO_PROPERTY_FAIL',

  // REMOVE IMAGE FROM PROPERTY
  REMOVE_IMAGE_FROM_PROPERTY_START: 'REMOVE_IMAGE_FROM_PROPERTY_START',
  REMOVE_IMAGE_FROM_PROPERTY_SUCCESS: 'REMOVE_IMAGE_FROM_PROPERTY_SUCCESS',
  REMOVE_IMAGE_FROM_PROPERTY_FAIL: 'REMOVE_IMAGE_FROM_PROPERTY_FAIL',

  // SET PROPERTY TO EDIT
  SET_PROPERTY_EDIT: 'SET_PROPERTY_EDIT',

  // GET UNIT
  GET_UNITS_START: 'GET_UNITS_START',
  GET_UNITS_SUCCESS: 'GET_UNITS_SUCCESS',
  GET_UNITS_FAIL: 'GET_UNITS_FAIL',

  // UPDATE UNIT
  UPDATE_UNIT_START: 'UPDATE_UNIT_START',
  UPDATE_UNIT_SUCCESS: 'UPDATE_UNIT_SUCCESS',
  UPDATE_UNIT_FAIL: 'UPDATE_UNIT_FAIL',

  // CREATE UNIT
  CREATE_UNIT_START: 'CREATE_UNIT_START',
  CREATE_UNIT_SUCCESS: 'CREATE_UNIT_SUCCESS',
  CREATE_UNIT_FAIL: 'CREATE_UNIT_FAIL',

  // DELETE UNIT
  DELETE_UNIT_START: 'DELETE_UNIT_START',
  DELETE_UNIT_SUCCESS: 'DELETE_UNIT_SUCCESS',
  DELETE_UNIT_FAIL: 'DELETE_UNIT_FAIL',

  // ASSIGN IMAGE TO UNIT
  ASSIGN_IMAGE_TO_UNIT_START: 'ASSIGN_IMAGE_TO_UNIT_START',
  ASSIGN_IMAGE_TO_UNIT_SUCCESS: 'ASSIGN_IMAGE_TO_UNIT_SUCCESS',
  ASSIGN_IMAGE_TO_UNIT_FAIL: 'ASSIGN_IMAGE_TO_UNIT_FAIL',

  // REMOVE IMAGE FROM UNIT
  REMOVE_IMAGE_FROM_UNIT_START: 'REMOVE_IMAGE_FROM_UNIT_START',
  REMOVE_IMAGE_FROM_UNIT_SUCCESS: 'REMOVE_IMAGE_FROM_UNIT_SUCCESS',
  REMOVE_IMAGE_FROM_UNIT_FAIL: 'REMOVE_IMAGE_FROM_UNIT_FAIL',

  // SET UNIT TO EDIT
  SET_UNIT_EDIT: 'SET_UNIT_EDIT',

  // UPLOAD BULK PROPERTIES AND UNITS
  BULK_UPLOAD_PROPERTIES_AND_UNITS_START:
    'BULK_UPLOAD_PROPERTIES_AND_UNITS_START',
  BULK_UPLOAD_PROPERTIES_AND_UNITS_SUCCESS:
    'BULK_UPLOAD_PROPERTIES_AND_UNITS_SUCCESS',
  BULK_UPLOAD_PROPERTIES_AND_UNITS_FAIL:
    'BULK_UPLOAD_PROPERTIES_AND_UNITS_FAIL',

  // UPDATE MAX AND MIN RENTS
  UPDATE_MAX_AND_MIN_RENTS_START: 'UPDATE_MAX_AND_MIN_RENTS_START',
  UPDATE_MAX_AND_MIN_RENTS_SUCCESS: 'UPDATE_MAX_AND_MIN_RENTS_SUCCESS',
  UPDATE_MAX_AND_MIN_RENTS_FAIL: 'UPDATE_MAX_AND_MIN_RENTS_FAIL',
};

export default actionTypes;
