export const ACTIONS = {
  LOADED: 'Loaded',
  CLICK: 'Click',
  FAVORITE: 'Favorite',
  REDEEM: 'Redeem',
  MAP_VIEW_EXTERNAL: 'Map View External',
  UPDATE: 'Update',
};

export const CATEGORIES = {
  CALL_TO_ACTION: 'Call To Action',
  COUPON: 'Coupon',
  PROPERTY: 'Property',
  CATEGORY: 'Category',
  OFFER: 'Offer',
  FEATURED_OFFER: 'Featured Offer',
  EXTERNAL_LINK: 'External Link',
  APP_LINKS: 'App Links',
  BUSINESS_LISTING: 'Business Listing',
  USER: 'User',
};
