import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../styles/FeaturedOfferCard.module.css';
import { encodeCategoryName } from '../utils/categoryNameEcoder';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import Clickable from './Clickable';

const FeaturedOfferCard = ({ title, merchant, logo }) => {
  return (
    <Link
      to={`/categories/${encodeCategoryName(
        process.env.REACT_APP_DEFAULT_CATEGORY
      )}/${merchant}?selectedTab=${
        merchant === 'Donatos' ? 'specials' : 'coupons'
      }`}
      onClick={() => {
        logAnalyticsEvent({
          label: `${merchant} - ${title}`,
          action: ACTIONS.CLICK,
          category: CATEGORIES.FEATURED_OFFER,
        });
      }}
    >
      <Clickable className={styles.card}>
        <p className={styles.title}>{title}</p>
        <div className={styles.cardBody}>
          <div className={styles.cardImage} style={{ width: '50%' }}>
            <img
              alt="merchant-logo"
              className={styles.merchantLogo}
              src={logo}
              width="96%"
            />
          </div>
          <div className={styles.cardItem}>
            <p className={styles.merchantName}>{merchant}</p>
          </div>
        </div>
      </Clickable>
    </Link>
  );
};

FeaturedOfferCard.propTypes = {
  title: PropTypes.string.isRequired,
  merchant: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default FeaturedOfferCard;
