/* eslint-disable camelcase */
import authActionsTypes from '../constants/authActionTypes';

const initialState = {
  session: null,
  profile: null,
  loading: false,
  error: null,
  customAuth: null,
  signUpVerificationUser: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    default: {
      return state;
    }
    case authActionsTypes.SIGN_UP_CONFIRMATION_CODE_SENT: {
      return {
        ...state,
        signUpVerificationUser: action.payload,
      };
    }
    case authActionsTypes.SIGN_IN_SMS_PHONE_NUMBER: {
      return {
        ...state,
        customAuth: action.payload,
        loading: false,
      };
    }
    case authActionsTypes.SIGN_IN_INTERRUPT: {
      return {
        ...state,
        loading: false,
        interruptReason: action.payload,
      };
    }
    case authActionsTypes.SIGN_OUT_START:
    case authActionsTypes.GET_SESSION_START:
    case authActionsTypes.SIGN_IN_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case authActionsTypes.GET_SESSION_SUCCESS:
    case authActionsTypes.SIGN_IN_SUCCESS: {
      return {
        ...state,
        session: action.payload.signInUserSession,
        profile: action.payload,
        loading: false,
        error: null,
      };
    }
    case authActionsTypes.SIGN_OUT_SUCCESS: {
      return initialState;
    }
    case authActionsTypes.SIGN_OUT_FAIL:
    case authActionsTypes.GET_SESSION_FAIL:
    case authActionsTypes.SIGN_IN_FAIL: {
      return {
        ...state,
        session: null,
        profile: null,
        loading: false,
        error: action.payload,
      };
    }
  }
}

export default authReducer;
