const rt = '/magazine/Spring_23 Saver-';

export const magazinePages = new Array(42).fill(0).map((_, i) => {
  const page = (i + 1).toString().padStart(2, '0');

  return {
    name: `${page}.png`,
    src: `${rt}${page}.png`,
  };
});
