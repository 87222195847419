import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import ReactGA from 'react-ga4';
import amplifyConfig from './amplifyConfig';
import './index.css';
import './base-reset.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { SearchProvider } from './context/SearchContext';

const updatedAwsConfig = {
  ...amplifyConfig,
  oauth: {
    ...amplifyConfig.oauth,
    redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT,
    redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT,
  },
};

console.log('Initializing Amplify with config: ', updatedAwsConfig);

Amplify.configure(updatedAwsConfig);

ReactGA.initialize('G-GR7VDGXLFM', { gaOptions: { isDevelopment: true } });

ReactDOM.render(
  <Provider store={store}>
    <SearchProvider>
      <App />
    </SearchProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
