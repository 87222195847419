import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import styles from '../styles/Home.module.css';
import SaverCategory from '../components/SaverCategory';
import { sortASCEnabled } from '../utils/generalFunctions';
import getImageURL from '../utils/getImageURL';
import FeaturedOfferCard from '../components/FeaturedOfferCard';
import { encodeCategoryName } from '../utils/categoryNameEcoder';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import useTitle from '../hooks/useTitle';

export default function Home() {
  const tempCategories = useSelector((state) => state.categories.list);
  const tempCoupons = useSelector((state) => state.coupons.items);

  const categories = sortASCEnabled(tempCategories);
  const today = moment();
  const filteredCoupons = tempCoupons.filter(
    (coupon) => !moment(today).isSameOrAfter(coupon.expirationDate)
  );

  const actualOffers = filteredCoupons;

  const categoriesRef = useRef(null);
  const offersRef = useRef(null);

  useTitle();

  const categoriesScroll = (scrollOffset) => {
    categoriesRef.current.scrollLeft += scrollOffset;
  };

  const offerScroll = (scrollOffset) => {
    offersRef.current.scrollLeft += scrollOffset;
  };

  const handleOnFindHomeClick = () => {
    logAnalyticsEvent({
      label: 'Find your next home',
      category: CATEGORIES.CALL_TO_ACTION,
      action: ACTIONS.CLICK,
    });
  };

  return (
    <>
      <div className={styles.container}>
        <Link
          className={styles.titles}
          to={`/categories/${encodeCategoryName(
            process.env.REACT_APP_DEFAULT_CATEGORY
          )}`}
          style={{ cursor: 'pointer' }}
        >
          <img src="/icons/categoriesIcon.svg" alt="" className={styles.icon} />
          Our Categories
        </Link>
        <div className={styles.carouselControl}>
          <div className={styles.arrowLeft}>
            <button type="button" onClick={() => categoriesScroll(-500)}>
              <img src="/icons/prev.png" alt="" />
            </button>
          </div>
          <div
            className={styles.categoriesCarousel}
            data-testid="categories-carousel"
            ref={categoriesRef}
          >
            {categories.map((c) => {
              return (
                <SaverCategory
                  key={c.id}
                  name={c.name}
                  image={getImageURL(c.image.key, 1500, 'cover')}
                  categoryID={c.id}
                />
              );
            })}
          </div>
          <div className={styles.arrowRight}>
            <button type="button" onClick={() => categoriesScroll(500)}>
              <img src="/icons/next.png" alt="" />
            </button>
          </div>
        </div>

        {actualOffers && actualOffers.length > 0 && (
          <>
            <Link
              to={`/categories/${encodeCategoryName(
                process.env.REACT_APP_DEFAULT_CATEGORY
              )}`}
              className={styles.titles}
              style={{ cursor: 'pointer' }}
            >
              <img src="/icons/offersIcon.svg" alt="" className={styles.icon} />
              Featured Offers
            </Link>
            <div className={styles.carouselControl}>
              <div className={styles.arrowLeft}>
                <button type="button" onClick={() => offerScroll(-600)}>
                  <img src="/icons/prev.png" alt="" />
                </button>
              </div>
              <div
                className={styles.carousel}
                data-testid="featured-offers-carousel"
                ref={offersRef}
              >
                {actualOffers.map((o) => {
                  return (
                    <FeaturedOfferCard
                      key={o.id}
                      title={o.title}
                      merchant={o.merchant.name}
                      logo={getImageURL(o.merchant.logo.key, 1500, 'contain')}
                    />
                  );
                })}
              </div>
              <div className={styles.arrowRight}>
                <button type="button" onClick={() => offerScroll(600)}>
                  <img src="/icons/next.png" alt="" />
                </button>
              </div>
            </div>
          </>
        )}

        <div className={styles.cards}>
          <Link
            to="/apartments/"
            className={styles.card1}
            onClick={() => handleOnFindHomeClick()}
          >
            <div role="button" tabIndex="0" aria-hidden="true">
              <h4>FIND YOUR NEXT HOME HERE</h4>
            </div>
            <div className={styles.card1Content}>
              <p>Search our database of thousands of OSU Area homes</p>
              <div className={styles.card1_homes}>
                <img src="/images/card1.png" alt="" />
              </div>
            </div>
          </Link>

          <Link to="/saverAd" className={styles.card2}>
            <div>
              <img src="/images/card2.png" alt="" />
            </div>
            <p>
              See the true cost of using the national online ordering companies.
              <span> Save yourself money and help local businesses</span>
            </p>
          </Link>
        </div>
      </div>
      <img src="/images/bck_featured.svg" alt="" className="lightBackground" />
    </>
  );
}
