import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/MerchantListing.module.css';
import MerchantCard from './MerchantCard';
import getImageURL from '../utils/getImageURL';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';

export default function MerchantListing({ merchants, category }) {
  useEffect(() => {
    if (merchants.length > 0) {
      merchants.forEach((merchant) => {
        return logAnalyticsEvent({
          nonInteraction: true,
          label: merchant.name,
          action: ACTIONS.LOADED,
          category: CATEGORIES.BUSINESS_LISTING,
        });
      });
    }
  }, [merchants, category]);

  return (
    <div className={styles.grid}>
      {merchants.length > 0 ? (
        merchants.map((merchant) => (
          <div
            key={merchant.id}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <MerchantCard
              key={merchant.id}
              merchantId={merchant.id}
              name={merchant.name}
              logo={getImageURL(merchant.logo.key)}
              schedule={merchant.schedule}
              phone={merchant.phone}
              address={merchant.address}
              deliveryWebsite={merchant.deliveryWebsite}
              category={category}
              coupons={merchant.coupons}
              type={merchant.type}
            />
          </div>
        ))
      ) : (
        <div className={styles.noData}>
          <img src="/icons/noMerchants.svg" alt="" width="40px" />
          <p>
            Sorry, we don&apos;t currently have any businesses within this
            category.
            <br />
            Please choose a different category.
          </p>
        </div>
      )}
    </div>
  );
}

MerchantListing.propTypes = {
  category: PropTypes.string.isRequired,
  merchants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string,
      phone: PropTypes.string,
      deliveryWebsite: PropTypes.string,
      type: PropTypes.string,
      imageID: PropTypes.string,
      priority: PropTypes.number,
      address: PropTypes.arrayOf(
        PropTypes.shape({
          city: PropTypes.string,
          details: PropTypes.string,
          label: PropTypes.string,
          phone: PropTypes.string,
          state: PropTypes.string,
          street: PropTypes.string,
          zip: PropTypes.string,
          location: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
          }).isRequired,
        }).isRequired
      ),
      cover: PropTypes.shape({
        key: PropTypes.string.isRequired,
      }).isRequired,
      saverAd: PropTypes.shape({
        key: PropTypes.string.isRequired,
      }).isRequired,
      socialMedia: PropTypes.shape({
        facebook: PropTypes.string,
        instagram: PropTypes.string,
        twitter: PropTypes.string,
        website: PropTypes.string,
      }).isRequired,
      schedule: PropTypes.arrayOf(
        PropTypes.shape({
          closed: PropTypes.bool,
          day: PropTypes.string,
          label: PropTypes.string,
          openAt: PropTypes.string,
          workingHours: PropTypes.number,
        }).isRequired
      ).isRequired,
      enabled: PropTypes.bool,
      sort: PropTypes.string,
      coupons: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
        nextToken: PropTypes.string,
      }).isRequired,
      categories: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
        nextToken: PropTypes.string,
      }).isRequired,
      logo: PropTypes.shape({
        bucket: PropTypes.string,
        createdAt: PropTypes.string,
        id: PropTypes.string,
        key: PropTypes.string,
        name: PropTypes.string,
        owner: PropTypes.string,
        region: PropTypes.string,
        updatedAt: PropTypes.string,
      }).isRequired,
      menus: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
        nextToken: PropTypes.string,
      }).isRequired,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }).isRequired
  ),
};

MerchantListing.defaultProps = {
  merchants: {},
};
