/* eslint-disable class-methods-use-this */
import { API, graphqlOperation } from 'aws-amplify';
import { listMerchants, listCustomers, getMerchant } from '../graphql/queries';
import { sortASCEnabled } from '../utils/generalFunctions';
import requestAll from '../utils/requestAll';

export default class MerchantService {
  async getMerchants() {
    const merchants = await requestAll(listMerchants, 'listMerchants', {
      filter: { enabled: { eq: true } },
    });
    const enabledMerchants = sortASCEnabled(merchants);
    return enabledMerchants;
  }

  async getCustomers() {
    const customers = await requestAll(listCustomers, 'listCustomers');
    return customers;
  }

  async getEnabledMerchant(merchantID) {
    const merchant = await API.graphql(
      graphqlOperation(getMerchant, { id: merchantID })
    );
    return merchant.data.getMerchant.enabled;
  }
}
