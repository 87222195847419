import PropTypes from 'prop-types';

const FavoriteIcon = ({
  isFavorite = false,
  strokeColor = '#E11B22',
  color = '#E11B22',
  size = 25,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8482 1.09717C15.111 1.09717 15.3933 1.11276 15.7085 1.1405C17.4022 1.30863 19.4906 2.88435 19.7537 6.08809V7.19056C19.5135 10.2041 17.2419 14.0771 10.2556 19.0388C3.26993 14.0775 0.998573 10.2053 0.753662 7.19116V6.08807C1.01675 2.88365 3.10887 1.30852 4.79905 1.14047C5.1107 1.1128 5.39898 1.09717 5.66209 1.09717C6.71308 1.09717 7.45815 1.32302 8.08066 1.70013C8.71666 2.08541 9.25601 2.64641 9.87332 3.37885L10.2564 3.83338L10.6386 3.3781C11.2532 2.64594 11.7912 2.0852 12.4268 1.70011C13.049 1.32312 13.795 1.09717 14.8482 1.09717Z"
        fill={isFavorite ? color : '#FBFBFB'}
        stroke={strokeColor}
      />
    </svg>
  );
};

export default FavoriteIcon;

FavoriteIcon.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  strokeColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};
