import { combineReducers } from 'redux';
import categories from './categories.reducer';
import coupons from './coupons.reducer';
import merchants from './merchants.reducer';
import properties from './property.reducer';
import auth from './authReducer';
import favorites from './favorites.reducer';
import search from './search.reducer';

export default combineReducers({
  categories,
  coupons,
  merchants,
  properties,
  auth,
  favorites,
  search,
});
