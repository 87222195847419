import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styles from '../styles/NavLinks.module.css';
import { signOutUserAction } from '../redux/actions/authActions';

const NavLinks = ({ drawerToggle }) => {
  const { profile } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(signOutUserAction());
    history.push('/');
  };

  return (
    <ul className={styles.navlinks} data-testid="navlinks">
      <li>
        <Link to="/offers" onClick={drawerToggle} data-testid="all-offers-link">
          See All Our Offers
        </Link>
      </li>
      <li>
        <Link
          to="/magazine"
          onClick={drawerToggle}
          data-testid="digital-magazine-link"
        >
          Digital Magazine
        </Link>
      </li>
      <li>
        <Link to="/about" onClick={drawerToggle} data-testid="about-us-link">
          About us
        </Link>
      </li>
      <li>
        <Link
          to="/contact"
          onClick={drawerToggle}
          data-testid="contact-us-link"
        >
          Contact us
        </Link>
      </li>
      <li>
        <Link
          to="/subscribe"
          onClick={drawerToggle}
          data-testid="subscribe-link"
        >
          Subscribe
        </Link>
      </li>
      <li>
        <Link
          to="/favorites"
          onClick={drawerToggle}
          data-testid="favorites-link"
          className={styles.mobileLinks}
        >
          Favorites
        </Link>
      </li>
      {profile && (
        <li>
          <button type="button" className={styles.logoutBtn} onClick={onLogOut}>
            Log out
          </button>
        </li>
      )}
    </ul>
  );
};

NavLinks.propTypes = {
  drawerToggle: PropTypes.func,
};

NavLinks.defaultProps = {
  drawerToggle: () => {},
};

export default NavLinks;
