import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input/input';
import styles from '../../styles/Auth.module.css';

export default function SignUpForm({
  changeAction,
  backToSignIn,
  signUpAction,
  inputErrors,
  socialMedia,
}) {
  const {
    emailTakenError,
    emailError,
    numberTakenError,
    numberInvalidError,
    passwordError,
    passwordConfirmationError,
  } = inputErrors;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        signUpAction();
      }}
      className={styles.signUpform}
    >
      <button type="button" onClick={backToSignIn} className={styles.backLink}>
        <img alt="" src="/icons/back.svg" className="iconLeft" height="16px" />
        Take me to the Login Page
      </button>
      <div className={styles.signUpContainer}>
        <p className={styles.signUpNote}>*All fields are required</p>
        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <input
              name="name"
              onChange={changeAction}
              placeholder="First Name"
              className={styles.formInput}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              name="family_name"
              onChange={changeAction}
              placeholder="Last Name"
              className={styles.formInput}
              required
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <input
              name="username"
              type="email"
              onChange={changeAction}
              placeholder="Email"
              className={
                styles.formInput +
                (emailError ? ` ${styles.formInputError}` : '')
              }
              required
            />
            {emailError && (
              <span className={styles.errorMessage}>
                The entered email address is not valid or does not have a valid
                format.
              </span>
            )}
            {emailTakenError && (
              <span className={styles.errorMessage}>
                The entered email is already taken by another user.
              </span>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
            className={styles.phoneInput}
          >
            <PhoneInput
              required
              smartCaret={false}
              country="US"
              withCountryCallingCode
              international
              onChange={(phone) =>
                changeAction({ target: { name: 'phone_number', value: phone } })
              }
              placeholder="Mobile Phone Number*"
              iconStyle={{ width: 18, height: 18 }}
              className={
                styles.phoneDesc +
                (numberInvalidError || numberTakenError
                  ? ` ${styles.formInputError}`
                  : '')
              }
            />

            {numberInvalidError && (
              <span className={styles.errorMessage}>
                The entered phone number is not valid or does not have a valid
                format.
              </span>
            )}
            {numberTakenError && (
              <span className={styles.errorMessage}>
                The entered phone number is already taken by another user.
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.row} style={{ marginBottom: '-0.5rem' }}>
        <div className={styles.inputContainer}>
          <input
            name="password"
            type="password"
            onChange={changeAction}
            placeholder="Password"
            className={
              styles.formInput +
              (passwordError ? ` ${styles.formInputError}` : '')
            }
            required
          />
          {passwordError && (
            <span className={styles.errorMessage}>
              Entered password doesn’t match our password policy
            </span>
          )}
          <span
            style={{
              fontSize: '10px',
              color: 'var(--saver-darkBtn)',
              textAlign: 'left',
            }}
          >
            * Password must be longer than 6 characters, include at least a
            number, special character, uppercase letter and a lowercase letter
          </span>
        </div>
        <div className={styles.inputContainer}>
          <input
            name="confirmPassword"
            type="password"
            onChange={changeAction}
            placeholder="Confirm Password"
            className={styles.formInput}
          />
          {passwordConfirmationError && (
            <span className={styles.errorMessage}>
              The passwords entered do not match.
            </span>
          )}
        </div>
      </div>

      <button
        type="submit"
        className="mainButton"
        style={{
          width: '56%',
          padding: '2%',
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        Sign up
      </button>

      <div>
        <div className={styles.divider}>
          <img alt="" src="/icons/divider.svg" width="70%" />
          <p className={styles.dividerLine}>or</p>
          <img alt="" src="/icons/divider.svg" width="70%" />
        </div>

        <p style={{ fontWeight: '500' }}>Sign up with a social media account</p>
        <div className={styles.socialMediaContainer}>
          <button
            className={styles.mediaIcon}
            type="button"
            onClick={() => socialMedia('Google')}
          >
            <img
              alt=""
              src="/icons/googleColored.svg"
              className={styles.accountIcon}
            />
          </button>
          <button
            className={styles.mediaIcon}
            type="button"
            onClick={() => socialMedia('Facebook')}
          >
            <img
              alt=""
              src="/icons/fbColored.svg"
              className={styles.accountIcon}
            />
          </button>
          <button
            className={styles.mediaIcon}
            type="button"
            onClick={() => socialMedia('Apple')}
          >
            <img
              alt=""
              src="/icons/appleLogo.svg"
              className={styles.accountIcon}
            />
          </button>
        </div>
      </div>
    </form>
  );
}

SignUpForm.propTypes = {
  changeAction: PropTypes.func.isRequired,
  backToSignIn: PropTypes.func.isRequired,
  signUpAction: PropTypes.func.isRequired,
  socialMedia: PropTypes.func.isRequired,
  inputErrors: PropTypes.shape({
    emailError: PropTypes.bool,
    passwordError: PropTypes.bool,
    numberTakenError: PropTypes.bool,
    numberInvalidError: PropTypes.bool,
    passwordConfirmationError: PropTypes.bool,
  }),
  // socialMedia: PropTypes.func.isRequired,
};

SignUpForm.defaultProps = {
  inputErrors: {
    emailError: false,
    passwordError: false,
    numberTakenError: false,
    numberInvalidError: false,
    passwordConfirmationError: false,
  },
};
