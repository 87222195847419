import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import { css } from '@emotion/react';
import { BarLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import styles from '../styles/Navbar.module.css';
import { searchAction } from '../redux/actions/searchActions';
import SearchContext from '../context/SearchContext';
import { encodeCategoryName } from '../utils/categoryNameEcoder';

const SearchBar = () => {
  const { searchText, setSearchText, clearSearchHandler } =
    useContext(SearchContext);
  const { loading, results } = useSelector((state) => state.search);
  const { list } = useSelector((state) => state.merchants);
  const dispatch = useDispatch();
  const color = '#E11B22';

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 20px;
    position: absolute;
    background: #ffffff;
    z-index: 1002;
  `;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length >= 3) {
        dispatch(searchAction(searchText));
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText, dispatch]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  return (
    <>
      <div className={styles.searchBar}>
        <input
          type="search"
          value={searchText}
          placeholder="Search For Businesses, Categories, Coupons & Specials"
          onChange={handleInputChange}
        />
        <BarLoader color={color} loading={loading} css={override} size={100} />
        {!isEmpty(results) && searchText.length >= 3 && !loading && (
          <div className={styles.searchResultsList}>
            <ul>
              <h3>Merchants</h3>
              {!isEmpty(results[0]) ? (
                results[0].map(
                  (merchant) =>
                    merchant.enabled && (
                      <li key={merchant.id}>
                        <div
                          onClick={clearSearchHandler}
                          aria-hidden="true"
                          className={styles.link}
                        >
                          <Link
                            passHref
                            to={`/categories/${encodeCategoryName(
                              process.env.REACT_APP_DEFAULT_CATEGORY
                            )}/${merchant.name}`}
                          >
                            {merchant.name}
                          </Link>
                        </div>
                      </li>
                    )
                )
              ) : (
                <p>No results found for {searchText}</p>
              )}
              <hr />
            </ul>
            <ul>
              <h3>Categories</h3>
              {!isEmpty(results[1]) ? (
                results[1].map(
                  (category) =>
                    category.enabled && (
                      <li key={category.id}>
                        <div
                          onClick={clearSearchHandler}
                          aria-hidden="true"
                          className={styles.link}
                        >
                          <Link
                            to={`/categories/${encodeCategoryName(
                              category.name
                            )}`}
                            passhref
                            key={category.id}
                          >
                            {category.name}
                          </Link>
                        </div>
                      </li>
                    )
                )
              ) : (
                <p>No results found for {searchText}</p>
              )}
              <hr />
            </ul>
            <ul>
              <h3>Special Offers</h3>
              {!isEmpty(results[2]) ? (
                results[2].map(
                  (coupon) =>
                    list.find((x) => x.id === coupon.merchantID) && (
                      <li key={coupon.id}>
                        <div
                          onClick={clearSearchHandler}
                          aria-hidden="true"
                          className={styles.link}
                        >
                          <Link
                            passhref
                            to={`/categories/${encodeCategoryName(
                              process.env.REACT_APP_DEFAULT_CATEGORY
                            )}/${coupon.merchant.name}?selectedTab=${
                              coupon.isSpecial ? 'specials' : 'coupons'
                            }`}
                          >
                            <p style={{ marginBottom: '1rem' }}>
                              <span className={styles.offerTitle}>
                                {coupon.title}
                              </span>{' '}
                              <br />
                              {coupon.merchant.name}
                            </p>
                          </Link>
                        </div>
                      </li>
                    )
                )
              ) : (
                <p>No results found for {searchText}</p>
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBar;
