const buttonsObject = Object.freeze({
  styleButtons: {
    firstSection: [
      {
        label: 'Home/Townhome',
        value: 'House',
      },
      {
        label: 'Apartment',
        value: 'Apartments',
      },
    ],
    secondSection: [
      {
        label: 'Condo',
        value: 'Condo',
      },
      {
        label: 'Flat',
        value: 'Flat',
      },
    ],
  },
  bedButtons: [
    {
      label: 'Studio',
      value: 0,
    },
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
    {
      label: '6+',
      value: 6,
    },
  ],
  bathButtons: [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4+',
      value: 4,
    },
  ],
  areaButtons: [
    {
      section: [
        { label: 'Nort Of Lane Ave', value: 'NORTH_OF_LANE_AVE' },
        {
          label: 'Between 15th Ave & Lane Ave',
          value: 'BETWEEN_15TH_AND_LANE_AVE',
        },
      ],
    },
    {
      section: [
        {
          label: 'Between 10th Ave & 14th Ave',
          value: 'BETWEEN_10TH_AND_14TH_AVE',
        },
        { label: 'South Of 10th Ave', value: 'SOUTH_OF_10TH_AVE' },
      ],
    },
    {
      section: [
        { label: 'West Campus', value: 'WEST_CAMPUS' },
        { label: 'Off Campus', value: 'OFF_CAMPUS' },
      ],
    },
  ],
  amenities: [
    {
      label: 'Air Conditioning',
      value: 'airConditioning',
    },
    {
      label: 'Off Street Parking Available',
      value: 'offStreetParking',
    },
    {
      label: 'Roommate Matching Service Offered',
      value: 'roommateMatchingServiceAvailable',
    },
    {
      label: 'Dishwasher',
      value: 'dishwasher',
    },
    {
      label: 'Furnished',
      value: 'furnished',
    },
    {
      label: 'Dogs Welcome',
      value: 'dogsAllowed',
    },
    {
      label: 'Cats Welcome',
      value: 'catsAllowed',
    },
    {
      label: 'Washer/Dryer or hookup',
      value: 'washerAndDryer',
    },
    {
      label: 'Short term leases available',
      value: 'shortTermLeases',
    },
    {
      label: 'Balcony, Deck or Patio',
      value: 'patioBalconyDeck',
    },
    {
      label: 'Bedrooms keyed Separately',
      value: 'bedroomsKeyedSeparately',
    },
    {
      label: 'Handicapped Accesible',
      value: 'rampedEntry',
    },
    {
      label: 'Fitness Center',
      value: 'exerciseFacility',
    },
    {
      label: 'Swimming Pool',
      value: 'pool',
    },
  ],
});

function generateAvailableOptions() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const availableOptions = [{ value: 'IMMEDIATELY', label: 'Immediately' }];

  // Define the seasons as they correspond to specific months (0-11)
  const seasons = ['SPRING', 'SUMMER', 'FALL'];
  const seasonStartMonths = [2, 5, 8]; // 0-based month indexes: March, June, September

  // Determine the current season based on the current month
  let currentSeasonIndex = seasonStartMonths.findIndex((startMonth, i) => {
    const nextSeasonStartMonth = seasonStartMonths[i + 1] || 12;
    return currentMonth >= startMonth && currentMonth < nextSeasonStartMonth;
  });

  if (currentSeasonIndex === -1) {
    currentSeasonIndex = 0;
  }

  // Generate the seasons for the rest of the current year
  for (let i = currentSeasonIndex; i < seasons.length; i++) {
    const yearSuffix = currentYear.toString().slice(2);
    const season = seasons[i];
    const value = `${season}_${yearSuffix}`;
    const label = `${season.charAt(0)}${season
      .slice(1)
      .toLowerCase()} '${yearSuffix}`;
    availableOptions.push({ value, label });
  }

  // Generate the entire next two years worth of seasons
  for (let y = 1; y <= 2; y++) {
    for (let s = 0; s < seasons.length; s++) {
      const yearSuffix = (currentYear + y).toString().slice(2);
      const season = seasons[s];
      const value = `${season}_${yearSuffix}`;
      const label = `${season.charAt(0)}${season
        .slice(1)
        .toLowerCase()} '${yearSuffix}`;
      availableOptions.push({ value, label });
    }
  }

  return availableOptions;
}

const availableOptions = generateAvailableOptions();

const getDefaultOption = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  let defaultYear = currentYear;
  if (currentMonth >= 7) {
    // Months are 0-indexed, so July is 6 and August is 7.
    defaultYear += 1;
  }

  const yearSuffix = defaultYear.toString().slice(2);
  const value = `FALL_${yearSuffix}`;
  const label = `Fall '${yearSuffix}`;

  return { value, label };
};

const amenitiesKeys = Object.freeze({
  heatIncluded: {
    label: 'Heat Included',
    value: 'heatIncluded',
    image: '/apartmentIcons/heatIncluded.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  electricityIncluded: {
    label: 'Electricity Included',
    value: 'electricityIncluded',
    image: '/apartmentIcons/electricityIncluded.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  gasIncluded: {
    label: 'Gas Included',
    value: 'gasIncluded',
    image: '/apartmentIcons/gasIncluded.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  waterIncluded: {
    label: 'Water Included',
    value: 'waterIncluded',
    image: '/apartmentIcons/waterIncluded.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  dogsAllowed: {
    label: 'Dogs Allowed',
    value: 'dogsAllowed',
    image: '/apartmentIcons/pets.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  largeDogsAllowed: {
    label: 'Large Dogs Allowed',
    value: 'largeDogsAllowed',
    image: '/apartmentIcons/pets.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  smallDogsAllowed: {
    label: 'Small Dogs Allowed',
    value: 'smallDogsAllowed',
    image: '/apartmentIcons/pets.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  catsAllowed: {
    label: 'Cats Allowed',
    value: 'catsAllowed',
    image: '/apartmentIcons/pets.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  cableTVIncluded: {
    label: 'Cable TV Included',
    value: 'cableTVIncluded',
    image: '/apartmentIcons/cableIncluded.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  range: {
    label: 'Range',
    value: 'range',
    image: '/apartmentIcons/cards.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  shortTermLeases: {
    label: 'Short Term Leases',
    value: 'shortTermLeases',
    image: '/apartmentIcons/shortTermLeases.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  twelveMonths: {
    label: 'Twelve Month Leases',
    value: 'twelveMonths',
    image: '/apartmentIcons/twelveMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  twoMonths: {
    label: 'Two Month Leases',
    value: 'twoMonths',
    image: '/apartmentIcons/twoMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  threeMonths: {
    label: 'Three Month Leases',
    value: 'threeMonths',
    image: '/apartmentIcons/threeMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  tenMonths: {
    label: 'Ten Month Leases',
    value: 'tenMonths',
    image: '/apartmentIcons/tenMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  sixMonths: {
    label: 'Six Month Leases',
    value: 'sixMonths',
    image: '/apartmentIcons/sixMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  nineMonths: {
    label: 'Nine Month Leases',
    value: 'nineMonths',
    image: '/apartmentIcons/nineMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  fourMonths: {
    label: 'Four Month Leases',
    value: 'fourMonths',
    image: '/apartmentIcons/fourMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  eightMonths: {
    label: 'Eight Month Leases',
    value: 'eightMonths',
    image: '/apartmentIcons/eightMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  twentyFourMonths: {
    label: 'Twenty Four Month Leases',
    value: 'twentyFourMonths',
    image: '/apartmentIcons/twentyFourMonths.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  dishwasher: {
    label: 'Dishwasher',
    value: 'dishwasher',
    image: '/apartmentIcons/dishwasher.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  refrigerator: {
    label: 'Refrigerator',
    value: 'refrigerator',
    image: '/apartmentIcons/dishwasher.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  airConditioningWall: {
    label: 'Air Conditioning in Wall',
    value: 'airConditioningWall',
    image: '/apartmentIcons/airConditioningWall.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  airConditioning: {
    label: 'Air Conditioning',
    value: 'airConditioning',
    image: '/apartmentIcons/airConditioning.png',
    size: {
      width: 26,
      height: 20,
    },
  },
  washerAndDryer: {
    label: 'Washer and Dryer',
    value: 'washerAndDryer',
    image: '/apartmentIcons/washerAndDryerOnSite.png',
    size: {
      width: 22,
      height: 24,
    },
  },
  washerAndDryerHookup: {
    label: 'Washer and Dryer Hookup',
    value: 'washerAndDryerHookup',
    image: '/apartmentIcons/washerAndDryerHookup.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  pool: {
    label: 'Swimming pool',
    value: 'pool',
    image: '/apartmentIcons/swimming-pool.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  individualLeases: {
    label: 'Individual Leases',
    value: 'individualLeases',
    image: '/apartmentIcons/cards.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  furnished: {
    label: 'Furnished',
    value: 'furnished',
    image: '/apartmentIcons/furnished.png',
    size: {
      width: 27,
      height: 15,
    },
  },
  exerciseFacility: {
    label: 'Exercise Facility',
    value: 'exerciseFacility',
    image: '/apartmentIcons/exerciseFacility.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  internetIncluded: {
    label: 'Internet Included',
    value: 'internetIncluded',
    image: '/apartmentIcons/internetIncluded.png',
    size: {
      width: 21,
      height: 20,
    },
  },
  microwave: {
    label: 'Microwave',
    value: 'microwave',
    image: '/apartmentIcons/microwave.png',
    size: {
      width: 28,
      height: 20,
    },
  },
  elevatorInBuilding: {
    label: 'Elevator in Building',
    value: 'elevatorInBuilding',
    image: '/apartmentIcons/elevatorInBuilding.png',
    size: {
      width: 20,
      height: 24,
    },
  },
  rampedEntry: {
    label: 'Ramped Entry',
    value: 'rampedEntry',
    image: '/apartmentIcons/ramped.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  garage: {
    label: 'Garage',
    value: 'garage',
    image: '/apartmentIcons/garage.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  patioBalconyDeck: {
    label: 'Patio and Balcony Deck',
    value: 'patioBalconyDeck',
    image: '/apartmentIcons/balconyDeck.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  patio: {
    label: 'Patio',
    value: 'patio',
    image: '/apartmentIcons/patio.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  balcony: {
    label: 'Balcony',
    value: 'balcony',
    image: '/apartmentIcons/patio.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  washerAndDryerOnSite: {
    label: 'Washer and Dryer on Site',
    value: 'washerAndDryerOnSite',
    image: '/apartmentIcons/washerAndDryerOnSite.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  offStreetParking: {
    label: 'Off Street Parking',
    value: 'offStreetParking',
    image: '/apartmentIcons/offStreetParking.png',
    size: {
      width: 15,
      height: 23,
    },
  },
  parking: {
    label: 'Parking',
    value: 'parking',
    image: '/apartmentIcons/offStreetParking.png',
    size: {
      width: 15,
      height: 23,
    },
  },
  emergencyMaintenance: {
    label: 'Emergency Maintenance',
    value: 'emergencyMaintenance',
    image: '/apartmentIcons/emergencyMaintenance.png',
    size: {
      width: 22,
      height: 22,
    },
  },
  entranceSecured: {
    label: 'Entrance Secured',
    value: 'entranceSecured',
    image: '/apartmentIcons/entranceSecured.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  virtualTours: {
    label: 'Virtual Tours',
    value: 'virtualTours',
    image: '/apartmentIcons/virtualTours.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  roommateMatchingServiceAvailable: {
    label: 'Roommate Matching Service Available',
    value: 'roommateMatchingServiceAvailable',
    image: '/apartmentIcons/roommateMatchingServiceAvailable.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  managerOnSite: {
    label: 'Manager on Site',
    value: 'managerOnSite',
    image: '/apartmentIcons/managerOnSite.png',
    size: {
      width: 22,
      height: 23,
    },
  },
  socialEvents: {
    label: 'Social Events',
    value: 'socialEvents',
    image: '/apartmentIcons/socialEvents.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  bedroomsKeyedSeparately: {
    label: 'Bedrooms Keyed Separately',
    value: 'bedroomsKeyedSeparately',
    image: '/apartmentIcons/bedroomsKeyedSeparately.png',
    size: {
      width: 22,
      height: 20,
    },
  },
  graniteCountertops: {
    label: 'Granite Countertops',
    value: 'graniteCountertops',
    image: '/apartmentIcons/washerAndDryerOnSite.png',
    size: {
      width: 22,
      height: 20,
    },
  },
});

//Address: 1871 North High St. Columbus, OH  43210
const defaultLocation = {
  accuracy: 20,
  altitude: 100,
  heading: 0,
  latitude: 40.00054736187543,
  longitude: -83.00902854136173,
  speed: 0,
};

module.exports = {
  buttonsObject,
  amenitiesKeys,
  availableOptions,
  getDefaultOption,
  defaultLocation,
};
