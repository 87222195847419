import actionTypes from '../constants/actionTypes';
import authActionsTypes from '../constants/authActionTypes';

export const initialState = {
  list: [],
  indexByType: {
    MERCHANT: [],
    PROPERTY: [],
    COUPON: [],
  },
  indexByItemId: {},
  loading: false,
};

function favoritesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_TO_FAVORITES_START:
    case actionTypes.GET_FAVORITES_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.ADD_TO_FAVORITES_SUCCESS: {
      const newList = [...state.list, action.payload];
      const byId = newList.reduce((obj, item, i) => {
        obj[item.itemID] = item;
        return obj;
      }, {});
      const byType = newList.reduce((obj, item, i) => {
        const previousValues = obj[item.type] || [];
        obj[item.type] = [...previousValues, item];
        return obj;
      }, {});
      return {
        ...state,
        list: newList,
        indexByItemId: byId,
        indexByType: byType,
        loading: false,
      };
    }
    case actionTypes.GET_FAVORITES_SUCCESS: {
      const byId = action.payload.filtered.reduce((obj, item, i) => {
        obj[item.itemID] = item;
        return obj;
      }, {});

      const byType = action.payload.filtered.reduce((obj, item, i) => {
        const previousValues = obj[item.type] || [];
        obj[item.type] = [...previousValues, item];
        return obj;
      }, {});
      return {
        ...state,
        rawList: action.payload.raw,
        list: action.payload.filtered,
        indexByItemId: byId,
        indexByType: byType,
        loading: false,
      };
    }
    case actionTypes.ADD_TO_FAVORITES_FAIL:
    case authActionsTypes.SIGN_OUT_SUCCESS:
    case actionTypes.GET_FAVORITES_FAIL: {
      return {
        initialState,
      };
    }
    default:
      return state;
  }
}

export default favoritesReducer;
