import { includes } from 'ramda';

export default function searchForProperties(query, properties) {
  const lowercaseQuery = query.toLowerCase();

  const filteredItems = properties.filter(
    (property) =>
      includes(lowercaseQuery, property.marketingName.toLowerCase()) ||
      includes(lowercaseQuery, property.type.toLowerCase()) ||
      includes(lowercaseQuery, property.id) ||
      includes(lowercaseQuery, property.manager.name.toLowerCase())
  );

  return filteredItems;
}
