/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      merchantID
      customerID
      paymentMethodID
      stripeID
      paymentConfirmed
      paymentID
      couponID
      status
      type
      orderTotal
      products {
        productID
        name
        amount
        price
        specialNotes
        buyer
        selectedOptions
      }
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        accessToMerchant
        createdAt
        updatedAt
      }
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        merchantID
        customerID
        paymentMethodID
        stripeID
        paymentConfirmed
        paymentID
        couponID
        status
        type
        orderTotal
        products {
          productID
          name
          amount
          price
          specialNotes
          buyer
          selectedOptions
        }
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          stripeID
          email
          age
          phone
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          studentType
          viaNotification
          viaEmail
          accessToMerchant
          createdAt
          updatedAt
        }
        merchant {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        viaSMS
        sendMagazine
        accessToMerchant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      firstName
      lastName
      stripeID
      email
      age
      phone
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      studentType
      viaNotification
      viaEmail
      viaSMS
      sendMagazine
      accessToMerchant
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        imageID
        enabled
        createdAt
        updatedAt
        merchants {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      description
      imageID
      enabled
      createdAt
      updatedAt
      merchants {
        items {
          id
          categoryID
          merchantID
          createdAt
          updatedAt
          category {
            id
            name
            description
            imageID
            enabled
            createdAt
            updatedAt
            merchants {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
        }
        nextToken
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const listMerchants = /* GraphQL */ `
  query ListMerchants(
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMerchant = /* GraphQL */ `
  query GetMerchant($id: ID!) {
    getMerchant(id: $id) {
      id
      name
      email
      phone
      usesAppFolio
      imageID
      coverID
      saverAdID
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      schedule {
        day
        openAt
        workingHours
        closed
        label
      }
      enabled
      deliveryFee {
        basketMinimum
        threshold
        extraFee
        fixedFee
      }
      serviceFee {
        threshold
        reducedFee
        fixedFee
      }
      deliveryWebsite
      sort
      socialMedia {
        twitter
        facebook
        instagram
        website
      }
      priority
      type
      createdAt
      updatedAt
      categories {
        items {
          id
          categoryID
          merchantID
          createdAt
          updatedAt
          category {
            id
            name
            description
            imageID
            enabled
            createdAt
            updatedAt
            merchants {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
        }
        nextToken
      }
      menus {
        items {
          id
          name
          description
          imageID
          merchantID
          createdAt
          updatedAt
          products {
            items {
              id
              name
              description
              imageID
              merchantID
              menuID
              price
              enabled
              featured
              createdAt
              updatedAt
            }
            nextToken
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      logo {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      cover {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      saverAd {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      coupons {
        items {
          id
          merchantID
          title
          content
          disclaimer
          expirationDate
          useUniversalExpirationDate
          isSpecial
          valueType
          redeemable
          enabled
          featured
          value
          createdAt
          updatedAt
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
          redemptions {
            items {
              id
              customerID
              couponID
              expirationDate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      imageID
      merchantID
      menuID
      price
      enabled
      featured
      options {
        label
        items {
          name
          price
        }
        limit
        required
      }
      createdAt
      updatedAt
      menu {
        id
        name
        description
        imageID
        merchantID
        createdAt
        updatedAt
        products {
          items {
            id
            name
            description
            imageID
            merchantID
            menuID
            price
            enabled
            featured
            options {
              label
              limit
              required
            }
            createdAt
            updatedAt
            menu {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        imageID
        merchantID
        menuID
        price
        enabled
        featured
        options {
          label
          items {
            name
            price
          }
          limit
          required
        }
        createdAt
        updatedAt
        menu {
          id
          name
          description
          imageID
          merchantID
          createdAt
          updatedAt
          products {
            items {
              id
              name
              description
              imageID
              merchantID
              menuID
              price
              enabled
              featured
              createdAt
              updatedAt
            }
            nextToken
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listMenus = /* GraphQL */ `
  query ListMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        imageID
        merchantID
        createdAt
        updatedAt
        products {
          items {
            id
            name
            description
            imageID
            merchantID
            menuID
            price
            enabled
            featured
            options {
              label
              limit
              required
            }
            createdAt
            updatedAt
            menu {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMenu = /* GraphQL */ `
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      name
      description
      imageID
      merchantID
      createdAt
      updatedAt
      products {
        items {
          id
          name
          description
          imageID
          merchantID
          menuID
          price
          enabled
          featured
          options {
            label
            items {
              name
              price
            }
            limit
            required
          }
          createdAt
          updatedAt
          menu {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      key
      name
      bucket
      region
      owner
      propertyID
      unitID
      createdAt
      updatedAt
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const imagesByOwner = /* GraphQL */ `
  query ImagesByOwner(
    $owner: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ImagesByOwner(
      owner: $owner
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfig = /* GraphQL */ `
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      id
      universalExpirationDate
      iOSAppLink
      AndroidAppLink
      createdAt
      updatedAt
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs(
    $filter: ModelConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        universalExpirationDate
        iOSAppLink
        AndroidAppLink
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        merchantID
        title
        content
        disclaimer
        expirationDate
        useUniversalExpirationDate
        isSpecial
        valueType
        redeemable
        enabled
        featured
        value
        createdAt
        updatedAt
        merchant {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        redemptions {
          items {
            id
            customerID
            couponID
            expirationDate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              stripeID
              email
              age
              phone
              studentType
              viaNotification
              viaEmail
              accessToMerchant
              createdAt
              updatedAt
            }
            coupon {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      merchantID
      title
      content
      disclaimer
      expirationDate
      useUniversalExpirationDate
      isSpecial
      valueType
      redeemable
      enabled
      featured
      value
      createdAt
      updatedAt
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      redemptions {
        items {
          id
          customerID
          couponID
          expirationDate
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            stripeID
            email
            age
            phone
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            studentType
            viaNotification
            viaEmail
            accessToMerchant
            createdAt
            updatedAt
          }
          coupon {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getRedemption = /* GraphQL */ `
  query GetRedemption($id: ID!) {
    getRedemption(id: $id) {
      id
      customerID
      couponID
      expirationDate
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        accessToMerchant
        createdAt
        updatedAt
      }
      coupon {
        id
        merchantID
        title
        content
        disclaimer
        expirationDate
        useUniversalExpirationDate
        isSpecial
        valueType
        redeemable
        enabled
        featured
        value
        createdAt
        updatedAt
        merchant {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        redemptions {
          items {
            id
            customerID
            couponID
            expirationDate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              stripeID
              email
              age
              phone
              studentType
              viaNotification
              viaEmail
              accessToMerchant
              createdAt
              updatedAt
            }
            coupon {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listRedemptions = /* GraphQL */ `
  query ListRedemptions(
    $filter: ModelRedemptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRedemptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        couponID
        expirationDate
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          stripeID
          email
          age
          phone
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          studentType
          viaNotification
          viaEmail
          accessToMerchant
          createdAt
          updatedAt
        }
        coupon {
          id
          merchantID
          title
          content
          disclaimer
          expirationDate
          useUniversalExpirationDate
          isSpecial
          valueType
          redeemable
          enabled
          featured
          value
          createdAt
          updatedAt
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
          redemptions {
            items {
              id
              customerID
              couponID
              expirationDate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listPropertys = /* GraphQL */ `
  query ListPropertys(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appFolioPropertyID
        usesAppFolio
        managerID
        coverImageKey
        marketingName
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        phoneNumber
        emailAddress
        description
        minRent
        maxRent
        type
        amenities {
          heatIncluded
          residentPaysHeat
          electricityIncluded
          residentPaysElectricity
          gasIncluded
          residentPaysGas
          waterIncluded
          residentPaysWater
          otherUtilities
          smallDogsAllowed
          catsAllowed
          noDogsAllowed
          noCatsAllowed
          noPetsAllowed
          otherPets
          monthToMonth
          twoMonths
          threeMonths
          fourMonths
          sixMonths
          nineMonths
          tenMonths
          twelveMonths
          twentyFourMonths
          otherLease
          dishwasher
          airConditioningWall
          airConditioning
          refrigerator
          range
          oven
          garbageDisposal
          washerAndDryer
          washerAndDryerHookup
          otherAppliances
          furnished
          unfurnished
          parking
          otherAmenities
          eightMonths
          pool
          exerciseFacility
          internetIncluded
          internetAvailable
          microwave
          mealProgramAvailable
          homeHealthServicesAvailable
          transportationServiceAvailable
          elevatorInBuilding
          rampedEntry
          accessibleParking
          wideDoorways
          wideHallways
          leverStyleDoorHandles
          environmentalControls
          grabBarsInBathroom
          rollInShower
          tTurnInBathroom
          accessibleBathroomVanities
          frontLoadingKitchenAppliances
          accesibleKitchenCounters
          clearFloorAreaInFrontOfSink
          otherAccessibility
          smokeFree
          rentToOwn
          basement
          residentPaysTrashPickUp
          trashPickUpIncluded
          garage
          yard
          patio
          balcony
          deck
          washerAndDryerOnSite
          largeDogsAllowed
          offStreetParking
          emergencyMaintenance
          entranceSecured
          virtualTours
          roommateMatchingServiceAvailable
          managerOnSite
          socialEvents
          bedroomsKeyedSeparately
          dogsAllowed
          cableTVIncluded
          graniteCountertops
          individualLeases
          patioBalconyDeck
          petDetails
          shortTermLeases
        }
        numberOfUnits
        photoUrls
        area
        enabled
        createdAt
        updatedAt
        manager {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        images {
          items {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          nextToken
        }
        units {
          items {
            id
            propertyID
            managerID
            usesAppFolio
            coverImageKey
            rent
            maxRent
            minDeposit
            maxDeposit
            securityDeposit
            isOpenToLease
            dateAvailable
            rentIsBasedOnIncome
            bedrooms
            fullBaths
            halfBaths
            totalBaths
            minSquareFeet
            maxSquareFeet
            isMobilityAccesible
            isVisionAccesible
            isHearingAccesible
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            photoUrls
            availableSeason
            enabled
            createdAt
            updatedAt
            images {
              items {
                id
                name
                key
                owner
                propertyID
                unitID
              }
              nextToken
            }
            property {
              id
              appFolioPropertyID
              usesAppFolio
              managerID
              coverImageKey
              marketingName
              phoneNumber
              emailAddress
              description
              minRent
              maxRent
              type
              numberOfUnits
              photoUrls
              area
              enabled
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      appFolioPropertyID
      usesAppFolio
      managerID
      coverImageKey
      marketingName
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      phoneNumber
      emailAddress
      description
      minRent
      maxRent
      type
      amenities {
        heatIncluded
        residentPaysHeat
        electricityIncluded
        residentPaysElectricity
        gasIncluded
        residentPaysGas
        waterIncluded
        residentPaysWater
        otherUtilities
        smallDogsAllowed
        catsAllowed
        noDogsAllowed
        noCatsAllowed
        noPetsAllowed
        otherPets
        monthToMonth
        twoMonths
        threeMonths
        fourMonths
        sixMonths
        nineMonths
        tenMonths
        twelveMonths
        twentyFourMonths
        otherLease
        dishwasher
        airConditioningWall
        airConditioning
        refrigerator
        range
        oven
        garbageDisposal
        washerAndDryer
        washerAndDryerHookup
        otherAppliances
        furnished
        unfurnished
        parking
        otherAmenities
        eightMonths
        pool
        exerciseFacility
        internetIncluded
        internetAvailable
        microwave
        mealProgramAvailable
        homeHealthServicesAvailable
        transportationServiceAvailable
        elevatorInBuilding
        rampedEntry
        accessibleParking
        wideDoorways
        wideHallways
        leverStyleDoorHandles
        environmentalControls
        grabBarsInBathroom
        rollInShower
        tTurnInBathroom
        accessibleBathroomVanities
        frontLoadingKitchenAppliances
        accesibleKitchenCounters
        clearFloorAreaInFrontOfSink
        otherAccessibility
        smokeFree
        rentToOwn
        basement
        residentPaysTrashPickUp
        trashPickUpIncluded
        garage
        yard
        patio
        balcony
        deck
        washerAndDryerOnSite
        largeDogsAllowed
        offStreetParking
        emergencyMaintenance
        entranceSecured
        virtualTours
        roommateMatchingServiceAvailable
        managerOnSite
        socialEvents
        bedroomsKeyedSeparately
        dogsAllowed
        cableTVIncluded
        graniteCountertops
        individualLeases
        patioBalconyDeck
        petDetails
        shortTermLeases
      }
      numberOfUnits
      photoUrls
      area
      enabled
      createdAt
      updatedAt
      manager {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      images {
        items {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        nextToken
      }
      units {
        items {
          id
          propertyID
          managerID
          usesAppFolio
          coverImageKey
          rent
          maxRent
          minDeposit
          maxDeposit
          securityDeposit
          isOpenToLease
          dateAvailable
          rentIsBasedOnIncome
          bedrooms
          fullBaths
          halfBaths
          totalBaths
          minSquareFeet
          maxSquareFeet
          isMobilityAccesible
          isVisionAccesible
          isHearingAccesible
          amenities {
            heatIncluded
            residentPaysHeat
            electricityIncluded
            residentPaysElectricity
            gasIncluded
            residentPaysGas
            waterIncluded
            residentPaysWater
            otherUtilities
            smallDogsAllowed
            catsAllowed
            noDogsAllowed
            noCatsAllowed
            noPetsAllowed
            otherPets
            monthToMonth
            twoMonths
            threeMonths
            fourMonths
            sixMonths
            nineMonths
            tenMonths
            twelveMonths
            twentyFourMonths
            otherLease
            dishwasher
            airConditioningWall
            airConditioning
            refrigerator
            range
            oven
            garbageDisposal
            washerAndDryer
            washerAndDryerHookup
            otherAppliances
            furnished
            unfurnished
            parking
            otherAmenities
            eightMonths
            pool
            exerciseFacility
            internetIncluded
            internetAvailable
            microwave
            mealProgramAvailable
            homeHealthServicesAvailable
            transportationServiceAvailable
            elevatorInBuilding
            rampedEntry
            accessibleParking
            wideDoorways
            wideHallways
            leverStyleDoorHandles
            environmentalControls
            grabBarsInBathroom
            rollInShower
            tTurnInBathroom
            accessibleBathroomVanities
            frontLoadingKitchenAppliances
            accesibleKitchenCounters
            clearFloorAreaInFrontOfSink
            otherAccessibility
            smokeFree
            rentToOwn
            basement
            residentPaysTrashPickUp
            trashPickUpIncluded
            garage
            yard
            patio
            balcony
            deck
            washerAndDryerOnSite
            largeDogsAllowed
            offStreetParking
            emergencyMaintenance
            entranceSecured
            virtualTours
            roommateMatchingServiceAvailable
            managerOnSite
            socialEvents
            bedroomsKeyedSeparately
            dogsAllowed
            cableTVIncluded
            graniteCountertops
            individualLeases
            patioBalconyDeck
            petDetails
            shortTermLeases
          }
          photoUrls
          availableSeason
          enabled
          createdAt
          updatedAt
          images {
            items {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            nextToken
          }
          property {
            id
            appFolioPropertyID
            usesAppFolio
            managerID
            coverImageKey
            marketingName
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            phoneNumber
            emailAddress
            description
            minRent
            maxRent
            type
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            numberOfUnits
            photoUrls
            area
            enabled
            createdAt
            updatedAt
            manager {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            images {
              nextToken
            }
            units {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      propertyID
      managerID
      usesAppFolio
      coverImageKey
      rent
      maxRent
      minDeposit
      maxDeposit
      securityDeposit
      isOpenToLease
      dateAvailable
      rentIsBasedOnIncome
      bedrooms
      fullBaths
      halfBaths
      totalBaths
      minSquareFeet
      maxSquareFeet
      isMobilityAccesible
      isVisionAccesible
      isHearingAccesible
      amenities {
        heatIncluded
        residentPaysHeat
        electricityIncluded
        residentPaysElectricity
        gasIncluded
        residentPaysGas
        waterIncluded
        residentPaysWater
        otherUtilities
        smallDogsAllowed
        catsAllowed
        noDogsAllowed
        noCatsAllowed
        noPetsAllowed
        otherPets
        monthToMonth
        twoMonths
        threeMonths
        fourMonths
        sixMonths
        nineMonths
        tenMonths
        twelveMonths
        twentyFourMonths
        otherLease
        dishwasher
        airConditioningWall
        airConditioning
        refrigerator
        range
        oven
        garbageDisposal
        washerAndDryer
        washerAndDryerHookup
        otherAppliances
        furnished
        unfurnished
        parking
        otherAmenities
        eightMonths
        pool
        exerciseFacility
        internetIncluded
        internetAvailable
        microwave
        mealProgramAvailable
        homeHealthServicesAvailable
        transportationServiceAvailable
        elevatorInBuilding
        rampedEntry
        accessibleParking
        wideDoorways
        wideHallways
        leverStyleDoorHandles
        environmentalControls
        grabBarsInBathroom
        rollInShower
        tTurnInBathroom
        accessibleBathroomVanities
        frontLoadingKitchenAppliances
        accesibleKitchenCounters
        clearFloorAreaInFrontOfSink
        otherAccessibility
        smokeFree
        rentToOwn
        basement
        residentPaysTrashPickUp
        trashPickUpIncluded
        garage
        yard
        patio
        balcony
        deck
        washerAndDryerOnSite
        largeDogsAllowed
        offStreetParking
        emergencyMaintenance
        entranceSecured
        virtualTours
        roommateMatchingServiceAvailable
        managerOnSite
        socialEvents
        bedroomsKeyedSeparately
        dogsAllowed
        cableTVIncluded
        graniteCountertops
        individualLeases
        patioBalconyDeck
        petDetails
        shortTermLeases
      }
      photoUrls
      availableSeason
      enabled
      createdAt
      updatedAt
      images {
        items {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        nextToken
      }
      property {
        id
        appFolioPropertyID
        usesAppFolio
        managerID
        coverImageKey
        marketingName
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        phoneNumber
        emailAddress
        description
        minRent
        maxRent
        type
        amenities {
          heatIncluded
          residentPaysHeat
          electricityIncluded
          residentPaysElectricity
          gasIncluded
          residentPaysGas
          waterIncluded
          residentPaysWater
          otherUtilities
          smallDogsAllowed
          catsAllowed
          noDogsAllowed
          noCatsAllowed
          noPetsAllowed
          otherPets
          monthToMonth
          twoMonths
          threeMonths
          fourMonths
          sixMonths
          nineMonths
          tenMonths
          twelveMonths
          twentyFourMonths
          otherLease
          dishwasher
          airConditioningWall
          airConditioning
          refrigerator
          range
          oven
          garbageDisposal
          washerAndDryer
          washerAndDryerHookup
          otherAppliances
          furnished
          unfurnished
          parking
          otherAmenities
          eightMonths
          pool
          exerciseFacility
          internetIncluded
          internetAvailable
          microwave
          mealProgramAvailable
          homeHealthServicesAvailable
          transportationServiceAvailable
          elevatorInBuilding
          rampedEntry
          accessibleParking
          wideDoorways
          wideHallways
          leverStyleDoorHandles
          environmentalControls
          grabBarsInBathroom
          rollInShower
          tTurnInBathroom
          accessibleBathroomVanities
          frontLoadingKitchenAppliances
          accesibleKitchenCounters
          clearFloorAreaInFrontOfSink
          otherAccessibility
          smokeFree
          rentToOwn
          basement
          residentPaysTrashPickUp
          trashPickUpIncluded
          garage
          yard
          patio
          balcony
          deck
          washerAndDryerOnSite
          largeDogsAllowed
          offStreetParking
          emergencyMaintenance
          entranceSecured
          virtualTours
          roommateMatchingServiceAvailable
          managerOnSite
          socialEvents
          bedroomsKeyedSeparately
          dogsAllowed
          cableTVIncluded
          graniteCountertops
          individualLeases
          patioBalconyDeck
          petDetails
          shortTermLeases
        }
        numberOfUnits
        photoUrls
        area
        enabled
        createdAt
        updatedAt
        manager {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        images {
          items {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          nextToken
        }
        units {
          items {
            id
            propertyID
            managerID
            usesAppFolio
            coverImageKey
            rent
            maxRent
            minDeposit
            maxDeposit
            securityDeposit
            isOpenToLease
            dateAvailable
            rentIsBasedOnIncome
            bedrooms
            fullBaths
            halfBaths
            totalBaths
            minSquareFeet
            maxSquareFeet
            isMobilityAccesible
            isVisionAccesible
            isHearingAccesible
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            photoUrls
            availableSeason
            enabled
            createdAt
            updatedAt
            images {
              nextToken
            }
            property {
              id
              appFolioPropertyID
              usesAppFolio
              managerID
              coverImageKey
              marketingName
              phoneNumber
              emailAddress
              description
              minRent
              maxRent
              type
              numberOfUnits
              photoUrls
              area
              enabled
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        propertyID
        managerID
        usesAppFolio
        coverImageKey
        rent
        maxRent
        minDeposit
        maxDeposit
        securityDeposit
        isOpenToLease
        dateAvailable
        rentIsBasedOnIncome
        bedrooms
        fullBaths
        halfBaths
        totalBaths
        minSquareFeet
        maxSquareFeet
        isMobilityAccesible
        isVisionAccesible
        isHearingAccesible
        amenities {
          heatIncluded
          residentPaysHeat
          electricityIncluded
          residentPaysElectricity
          gasIncluded
          residentPaysGas
          waterIncluded
          residentPaysWater
          otherUtilities
          smallDogsAllowed
          catsAllowed
          noDogsAllowed
          noCatsAllowed
          noPetsAllowed
          otherPets
          monthToMonth
          twoMonths
          threeMonths
          fourMonths
          sixMonths
          nineMonths
          tenMonths
          twelveMonths
          twentyFourMonths
          otherLease
          dishwasher
          airConditioningWall
          airConditioning
          refrigerator
          range
          oven
          garbageDisposal
          washerAndDryer
          washerAndDryerHookup
          otherAppliances
          furnished
          unfurnished
          parking
          otherAmenities
          eightMonths
          pool
          exerciseFacility
          internetIncluded
          internetAvailable
          microwave
          mealProgramAvailable
          homeHealthServicesAvailable
          transportationServiceAvailable
          elevatorInBuilding
          rampedEntry
          accessibleParking
          wideDoorways
          wideHallways
          leverStyleDoorHandles
          environmentalControls
          grabBarsInBathroom
          rollInShower
          tTurnInBathroom
          accessibleBathroomVanities
          frontLoadingKitchenAppliances
          accesibleKitchenCounters
          clearFloorAreaInFrontOfSink
          otherAccessibility
          smokeFree
          rentToOwn
          basement
          residentPaysTrashPickUp
          trashPickUpIncluded
          garage
          yard
          patio
          balcony
          deck
          washerAndDryerOnSite
          largeDogsAllowed
          offStreetParking
          emergencyMaintenance
          entranceSecured
          virtualTours
          roommateMatchingServiceAvailable
          managerOnSite
          socialEvents
          bedroomsKeyedSeparately
          dogsAllowed
          cableTVIncluded
          graniteCountertops
          individualLeases
          patioBalconyDeck
          petDetails
          shortTermLeases
        }
        photoUrls
        availableSeason
        enabled
        createdAt
        updatedAt
        images {
          items {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          nextToken
        }
        property {
          id
          appFolioPropertyID
          usesAppFolio
          managerID
          coverImageKey
          marketingName
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          phoneNumber
          emailAddress
          description
          minRent
          maxRent
          type
          amenities {
            heatIncluded
            residentPaysHeat
            electricityIncluded
            residentPaysElectricity
            gasIncluded
            residentPaysGas
            waterIncluded
            residentPaysWater
            otherUtilities
            smallDogsAllowed
            catsAllowed
            noDogsAllowed
            noCatsAllowed
            noPetsAllowed
            otherPets
            monthToMonth
            twoMonths
            threeMonths
            fourMonths
            sixMonths
            nineMonths
            tenMonths
            twelveMonths
            twentyFourMonths
            otherLease
            dishwasher
            airConditioningWall
            airConditioning
            refrigerator
            range
            oven
            garbageDisposal
            washerAndDryer
            washerAndDryerHookup
            otherAppliances
            furnished
            unfurnished
            parking
            otherAmenities
            eightMonths
            pool
            exerciseFacility
            internetIncluded
            internetAvailable
            microwave
            mealProgramAvailable
            homeHealthServicesAvailable
            transportationServiceAvailable
            elevatorInBuilding
            rampedEntry
            accessibleParking
            wideDoorways
            wideHallways
            leverStyleDoorHandles
            environmentalControls
            grabBarsInBathroom
            rollInShower
            tTurnInBathroom
            accessibleBathroomVanities
            frontLoadingKitchenAppliances
            accesibleKitchenCounters
            clearFloorAreaInFrontOfSink
            otherAccessibility
            smokeFree
            rentToOwn
            basement
            residentPaysTrashPickUp
            trashPickUpIncluded
            garage
            yard
            patio
            balcony
            deck
            washerAndDryerOnSite
            largeDogsAllowed
            offStreetParking
            emergencyMaintenance
            entranceSecured
            virtualTours
            roommateMatchingServiceAvailable
            managerOnSite
            socialEvents
            bedroomsKeyedSeparately
            dogsAllowed
            cableTVIncluded
            graniteCountertops
            individualLeases
            patioBalconyDeck
            petDetails
            shortTermLeases
          }
          numberOfUnits
          photoUrls
          area
          enabled
          createdAt
          updatedAt
          manager {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
          images {
            items {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            nextToken
          }
          units {
            items {
              id
              propertyID
              managerID
              usesAppFolio
              coverImageKey
              rent
              maxRent
              minDeposit
              maxDeposit
              securityDeposit
              isOpenToLease
              dateAvailable
              rentIsBasedOnIncome
              bedrooms
              fullBaths
              halfBaths
              totalBaths
              minSquareFeet
              maxSquareFeet
              isMobilityAccesible
              isVisionAccesible
              isHearingAccesible
              photoUrls
              availableSeason
              enabled
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getFavorite = /* GraphQL */ `
  query GetFavorite($id: ID!) {
    getFavorite(id: $id) {
      id
      itemID
      customerID
      type
      createdAt
      updatedAt
    }
  }
`;
export const listFavorites = /* GraphQL */ `
  query ListFavorites(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemID
        customerID
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
