export const outboundLinks = {
  privacyPolicy:
    'https://www.privacypolicies.com/live/a32ea2e0-d02f-49e0-b3a4-6102866b6057',
  termsAndConditions:
    'https://www.privacypolicies.com/live/0d43d087-33fa-4be0-a1b3-fd501d9bff0e',
};

export const storeLinks = {
  android:
    'https://play.google.com/store/apps/details?id=com.saverapp&hl=en&gl=US',
  apple: 'https://apps.apple.com/us/app/the-saver/id1528921619',
};

export default outboundLinks;
