import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchText, setSearchText] = useState('');

  const clearSearchHandler = () => {
    setSearchText('');
  };

  return (
    <SearchContext.Provider
      value={{ searchText, setSearchText, clearSearchHandler }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default SearchContext;
