import { API } from 'aws-amplify';
import { getDistance } from 'geolib';
import { find, propEq, uniq, isEmpty, equals } from 'ramda';
import moment from 'moment';
import { listCoupons } from '../graphql/queries';

const days = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const week = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

function compare(a, b) {
  if (a > b) return 1;
  if (b > a) return -1;

  return 0;
}

const sortASCEnabled = (categories) => {
  const sortedCategories = [
    ...categories.sort((x, y) => compare(x.name, y.name)),
  ];

  const categoryItems = [];
  sortedCategories.forEach((c) => {
    if (c.enabled) {
      categoryItems.push(c);
    }
  });

  return categoryItems;
};

const requestAllFeaturedOffers = async (
  data = {
    items: [],
    nextToken: null,
    first: true,
  }
) => {
  if (!data.nextToken && !data.first) {
    return data.items;
  }
  const offersReq = await API.graphql({
    query: listCoupons,
    variables: {
      nextToken: data.nextToken,
      filter: { enabled: { eq: true }, featured: { eq: true } },
    },
    authMode: 'API_KEY',
  });
  return requestAllFeaturedOffers({
    items: [...data.items, ...offersReq.data.listCoupons.items],
    nextToken: offersReq.data.listCoupons.nextToken,
    first: false,
  });
};

const getFeaturedOffers = async () => {
  const result = await requestAllFeaturedOffers();
  const today = moment();
  const filteredCoupons = result.filter(
    (coupon) => !moment(today).isSameOrAfter(coupon.expirationDate)
  );
  const offers = filteredCoupons;

  return offers;
};

const getDrivingDistance = async (userLocation, businessLocation) => {
  const pointA = {
    latitude: userLocation.latitude,
    longitude: userLocation.longitude,
  };
  const pointB = {
    latitude: businessLocation.lat,
    longitude: businessLocation.lng,
  };
  const distanceInMeters = getDistance(pointA, pointB);
  const disntanceInMiles = (distanceInMeters / 1609).toFixed(2);
  const distance =
    disntanceInMiles >= 1 ? Math.round(disntanceInMiles) : disntanceInMiles;
  const distanceObject = {
    text: distance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    value: distance,
  };

  return distanceObject;
};

const getItemRange = (item, field) => {
  return uniq(
    item.units.items.map((unit) => {
      if (equals(field, 'fullBaths')) {
        const halfBaths = unit.halfBaths * 0.5;
        return unit[field] + halfBaths;
      }
      return unit[field];
    })
  ).sort((a, b) => a - b);
};

const isOpen = (schedule) => {
  if (schedule) {
    const weekDay = days[moment().isoWeekday()];
    const day = find(propEq('day', weekDay))(schedule);
    if (!day) {
      return false;
    }
    return day.closed
      ? false
      : moment().isBetween(
          moment(day.openAt, 'H:mm'),
          moment(day.openAt, 'H:mm').add(day.workingHours, 'h')
        );
  }
  return false;
};

const getTodaySchedule = (schedule) => {
  if (schedule) {
    const weekDay = days[moment().isoWeekday()];
    const day = find(propEq('day', weekDay))(schedule);
    if (!day) {
      return '';
    }

    const format = 'h:mm a';
    const from = moment(day.openAt, 'H:mm').format(format);
    const to = moment(day.openAt, 'H:mm')
      .add(day.workingHours, 'h')
      .format(format);
    return `Today's Hours\n${from} to ${to}`;
  }
  return '';
};

const formatNumbers = async (number, character) => {
  return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, character)}`;
};

const getPropertiesRanges = async (items) => {
  const properties = items.map((property) => {
    if (!isEmpty(property.units.items)) {
      const baths = getItemRange(property, 'fullBaths');
      const beds = getItemRange(property, 'bedrooms');
      const rents = getItemRange(property, 'rent');
      const maxRents = getItemRange(property, 'maxRent');
      const bathsRange =
        baths.length > 1
          ? `${baths[0]}-${baths[baths.length - 1]} baths`
          : baths[0] === 1
          ? `${baths[0]} bath`
          : `${baths[0]} baths`;
      const bedsRange =
        beds.length > 1
          ? `${beds[0]}-${beds[beds.length - 1]} beds`
          : beds[0] === 1 || beds[0] === 0
          ? beds[0] > 0
            ? `${beds[0]} bed`
            : 'Studio'
          : `${beds[0]} beds`;
      let rentRanges = '';
      if (rents[0] && maxRents[maxRents.length - 1]) {
        rentRanges = `$${formatNumbers(rents[0], ',')} - $${formatNumbers(
          maxRents[maxRents.length - 1],
          ','
        )}`;
        if (rents[0] === maxRents[maxRents.length - 1]) {
          rentRanges = `$${formatNumbers(rents[0], ',')}`;
        }
      } else {
        rentRanges = 'Contact Us';
      }
      property.bathsRange = bathsRange;
      property.bedsRange = bedsRange;
      property.rentRanges = rentRanges;
    }
    return property;
  });
  return properties;
};

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export {
  compare,
  requestAllFeaturedOffers,
  getFeaturedOffers,
  getDrivingDistance,
  getPropertiesRanges,
  isOpen,
  getTodaySchedule,
  formatPhoneNumber,
  week,
  sortASCEnabled,
};
