import useTitle from '../hooks/useTitle';
import styles from '../styles/About.module.css';

const About = () => {
  useTitle('About');

  return (
    <div data-testid="about">
      <div className={styles.container}>
        <div className={styles.header}>
          <img src="/icons/s-logo.svg" alt="logo" data-testid="logo" />
          <h1 data-testid="title">The Saver Story</h1>
        </div>
        <p className={styles.info} data-testid="about-paragraph">
          If you&apos;re a Buckeye, you probably know us. That&apos;s because
          we&apos;re an original - one of the longest - standing and most
          popular publications on campus. More importantly, our savings are for
          Buckeyes, by a Buckeye: Our own founder was a junior in 1986 when he
          started The Saver - and he bleeds scarlet and gray to this day.
        </p>

        <p className={styles.info} data-testid="about-paragraph-2">
          So whether you&apos;re browsing through our magazine&apos;s pages,
          snagging our Steal, or using an online coupon from our website, you
          can be sure you won&apos;t find a better student savings source.
        </p>

        <p className={styles.issue} data-testid="about-paragraph-3">
          The very first issue of The Saver ... what can we say, it was the
          80&apos;s.
        </p>
        <section className={styles.saverImage}>
          <img src="/images/firstsaver.jpg" alt="firstsaver" />
        </section>
      </div>
    </div>
  );
};

export default About;
