import { Link } from 'react-router-dom';
import styles from '../../styles/Auth.module.css';

const LoginHeader = () => {
  return (
    <Link to="/" className={styles.headerWrapper} passhref>
      <img
        src="/icons/saverLogo.svg"
        alt=""
        className={styles.responsiveLogo}
      />
      <span className={styles.slogan}>Faster. Cheaper. Simpler.</span>
    </Link>
  );
};

export default LoginHeader;
