import actionTypes from '../constants/actionTypes';
import errorHandler from '../../utils/errorHandler';
import searchForProperties from '../../api/aptSearchService';

export function searchPropertiesAction(searchInput, properties) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.SEARCH_PROPERTIES_START));
    try {
      const filtered = searchForProperties(searchInput, properties);
      dispatch(actionReducer(filtered, actionTypes.SEARCH_PROPERTIES_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.SEARCH_PROPERTIES_FAIL));
    }
  };
}

const actionReducer = (payload, type) => ({
  type,
  payload,
});
