import PropTypes from 'prop-types';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';

const FooterImage = ({ link, src, dataTestId, className, alt }) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    onClick={() => {
      if (dataTestId === 'appleStore' || dataTestId === 'playStore') {
        logAnalyticsEvent({
          label: link,
          action: ACTIONS.CLICK,
          category: CATEGORIES.APP_LINKS,
        });
      } else {
        logAnalyticsEvent({
          label: link,
          action: ACTIONS.CLICK,
          category: CATEGORIES.EXTERNAL_LINK,
        });
      }
    }}
  >
    <img src={src} data-testid={dataTestId} className={className} alt={alt} />
  </a>
);

FooterImage.propTypes = {
  link: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
};

FooterImage.defaultProps = {
  dataTestId: 'footer-image',
  className: '',
  alt: 'Footer Image',
};

export default FooterImage;
