/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      stripeID
      email
      age
      phone
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      studentType
      viaNotification
      viaEmail
      viaSMS
      accessToMerchant
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      stripeID
      email
      age
      phone
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      studentType
      viaNotification
      viaSMS
      viaEmail
      accessToMerchant
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      stripeID
      email
      age
      phone
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      studentType
      viaNotification
      viaEmail
      accessToMerchant
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      enabled
      createdAt
      updatedAt
      merchants {
        items {
          id
          categoryID
          merchantID
          createdAt
          updatedAt
          category {
            id
            name
            description
            imageID
            enabled
            createdAt
            updatedAt
            merchants {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
        }
        nextToken
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      enabled
      createdAt
      updatedAt
      merchants {
        items {
          id
          categoryID
          merchantID
          createdAt
          updatedAt
          category {
            id
            name
            description
            imageID
            enabled
            createdAt
            updatedAt
            merchants {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
        }
        nextToken
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      enabled
      createdAt
      updatedAt
      merchants {
        items {
          id
          categoryID
          merchantID
          createdAt
          updatedAt
          category {
            id
            name
            description
            imageID
            enabled
            createdAt
            updatedAt
            merchants {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
        }
        nextToken
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMerchantCategory = /* GraphQL */ `
  mutation CreateMerchantCategory(
    $input: CreateMerchantCategoryInput!
    $condition: ModelMerchantCategoryConditionInput
  ) {
    createMerchantCategory(input: $input, condition: $condition) {
      id
      categoryID
      merchantID
      createdAt
      updatedAt
      category {
        id
        name
        description
        imageID
        enabled
        createdAt
        updatedAt
        merchants {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateMerchantCategory = /* GraphQL */ `
  mutation UpdateMerchantCategory(
    $input: UpdateMerchantCategoryInput!
    $condition: ModelMerchantCategoryConditionInput
  ) {
    updateMerchantCategory(input: $input, condition: $condition) {
      id
      categoryID
      merchantID
      createdAt
      updatedAt
      category {
        id
        name
        description
        imageID
        enabled
        createdAt
        updatedAt
        merchants {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteMerchantCategory = /* GraphQL */ `
  mutation DeleteMerchantCategory(
    $input: DeleteMerchantCategoryInput!
    $condition: ModelMerchantCategoryConditionInput
  ) {
    deleteMerchantCategory(input: $input, condition: $condition) {
      id
      categoryID
      merchantID
      createdAt
      updatedAt
      category {
        id
        name
        description
        imageID
        enabled
        createdAt
        updatedAt
        merchants {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createMerchant = /* GraphQL */ `
  mutation CreateMerchant(
    $input: CreateMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    createMerchant(input: $input, condition: $condition) {
      id
      name
      email
      phone
      usesAppFolio
      imageID
      coverID
      saverAdID
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      schedule {
        day
        openAt
        workingHours
        closed
        label
      }
      enabled
      deliveryFee {
        basketMinimum
        threshold
        extraFee
        fixedFee
      }
      serviceFee {
        threshold
        reducedFee
        fixedFee
      }
      deliveryWebsite
      sort
      socialMedia {
        twitter
        facebook
        instagram
        website
      }
      priority
      type
      createdAt
      updatedAt
      categories {
        items {
          id
          categoryID
          merchantID
          createdAt
          updatedAt
          category {
            id
            name
            description
            imageID
            enabled
            createdAt
            updatedAt
            merchants {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
        }
        nextToken
      }
      menus {
        items {
          id
          name
          description
          imageID
          merchantID
          createdAt
          updatedAt
          products {
            items {
              id
              name
              description
              imageID
              merchantID
              menuID
              price
              enabled
              featured
              createdAt
              updatedAt
            }
            nextToken
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      logo {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      cover {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      saverAd {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      coupons {
        items {
          id
          merchantID
          title
          content
          disclaimer
          expirationDate
          useUniversalExpirationDate
          isSpecial
          valueType
          redeemable
          enabled
          featured
          value
          createdAt
          updatedAt
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
          redemptions {
            items {
              id
              customerID
              couponID
              expirationDate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateMerchant = /* GraphQL */ `
  mutation UpdateMerchant(
    $input: UpdateMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    updateMerchant(input: $input, condition: $condition) {
      id
      name
      email
      phone
      usesAppFolio
      imageID
      coverID
      saverAdID
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      schedule {
        day
        openAt
        workingHours
        closed
        label
      }
      enabled
      deliveryFee {
        basketMinimum
        threshold
        extraFee
        fixedFee
      }
      serviceFee {
        threshold
        reducedFee
        fixedFee
      }
      deliveryWebsite
      sort
      socialMedia {
        twitter
        facebook
        instagram
        website
      }
      priority
      type
      createdAt
      updatedAt
      categories {
        items {
          id
          categoryID
          merchantID
          createdAt
          updatedAt
          category {
            id
            name
            description
            imageID
            enabled
            createdAt
            updatedAt
            merchants {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
        }
        nextToken
      }
      menus {
        items {
          id
          name
          description
          imageID
          merchantID
          createdAt
          updatedAt
          products {
            items {
              id
              name
              description
              imageID
              merchantID
              menuID
              price
              enabled
              featured
              createdAt
              updatedAt
            }
            nextToken
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      logo {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      cover {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      saverAd {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      coupons {
        items {
          id
          merchantID
          title
          content
          disclaimer
          expirationDate
          useUniversalExpirationDate
          isSpecial
          valueType
          redeemable
          enabled
          featured
          value
          createdAt
          updatedAt
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
          redemptions {
            items {
              id
              customerID
              couponID
              expirationDate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteMerchant = /* GraphQL */ `
  mutation DeleteMerchant(
    $input: DeleteMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    deleteMerchant(input: $input, condition: $condition) {
      id
      name
      email
      phone
      usesAppFolio
      imageID
      coverID
      saverAdID
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      schedule {
        day
        openAt
        workingHours
        closed
        label
      }
      enabled
      deliveryFee {
        basketMinimum
        threshold
        extraFee
        fixedFee
      }
      serviceFee {
        threshold
        reducedFee
        fixedFee
      }
      deliveryWebsite
      sort
      socialMedia {
        twitter
        facebook
        instagram
        website
      }
      priority
      type
      createdAt
      updatedAt
      categories {
        items {
          id
          categoryID
          merchantID
          createdAt
          updatedAt
          category {
            id
            name
            description
            imageID
            enabled
            createdAt
            updatedAt
            merchants {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
        }
        nextToken
      }
      menus {
        items {
          id
          name
          description
          imageID
          merchantID
          createdAt
          updatedAt
          products {
            items {
              id
              name
              description
              imageID
              merchantID
              menuID
              price
              enabled
              featured
              createdAt
              updatedAt
            }
            nextToken
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      logo {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      cover {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      saverAd {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
      coupons {
        items {
          id
          merchantID
          title
          content
          disclaimer
          expirationDate
          useUniversalExpirationDate
          isSpecial
          valueType
          redeemable
          enabled
          featured
          value
          createdAt
          updatedAt
          merchant {
            id
            name
            email
            phone
            usesAppFolio
            imageID
            coverID
            saverAdID
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            schedule {
              day
              openAt
              workingHours
              closed
              label
            }
            enabled
            deliveryFee {
              basketMinimum
              threshold
              extraFee
              fixedFee
            }
            serviceFee {
              threshold
              reducedFee
              fixedFee
            }
            deliveryWebsite
            sort
            socialMedia {
              twitter
              facebook
              instagram
              website
            }
            priority
            type
            createdAt
            updatedAt
            categories {
              nextToken
            }
            menus {
              nextToken
            }
            logo {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            cover {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            saverAd {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            coupons {
              nextToken
            }
          }
          redemptions {
            items {
              id
              customerID
              couponID
              expirationDate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      merchantID
      menuID
      price
      enabled
      featured
      options {
        label
        items {
          name
          price
        }
        limit
        required
      }
      createdAt
      updatedAt
      menu {
        id
        name
        description
        imageID
        merchantID
        createdAt
        updatedAt
        products {
          items {
            id
            name
            description
            imageID
            merchantID
            menuID
            price
            enabled
            featured
            options {
              label
              limit
              required
            }
            createdAt
            updatedAt
            menu {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      merchantID
      menuID
      price
      enabled
      featured
      options {
        label
        items {
          name
          price
        }
        limit
        required
      }
      createdAt
      updatedAt
      menu {
        id
        name
        description
        imageID
        merchantID
        createdAt
        updatedAt
        products {
          items {
            id
            name
            description
            imageID
            merchantID
            menuID
            price
            enabled
            featured
            options {
              label
              limit
              required
            }
            createdAt
            updatedAt
            menu {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      merchantID
      menuID
      price
      enabled
      featured
      options {
        label
        items {
          name
          price
        }
        limit
        required
      }
      createdAt
      updatedAt
      menu {
        id
        name
        description
        imageID
        merchantID
        createdAt
        updatedAt
        products {
          items {
            id
            name
            description
            imageID
            merchantID
            menuID
            price
            enabled
            featured
            options {
              label
              limit
              required
            }
            createdAt
            updatedAt
            menu {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        image {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMenu = /* GraphQL */ `
  mutation CreateMenu(
    $input: CreateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    createMenu(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      merchantID
      createdAt
      updatedAt
      products {
        items {
          id
          name
          description
          imageID
          merchantID
          menuID
          price
          enabled
          featured
          options {
            label
            items {
              name
              price
            }
            limit
            required
          }
          createdAt
          updatedAt
          menu {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateMenu = /* GraphQL */ `
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      merchantID
      createdAt
      updatedAt
      products {
        items {
          id
          name
          description
          imageID
          merchantID
          menuID
          price
          enabled
          featured
          options {
            label
            items {
              name
              price
            }
            limit
            required
          }
          createdAt
          updatedAt
          menu {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteMenu = /* GraphQL */ `
  mutation DeleteMenu(
    $input: DeleteMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    deleteMenu(input: $input, condition: $condition) {
      id
      name
      description
      imageID
      merchantID
      createdAt
      updatedAt
      products {
        items {
          id
          name
          description
          imageID
          merchantID
          menuID
          price
          enabled
          featured
          options {
            label
            items {
              name
              price
            }
            limit
            required
          }
          createdAt
          updatedAt
          menu {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          image {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      image {
        id
        key
        name
        bucket
        region
        owner
        propertyID
        unitID
        createdAt
        updatedAt
      }
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      key
      name
      bucket
      region
      owner
      propertyID
      unitID
      createdAt
      updatedAt
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      key
      name
      bucket
      region
      owner
      propertyID
      unitID
      createdAt
      updatedAt
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      key
      name
      bucket
      region
      owner
      propertyID
      unitID
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      merchantID
      customerID
      paymentMethodID
      stripeID
      paymentConfirmed
      paymentID
      couponID
      status
      type
      orderTotal
      products {
        productID
        name
        amount
        price
        specialNotes
        buyer
        selectedOptions
      }
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        accessToMerchant
        createdAt
        updatedAt
      }
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      merchantID
      customerID
      paymentMethodID
      stripeID
      paymentConfirmed
      paymentID
      couponID
      status
      type
      orderTotal
      products {
        productID
        name
        amount
        price
        specialNotes
        buyer
        selectedOptions
      }
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        accessToMerchant
        createdAt
        updatedAt
      }
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      merchantID
      customerID
      paymentMethodID
      stripeID
      paymentConfirmed
      paymentID
      couponID
      status
      type
      orderTotal
      products {
        productID
        name
        amount
        price
        specialNotes
        buyer
        selectedOptions
      }
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        accessToMerchant
        createdAt
        updatedAt
      }
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig(
    $input: CreateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    createConfig(input: $input, condition: $condition) {
      id
      universalExpirationDate
      iOSAppLink
      AndroidAppLink
      createdAt
      updatedAt
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig(
    $input: UpdateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    updateConfig(input: $input, condition: $condition) {
      id
      universalExpirationDate
      iOSAppLink
      AndroidAppLink
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig(
    $input: DeleteConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    deleteConfig(input: $input, condition: $condition) {
      id
      universalExpirationDate
      iOSAppLink
      AndroidAppLink
      createdAt
      updatedAt
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      merchantID
      title
      content
      disclaimer
      expirationDate
      useUniversalExpirationDate
      isSpecial
      valueType
      redeemable
      enabled
      featured
      value
      createdAt
      updatedAt
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      redemptions {
        items {
          id
          customerID
          couponID
          expirationDate
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            stripeID
            email
            age
            phone
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            studentType
            viaNotification
            viaEmail
            accessToMerchant
            createdAt
            updatedAt
          }
          coupon {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      merchantID
      title
      content
      disclaimer
      expirationDate
      useUniversalExpirationDate
      isSpecial
      valueType
      redeemable
      enabled
      featured
      value
      createdAt
      updatedAt
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      redemptions {
        items {
          id
          customerID
          couponID
          expirationDate
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            stripeID
            email
            age
            phone
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            studentType
            viaNotification
            viaEmail
            accessToMerchant
            createdAt
            updatedAt
          }
          coupon {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      merchantID
      title
      content
      disclaimer
      expirationDate
      useUniversalExpirationDate
      isSpecial
      valueType
      redeemable
      enabled
      featured
      value
      createdAt
      updatedAt
      merchant {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      redemptions {
        items {
          id
          customerID
          couponID
          expirationDate
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            stripeID
            email
            age
            phone
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            studentType
            viaNotification
            viaEmail
            accessToMerchant
            createdAt
            updatedAt
          }
          coupon {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createRedemption = /* GraphQL */ `
  mutation CreateRedemption(
    $input: CreateRedemptionInput!
    $condition: ModelRedemptionConditionInput
  ) {
    createRedemption(input: $input, condition: $condition) {
      id
      customerID
      couponID
      expirationDate
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        accessToMerchant
        createdAt
        updatedAt
      }
      coupon {
        id
        merchantID
        title
        content
        disclaimer
        expirationDate
        useUniversalExpirationDate
        isSpecial
        valueType
        redeemable
        enabled
        featured
        value
        createdAt
        updatedAt
        merchant {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        redemptions {
          items {
            id
            customerID
            couponID
            expirationDate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              stripeID
              email
              age
              phone
              studentType
              viaNotification
              viaEmail
              accessToMerchant
              createdAt
              updatedAt
            }
            coupon {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateRedemption = /* GraphQL */ `
  mutation UpdateRedemption(
    $input: UpdateRedemptionInput!
    $condition: ModelRedemptionConditionInput
  ) {
    updateRedemption(input: $input, condition: $condition) {
      id
      customerID
      couponID
      expirationDate
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        accessToMerchant
        createdAt
        updatedAt
      }
      coupon {
        id
        merchantID
        title
        content
        disclaimer
        expirationDate
        useUniversalExpirationDate
        isSpecial
        valueType
        redeemable
        enabled
        featured
        value
        createdAt
        updatedAt
        merchant {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        redemptions {
          items {
            id
            customerID
            couponID
            expirationDate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              stripeID
              email
              age
              phone
              studentType
              viaNotification
              viaEmail
              accessToMerchant
              createdAt
              updatedAt
            }
            coupon {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteRedemption = /* GraphQL */ `
  mutation DeleteRedemption(
    $input: DeleteRedemptionInput!
    $condition: ModelRedemptionConditionInput
  ) {
    deleteRedemption(input: $input, condition: $condition) {
      id
      customerID
      couponID
      expirationDate
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        stripeID
        email
        age
        phone
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        studentType
        viaNotification
        viaEmail
        accessToMerchant
        createdAt
        updatedAt
      }
      coupon {
        id
        merchantID
        title
        content
        disclaimer
        expirationDate
        useUniversalExpirationDate
        isSpecial
        valueType
        redeemable
        enabled
        featured
        value
        createdAt
        updatedAt
        merchant {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        redemptions {
          items {
            id
            customerID
            couponID
            expirationDate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              stripeID
              email
              age
              phone
              studentType
              viaNotification
              viaEmail
              accessToMerchant
              createdAt
              updatedAt
            }
            coupon {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      appFolioPropertyID
      usesAppFolio
      managerID
      coverImageKey
      marketingName
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      phoneNumber
      emailAddress
      description
      minRent
      maxRent
      type
      amenities {
        heatIncluded
        residentPaysHeat
        electricityIncluded
        residentPaysElectricity
        gasIncluded
        residentPaysGas
        waterIncluded
        residentPaysWater
        otherUtilities
        smallDogsAllowed
        catsAllowed
        noDogsAllowed
        noCatsAllowed
        noPetsAllowed
        otherPets
        monthToMonth
        twoMonths
        threeMonths
        fourMonths
        sixMonths
        nineMonths
        tenMonths
        twelveMonths
        twentyFourMonths
        otherLease
        dishwasher
        airConditioningWall
        airConditioning
        refrigerator
        range
        oven
        garbageDisposal
        washerAndDryer
        washerAndDryerHookup
        otherAppliances
        furnished
        unfurnished
        parking
        otherAmenities
        eightMonths
        pool
        exerciseFacility
        internetIncluded
        internetAvailable
        microwave
        mealProgramAvailable
        homeHealthServicesAvailable
        transportationServiceAvailable
        elevatorInBuilding
        rampedEntry
        accessibleParking
        wideDoorways
        wideHallways
        leverStyleDoorHandles
        environmentalControls
        grabBarsInBathroom
        rollInShower
        tTurnInBathroom
        accessibleBathroomVanities
        frontLoadingKitchenAppliances
        accesibleKitchenCounters
        clearFloorAreaInFrontOfSink
        otherAccessibility
        smokeFree
        rentToOwn
        basement
        residentPaysTrashPickUp
        trashPickUpIncluded
        garage
        yard
        patio
        balcony
        deck
        washerAndDryerOnSite
        largeDogsAllowed
        offStreetParking
        emergencyMaintenance
        entranceSecured
        virtualTours
        roommateMatchingServiceAvailable
        managerOnSite
        socialEvents
        bedroomsKeyedSeparately
        dogsAllowed
        cableTVIncluded
        graniteCountertops
        individualLeases
        patioBalconyDeck
        petDetails
        shortTermLeases
      }
      numberOfUnits
      photoUrls
      area
      enabled
      createdAt
      updatedAt
      manager {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      images {
        items {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        nextToken
      }
      units {
        items {
          id
          propertyID
          managerID
          usesAppFolio
          coverImageKey
          rent
          maxRent
          minDeposit
          maxDeposit
          securityDeposit
          isOpenToLease
          dateAvailable
          rentIsBasedOnIncome
          bedrooms
          fullBaths
          halfBaths
          totalBaths
          minSquareFeet
          maxSquareFeet
          isMobilityAccesible
          isVisionAccesible
          isHearingAccesible
          amenities {
            heatIncluded
            residentPaysHeat
            electricityIncluded
            residentPaysElectricity
            gasIncluded
            residentPaysGas
            waterIncluded
            residentPaysWater
            otherUtilities
            smallDogsAllowed
            catsAllowed
            noDogsAllowed
            noCatsAllowed
            noPetsAllowed
            otherPets
            monthToMonth
            twoMonths
            threeMonths
            fourMonths
            sixMonths
            nineMonths
            tenMonths
            twelveMonths
            twentyFourMonths
            otherLease
            dishwasher
            airConditioningWall
            airConditioning
            refrigerator
            range
            oven
            garbageDisposal
            washerAndDryer
            washerAndDryerHookup
            otherAppliances
            furnished
            unfurnished
            parking
            otherAmenities
            eightMonths
            pool
            exerciseFacility
            internetIncluded
            internetAvailable
            microwave
            mealProgramAvailable
            homeHealthServicesAvailable
            transportationServiceAvailable
            elevatorInBuilding
            rampedEntry
            accessibleParking
            wideDoorways
            wideHallways
            leverStyleDoorHandles
            environmentalControls
            grabBarsInBathroom
            rollInShower
            tTurnInBathroom
            accessibleBathroomVanities
            frontLoadingKitchenAppliances
            accesibleKitchenCounters
            clearFloorAreaInFrontOfSink
            otherAccessibility
            smokeFree
            rentToOwn
            basement
            residentPaysTrashPickUp
            trashPickUpIncluded
            garage
            yard
            patio
            balcony
            deck
            washerAndDryerOnSite
            largeDogsAllowed
            offStreetParking
            emergencyMaintenance
            entranceSecured
            virtualTours
            roommateMatchingServiceAvailable
            managerOnSite
            socialEvents
            bedroomsKeyedSeparately
            dogsAllowed
            cableTVIncluded
            graniteCountertops
            individualLeases
            patioBalconyDeck
            petDetails
            shortTermLeases
          }
          photoUrls
          availableSeason
          enabled
          createdAt
          updatedAt
          images {
            items {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            nextToken
          }
          property {
            id
            appFolioPropertyID
            usesAppFolio
            managerID
            coverImageKey
            marketingName
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            phoneNumber
            emailAddress
            description
            minRent
            maxRent
            type
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            numberOfUnits
            photoUrls
            area
            enabled
            createdAt
            updatedAt
            manager {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            images {
              nextToken
            }
            units {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      id
      propertyID
      managerID
      usesAppFolio
      coverImageKey
      rent
      maxRent
      minDeposit
      maxDeposit
      securityDeposit
      isOpenToLease
      dateAvailable
      rentIsBasedOnIncome
      bedrooms
      fullBaths
      halfBaths
      totalBaths
      minSquareFeet
      maxSquareFeet
      isMobilityAccesible
      isVisionAccesible
      isHearingAccesible
      amenities {
        heatIncluded
        residentPaysHeat
        electricityIncluded
        residentPaysElectricity
        gasIncluded
        residentPaysGas
        waterIncluded
        residentPaysWater
        otherUtilities
        smallDogsAllowed
        catsAllowed
        noDogsAllowed
        noCatsAllowed
        noPetsAllowed
        otherPets
        monthToMonth
        twoMonths
        threeMonths
        fourMonths
        sixMonths
        nineMonths
        tenMonths
        twelveMonths
        twentyFourMonths
        otherLease
        dishwasher
        airConditioningWall
        airConditioning
        refrigerator
        range
        oven
        garbageDisposal
        washerAndDryer
        washerAndDryerHookup
        otherAppliances
        furnished
        unfurnished
        parking
        otherAmenities
        eightMonths
        pool
        exerciseFacility
        internetIncluded
        internetAvailable
        microwave
        mealProgramAvailable
        homeHealthServicesAvailable
        transportationServiceAvailable
        elevatorInBuilding
        rampedEntry
        accessibleParking
        wideDoorways
        wideHallways
        leverStyleDoorHandles
        environmentalControls
        grabBarsInBathroom
        rollInShower
        tTurnInBathroom
        accessibleBathroomVanities
        frontLoadingKitchenAppliances
        accesibleKitchenCounters
        clearFloorAreaInFrontOfSink
        otherAccessibility
        smokeFree
        rentToOwn
        basement
        residentPaysTrashPickUp
        trashPickUpIncluded
        garage
        yard
        patio
        balcony
        deck
        washerAndDryerOnSite
        largeDogsAllowed
        offStreetParking
        emergencyMaintenance
        entranceSecured
        virtualTours
        roommateMatchingServiceAvailable
        managerOnSite
        socialEvents
        bedroomsKeyedSeparately
        dogsAllowed
        cableTVIncluded
        graniteCountertops
        individualLeases
        patioBalconyDeck
        petDetails
        shortTermLeases
      }
      photoUrls
      availableSeason
      enabled
      createdAt
      updatedAt
      images {
        items {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        nextToken
      }
      property {
        id
        appFolioPropertyID
        usesAppFolio
        managerID
        coverImageKey
        marketingName
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        phoneNumber
        emailAddress
        description
        minRent
        maxRent
        type
        amenities {
          heatIncluded
          residentPaysHeat
          electricityIncluded
          residentPaysElectricity
          gasIncluded
          residentPaysGas
          waterIncluded
          residentPaysWater
          otherUtilities
          smallDogsAllowed
          catsAllowed
          noDogsAllowed
          noCatsAllowed
          noPetsAllowed
          otherPets
          monthToMonth
          twoMonths
          threeMonths
          fourMonths
          sixMonths
          nineMonths
          tenMonths
          twelveMonths
          twentyFourMonths
          otherLease
          dishwasher
          airConditioningWall
          airConditioning
          refrigerator
          range
          oven
          garbageDisposal
          washerAndDryer
          washerAndDryerHookup
          otherAppliances
          furnished
          unfurnished
          parking
          otherAmenities
          eightMonths
          pool
          exerciseFacility
          internetIncluded
          internetAvailable
          microwave
          mealProgramAvailable
          homeHealthServicesAvailable
          transportationServiceAvailable
          elevatorInBuilding
          rampedEntry
          accessibleParking
          wideDoorways
          wideHallways
          leverStyleDoorHandles
          environmentalControls
          grabBarsInBathroom
          rollInShower
          tTurnInBathroom
          accessibleBathroomVanities
          frontLoadingKitchenAppliances
          accesibleKitchenCounters
          clearFloorAreaInFrontOfSink
          otherAccessibility
          smokeFree
          rentToOwn
          basement
          residentPaysTrashPickUp
          trashPickUpIncluded
          garage
          yard
          patio
          balcony
          deck
          washerAndDryerOnSite
          largeDogsAllowed
          offStreetParking
          emergencyMaintenance
          entranceSecured
          virtualTours
          roommateMatchingServiceAvailable
          managerOnSite
          socialEvents
          bedroomsKeyedSeparately
          dogsAllowed
          cableTVIncluded
          graniteCountertops
          individualLeases
          patioBalconyDeck
          petDetails
          shortTermLeases
        }
        numberOfUnits
        photoUrls
        area
        enabled
        createdAt
        updatedAt
        manager {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        images {
          items {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          nextToken
        }
        units {
          items {
            id
            propertyID
            managerID
            usesAppFolio
            coverImageKey
            rent
            maxRent
            minDeposit
            maxDeposit
            securityDeposit
            isOpenToLease
            dateAvailable
            rentIsBasedOnIncome
            bedrooms
            fullBaths
            halfBaths
            totalBaths
            minSquareFeet
            maxSquareFeet
            isMobilityAccesible
            isVisionAccesible
            isHearingAccesible
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            photoUrls
            availableSeason
            enabled
            createdAt
            updatedAt
            images {
              nextToken
            }
            property {
              id
              appFolioPropertyID
              usesAppFolio
              managerID
              coverImageKey
              marketingName
              phoneNumber
              emailAddress
              description
              minRent
              maxRent
              type
              numberOfUnits
              photoUrls
              area
              enabled
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createFavorite = /* GraphQL */ `
  mutation CreateFavorite(
    $input: CreateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    createFavorite(input: $input, condition: $condition) {
      id
      itemID
      customerID
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateFavorite = /* GraphQL */ `
  mutation UpdateFavorite(
    $input: UpdateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    updateFavorite(input: $input, condition: $condition) {
      id
      itemID
      customerID
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteFavorite = /* GraphQL */ `
  mutation DeleteFavorite(
    $input: DeleteFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    deleteFavorite(input: $input, condition: $condition) {
      id
      itemID
      customerID
      type
      createdAt
      updatedAt
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      appFolioPropertyID
      usesAppFolio
      managerID
      coverImageKey
      marketingName
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      phoneNumber
      emailAddress
      description
      minRent
      maxRent
      type
      amenities {
        heatIncluded
        residentPaysHeat
        electricityIncluded
        residentPaysElectricity
        gasIncluded
        residentPaysGas
        waterIncluded
        residentPaysWater
        otherUtilities
        smallDogsAllowed
        catsAllowed
        noDogsAllowed
        noCatsAllowed
        noPetsAllowed
        otherPets
        monthToMonth
        twoMonths
        threeMonths
        fourMonths
        sixMonths
        nineMonths
        tenMonths
        twelveMonths
        twentyFourMonths
        otherLease
        dishwasher
        airConditioningWall
        airConditioning
        refrigerator
        range
        oven
        garbageDisposal
        washerAndDryer
        washerAndDryerHookup
        otherAppliances
        furnished
        unfurnished
        parking
        otherAmenities
        eightMonths
        pool
        exerciseFacility
        internetIncluded
        internetAvailable
        microwave
        mealProgramAvailable
        homeHealthServicesAvailable
        transportationServiceAvailable
        elevatorInBuilding
        rampedEntry
        accessibleParking
        wideDoorways
        wideHallways
        leverStyleDoorHandles
        environmentalControls
        grabBarsInBathroom
        rollInShower
        tTurnInBathroom
        accessibleBathroomVanities
        frontLoadingKitchenAppliances
        accesibleKitchenCounters
        clearFloorAreaInFrontOfSink
        otherAccessibility
        smokeFree
        rentToOwn
        basement
        residentPaysTrashPickUp
        trashPickUpIncluded
        garage
        yard
        patio
        balcony
        deck
        washerAndDryerOnSite
        largeDogsAllowed
        offStreetParking
        emergencyMaintenance
        entranceSecured
        virtualTours
        roommateMatchingServiceAvailable
        managerOnSite
        socialEvents
        bedroomsKeyedSeparately
        dogsAllowed
        cableTVIncluded
        graniteCountertops
        individualLeases
        patioBalconyDeck
        petDetails
        shortTermLeases
      }
      numberOfUnits
      photoUrls
      area
      enabled
      createdAt
      updatedAt
      manager {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      images {
        items {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        nextToken
      }
      units {
        items {
          id
          propertyID
          managerID
          usesAppFolio
          coverImageKey
          rent
          maxRent
          minDeposit
          maxDeposit
          securityDeposit
          isOpenToLease
          dateAvailable
          rentIsBasedOnIncome
          bedrooms
          fullBaths
          halfBaths
          totalBaths
          minSquareFeet
          maxSquareFeet
          isMobilityAccesible
          isVisionAccesible
          isHearingAccesible
          amenities {
            heatIncluded
            residentPaysHeat
            electricityIncluded
            residentPaysElectricity
            gasIncluded
            residentPaysGas
            waterIncluded
            residentPaysWater
            otherUtilities
            smallDogsAllowed
            catsAllowed
            noDogsAllowed
            noCatsAllowed
            noPetsAllowed
            otherPets
            monthToMonth
            twoMonths
            threeMonths
            fourMonths
            sixMonths
            nineMonths
            tenMonths
            twelveMonths
            twentyFourMonths
            otherLease
            dishwasher
            airConditioningWall
            airConditioning
            refrigerator
            range
            oven
            garbageDisposal
            washerAndDryer
            washerAndDryerHookup
            otherAppliances
            furnished
            unfurnished
            parking
            otherAmenities
            eightMonths
            pool
            exerciseFacility
            internetIncluded
            internetAvailable
            microwave
            mealProgramAvailable
            homeHealthServicesAvailable
            transportationServiceAvailable
            elevatorInBuilding
            rampedEntry
            accessibleParking
            wideDoorways
            wideHallways
            leverStyleDoorHandles
            environmentalControls
            grabBarsInBathroom
            rollInShower
            tTurnInBathroom
            accessibleBathroomVanities
            frontLoadingKitchenAppliances
            accesibleKitchenCounters
            clearFloorAreaInFrontOfSink
            otherAccessibility
            smokeFree
            rentToOwn
            basement
            residentPaysTrashPickUp
            trashPickUpIncluded
            garage
            yard
            patio
            balcony
            deck
            washerAndDryerOnSite
            largeDogsAllowed
            offStreetParking
            emergencyMaintenance
            entranceSecured
            virtualTours
            roommateMatchingServiceAvailable
            managerOnSite
            socialEvents
            bedroomsKeyedSeparately
            dogsAllowed
            cableTVIncluded
            graniteCountertops
            individualLeases
            patioBalconyDeck
            petDetails
            shortTermLeases
          }
          photoUrls
          availableSeason
          enabled
          createdAt
          updatedAt
          images {
            items {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            nextToken
          }
          property {
            id
            appFolioPropertyID
            usesAppFolio
            managerID
            coverImageKey
            marketingName
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            phoneNumber
            emailAddress
            description
            minRent
            maxRent
            type
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            numberOfUnits
            photoUrls
            area
            enabled
            createdAt
            updatedAt
            manager {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            images {
              nextToken
            }
            units {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      appFolioPropertyID
      usesAppFolio
      managerID
      coverImageKey
      marketingName
      address {
        label
        details
        street
        zip
        city
        state
        country
        location {
          lng
          lat
        }
        phone
      }
      phoneNumber
      emailAddress
      description
      minRent
      maxRent
      type
      amenities {
        heatIncluded
        residentPaysHeat
        electricityIncluded
        residentPaysElectricity
        gasIncluded
        residentPaysGas
        waterIncluded
        residentPaysWater
        otherUtilities
        smallDogsAllowed
        catsAllowed
        noDogsAllowed
        noCatsAllowed
        noPetsAllowed
        otherPets
        monthToMonth
        twoMonths
        threeMonths
        fourMonths
        sixMonths
        nineMonths
        tenMonths
        twelveMonths
        twentyFourMonths
        otherLease
        dishwasher
        airConditioningWall
        airConditioning
        refrigerator
        range
        oven
        garbageDisposal
        washerAndDryer
        washerAndDryerHookup
        otherAppliances
        furnished
        unfurnished
        parking
        otherAmenities
        eightMonths
        pool
        exerciseFacility
        internetIncluded
        internetAvailable
        microwave
        mealProgramAvailable
        homeHealthServicesAvailable
        transportationServiceAvailable
        elevatorInBuilding
        rampedEntry
        accessibleParking
        wideDoorways
        wideHallways
        leverStyleDoorHandles
        environmentalControls
        grabBarsInBathroom
        rollInShower
        tTurnInBathroom
        accessibleBathroomVanities
        frontLoadingKitchenAppliances
        accesibleKitchenCounters
        clearFloorAreaInFrontOfSink
        otherAccessibility
        smokeFree
        rentToOwn
        basement
        residentPaysTrashPickUp
        trashPickUpIncluded
        garage
        yard
        patio
        balcony
        deck
        washerAndDryerOnSite
        largeDogsAllowed
        offStreetParking
        emergencyMaintenance
        entranceSecured
        virtualTours
        roommateMatchingServiceAvailable
        managerOnSite
        socialEvents
        bedroomsKeyedSeparately
        dogsAllowed
        cableTVIncluded
        graniteCountertops
        individualLeases
        patioBalconyDeck
        petDetails
        shortTermLeases
      }
      numberOfUnits
      photoUrls
      area
      enabled
      createdAt
      updatedAt
      manager {
        id
        name
        email
        phone
        usesAppFolio
        imageID
        coverID
        saverAdID
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        deliveryFee {
          basketMinimum
          threshold
          extraFee
          fixedFee
        }
        serviceFee {
          threshold
          reducedFee
          fixedFee
        }
        deliveryWebsite
        sort
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        priority
        type
        createdAt
        updatedAt
        categories {
          items {
            id
            categoryID
            merchantID
            createdAt
            updatedAt
            category {
              id
              name
              description
              imageID
              enabled
              createdAt
              updatedAt
            }
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        menus {
          items {
            id
            name
            description
            imageID
            merchantID
            createdAt
            updatedAt
            products {
              nextToken
            }
            image {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        logo {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        cover {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        saverAd {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            createdAt
            updatedAt
            merchant {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            redemptions {
              nextToken
            }
          }
          nextToken
        }
      }
      images {
        items {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        nextToken
      }
      units {
        items {
          id
          propertyID
          managerID
          usesAppFolio
          coverImageKey
          rent
          maxRent
          minDeposit
          maxDeposit
          securityDeposit
          isOpenToLease
          dateAvailable
          rentIsBasedOnIncome
          bedrooms
          fullBaths
          halfBaths
          totalBaths
          minSquareFeet
          maxSquareFeet
          isMobilityAccesible
          isVisionAccesible
          isHearingAccesible
          amenities {
            heatIncluded
            residentPaysHeat
            electricityIncluded
            residentPaysElectricity
            gasIncluded
            residentPaysGas
            waterIncluded
            residentPaysWater
            otherUtilities
            smallDogsAllowed
            catsAllowed
            noDogsAllowed
            noCatsAllowed
            noPetsAllowed
            otherPets
            monthToMonth
            twoMonths
            threeMonths
            fourMonths
            sixMonths
            nineMonths
            tenMonths
            twelveMonths
            twentyFourMonths
            otherLease
            dishwasher
            airConditioningWall
            airConditioning
            refrigerator
            range
            oven
            garbageDisposal
            washerAndDryer
            washerAndDryerHookup
            otherAppliances
            furnished
            unfurnished
            parking
            otherAmenities
            eightMonths
            pool
            exerciseFacility
            internetIncluded
            internetAvailable
            microwave
            mealProgramAvailable
            homeHealthServicesAvailable
            transportationServiceAvailable
            elevatorInBuilding
            rampedEntry
            accessibleParking
            wideDoorways
            wideHallways
            leverStyleDoorHandles
            environmentalControls
            grabBarsInBathroom
            rollInShower
            tTurnInBathroom
            accessibleBathroomVanities
            frontLoadingKitchenAppliances
            accesibleKitchenCounters
            clearFloorAreaInFrontOfSink
            otherAccessibility
            smokeFree
            rentToOwn
            basement
            residentPaysTrashPickUp
            trashPickUpIncluded
            garage
            yard
            patio
            balcony
            deck
            washerAndDryerOnSite
            largeDogsAllowed
            offStreetParking
            emergencyMaintenance
            entranceSecured
            virtualTours
            roommateMatchingServiceAvailable
            managerOnSite
            socialEvents
            bedroomsKeyedSeparately
            dogsAllowed
            cableTVIncluded
            graniteCountertops
            individualLeases
            patioBalconyDeck
            petDetails
            shortTermLeases
          }
          photoUrls
          availableSeason
          enabled
          createdAt
          updatedAt
          images {
            items {
              id
              key
              name
              bucket
              region
              owner
              propertyID
              unitID
              createdAt
              updatedAt
            }
            nextToken
          }
          property {
            id
            appFolioPropertyID
            usesAppFolio
            managerID
            coverImageKey
            marketingName
            address {
              label
              details
              street
              zip
              city
              state
              country
              phone
            }
            phoneNumber
            emailAddress
            description
            minRent
            maxRent
            type
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            numberOfUnits
            photoUrls
            area
            enabled
            createdAt
            updatedAt
            manager {
              id
              name
              email
              phone
              usesAppFolio
              imageID
              coverID
              saverAdID
              enabled
              deliveryWebsite
              sort
              priority
              type
              createdAt
              updatedAt
            }
            images {
              nextToken
            }
            units {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      id
      propertyID
      managerID
      usesAppFolio
      coverImageKey
      rent
      maxRent
      minDeposit
      maxDeposit
      securityDeposit
      isOpenToLease
      dateAvailable
      rentIsBasedOnIncome
      bedrooms
      fullBaths
      halfBaths
      totalBaths
      minSquareFeet
      maxSquareFeet
      isMobilityAccesible
      isVisionAccesible
      isHearingAccesible
      amenities {
        heatIncluded
        residentPaysHeat
        electricityIncluded
        residentPaysElectricity
        gasIncluded
        residentPaysGas
        waterIncluded
        residentPaysWater
        otherUtilities
        smallDogsAllowed
        catsAllowed
        noDogsAllowed
        noCatsAllowed
        noPetsAllowed
        otherPets
        monthToMonth
        twoMonths
        threeMonths
        fourMonths
        sixMonths
        nineMonths
        tenMonths
        twelveMonths
        twentyFourMonths
        otherLease
        dishwasher
        airConditioningWall
        airConditioning
        refrigerator
        range
        oven
        garbageDisposal
        washerAndDryer
        washerAndDryerHookup
        otherAppliances
        furnished
        unfurnished
        parking
        otherAmenities
        eightMonths
        pool
        exerciseFacility
        internetIncluded
        internetAvailable
        microwave
        mealProgramAvailable
        homeHealthServicesAvailable
        transportationServiceAvailable
        elevatorInBuilding
        rampedEntry
        accessibleParking
        wideDoorways
        wideHallways
        leverStyleDoorHandles
        environmentalControls
        grabBarsInBathroom
        rollInShower
        tTurnInBathroom
        accessibleBathroomVanities
        frontLoadingKitchenAppliances
        accesibleKitchenCounters
        clearFloorAreaInFrontOfSink
        otherAccessibility
        smokeFree
        rentToOwn
        basement
        residentPaysTrashPickUp
        trashPickUpIncluded
        garage
        yard
        patio
        balcony
        deck
        washerAndDryerOnSite
        largeDogsAllowed
        offStreetParking
        emergencyMaintenance
        entranceSecured
        virtualTours
        roommateMatchingServiceAvailable
        managerOnSite
        socialEvents
        bedroomsKeyedSeparately
        dogsAllowed
        cableTVIncluded
        graniteCountertops
        individualLeases
        patioBalconyDeck
        petDetails
        shortTermLeases
      }
      photoUrls
      availableSeason
      enabled
      createdAt
      updatedAt
      images {
        items {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        nextToken
      }
      property {
        id
        appFolioPropertyID
        usesAppFolio
        managerID
        coverImageKey
        marketingName
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        phoneNumber
        emailAddress
        description
        minRent
        maxRent
        type
        amenities {
          heatIncluded
          residentPaysHeat
          electricityIncluded
          residentPaysElectricity
          gasIncluded
          residentPaysGas
          waterIncluded
          residentPaysWater
          otherUtilities
          smallDogsAllowed
          catsAllowed
          noDogsAllowed
          noCatsAllowed
          noPetsAllowed
          otherPets
          monthToMonth
          twoMonths
          threeMonths
          fourMonths
          sixMonths
          nineMonths
          tenMonths
          twelveMonths
          twentyFourMonths
          otherLease
          dishwasher
          airConditioningWall
          airConditioning
          refrigerator
          range
          oven
          garbageDisposal
          washerAndDryer
          washerAndDryerHookup
          otherAppliances
          furnished
          unfurnished
          parking
          otherAmenities
          eightMonths
          pool
          exerciseFacility
          internetIncluded
          internetAvailable
          microwave
          mealProgramAvailable
          homeHealthServicesAvailable
          transportationServiceAvailable
          elevatorInBuilding
          rampedEntry
          accessibleParking
          wideDoorways
          wideHallways
          leverStyleDoorHandles
          environmentalControls
          grabBarsInBathroom
          rollInShower
          tTurnInBathroom
          accessibleBathroomVanities
          frontLoadingKitchenAppliances
          accesibleKitchenCounters
          clearFloorAreaInFrontOfSink
          otherAccessibility
          smokeFree
          rentToOwn
          basement
          residentPaysTrashPickUp
          trashPickUpIncluded
          garage
          yard
          patio
          balcony
          deck
          washerAndDryerOnSite
          largeDogsAllowed
          offStreetParking
          emergencyMaintenance
          entranceSecured
          virtualTours
          roommateMatchingServiceAvailable
          managerOnSite
          socialEvents
          bedroomsKeyedSeparately
          dogsAllowed
          cableTVIncluded
          graniteCountertops
          individualLeases
          patioBalconyDeck
          petDetails
          shortTermLeases
        }
        numberOfUnits
        photoUrls
        area
        enabled
        createdAt
        updatedAt
        manager {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        images {
          items {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          nextToken
        }
        units {
          items {
            id
            propertyID
            managerID
            usesAppFolio
            coverImageKey
            rent
            maxRent
            minDeposit
            maxDeposit
            securityDeposit
            isOpenToLease
            dateAvailable
            rentIsBasedOnIncome
            bedrooms
            fullBaths
            halfBaths
            totalBaths
            minSquareFeet
            maxSquareFeet
            isMobilityAccesible
            isVisionAccesible
            isHearingAccesible
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            photoUrls
            availableSeason
            enabled
            createdAt
            updatedAt
            images {
              nextToken
            }
            property {
              id
              appFolioPropertyID
              usesAppFolio
              managerID
              coverImageKey
              marketingName
              phoneNumber
              emailAddress
              description
              minRent
              maxRent
              type
              numberOfUnits
              photoUrls
              area
              enabled
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      id
      propertyID
      managerID
      usesAppFolio
      coverImageKey
      rent
      maxRent
      minDeposit
      maxDeposit
      securityDeposit
      isOpenToLease
      dateAvailable
      rentIsBasedOnIncome
      bedrooms
      fullBaths
      halfBaths
      totalBaths
      minSquareFeet
      maxSquareFeet
      isMobilityAccesible
      isVisionAccesible
      isHearingAccesible
      amenities {
        heatIncluded
        residentPaysHeat
        electricityIncluded
        residentPaysElectricity
        gasIncluded
        residentPaysGas
        waterIncluded
        residentPaysWater
        otherUtilities
        smallDogsAllowed
        catsAllowed
        noDogsAllowed
        noCatsAllowed
        noPetsAllowed
        otherPets
        monthToMonth
        twoMonths
        threeMonths
        fourMonths
        sixMonths
        nineMonths
        tenMonths
        twelveMonths
        twentyFourMonths
        otherLease
        dishwasher
        airConditioningWall
        airConditioning
        refrigerator
        range
        oven
        garbageDisposal
        washerAndDryer
        washerAndDryerHookup
        otherAppliances
        furnished
        unfurnished
        parking
        otherAmenities
        eightMonths
        pool
        exerciseFacility
        internetIncluded
        internetAvailable
        microwave
        mealProgramAvailable
        homeHealthServicesAvailable
        transportationServiceAvailable
        elevatorInBuilding
        rampedEntry
        accessibleParking
        wideDoorways
        wideHallways
        leverStyleDoorHandles
        environmentalControls
        grabBarsInBathroom
        rollInShower
        tTurnInBathroom
        accessibleBathroomVanities
        frontLoadingKitchenAppliances
        accesibleKitchenCounters
        clearFloorAreaInFrontOfSink
        otherAccessibility
        smokeFree
        rentToOwn
        basement
        residentPaysTrashPickUp
        trashPickUpIncluded
        garage
        yard
        patio
        balcony
        deck
        washerAndDryerOnSite
        largeDogsAllowed
        offStreetParking
        emergencyMaintenance
        entranceSecured
        virtualTours
        roommateMatchingServiceAvailable
        managerOnSite
        socialEvents
        bedroomsKeyedSeparately
        dogsAllowed
        cableTVIncluded
        graniteCountertops
        individualLeases
        patioBalconyDeck
        petDetails
        shortTermLeases
      }
      photoUrls
      availableSeason
      enabled
      createdAt
      updatedAt
      images {
        items {
          id
          key
          name
          bucket
          region
          owner
          propertyID
          unitID
          createdAt
          updatedAt
        }
        nextToken
      }
      property {
        id
        appFolioPropertyID
        usesAppFolio
        managerID
        coverImageKey
        marketingName
        address {
          label
          details
          street
          zip
          city
          state
          country
          location {
            lng
            lat
          }
          phone
        }
        phoneNumber
        emailAddress
        description
        minRent
        maxRent
        type
        amenities {
          heatIncluded
          residentPaysHeat
          electricityIncluded
          residentPaysElectricity
          gasIncluded
          residentPaysGas
          waterIncluded
          residentPaysWater
          otherUtilities
          smallDogsAllowed
          catsAllowed
          noDogsAllowed
          noCatsAllowed
          noPetsAllowed
          otherPets
          monthToMonth
          twoMonths
          threeMonths
          fourMonths
          sixMonths
          nineMonths
          tenMonths
          twelveMonths
          twentyFourMonths
          otherLease
          dishwasher
          airConditioningWall
          airConditioning
          refrigerator
          range
          oven
          garbageDisposal
          washerAndDryer
          washerAndDryerHookup
          otherAppliances
          furnished
          unfurnished
          parking
          otherAmenities
          eightMonths
          pool
          exerciseFacility
          internetIncluded
          internetAvailable
          microwave
          mealProgramAvailable
          homeHealthServicesAvailable
          transportationServiceAvailable
          elevatorInBuilding
          rampedEntry
          accessibleParking
          wideDoorways
          wideHallways
          leverStyleDoorHandles
          environmentalControls
          grabBarsInBathroom
          rollInShower
          tTurnInBathroom
          accessibleBathroomVanities
          frontLoadingKitchenAppliances
          accesibleKitchenCounters
          clearFloorAreaInFrontOfSink
          otherAccessibility
          smokeFree
          rentToOwn
          basement
          residentPaysTrashPickUp
          trashPickUpIncluded
          garage
          yard
          patio
          balcony
          deck
          washerAndDryerOnSite
          largeDogsAllowed
          offStreetParking
          emergencyMaintenance
          entranceSecured
          virtualTours
          roommateMatchingServiceAvailable
          managerOnSite
          socialEvents
          bedroomsKeyedSeparately
          dogsAllowed
          cableTVIncluded
          graniteCountertops
          individualLeases
          patioBalconyDeck
          petDetails
          shortTermLeases
        }
        numberOfUnits
        photoUrls
        area
        enabled
        createdAt
        updatedAt
        manager {
          id
          name
          email
          phone
          usesAppFolio
          imageID
          coverID
          saverAdID
          address {
            label
            details
            street
            zip
            city
            state
            country
            location {
              lng
              lat
            }
            phone
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          deliveryFee {
            basketMinimum
            threshold
            extraFee
            fixedFee
          }
          serviceFee {
            threshold
            reducedFee
            fixedFee
          }
          deliveryWebsite
          sort
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          priority
          type
          createdAt
          updatedAt
          categories {
            items {
              id
              categoryID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          menus {
            items {
              id
              name
              description
              imageID
              merchantID
              createdAt
              updatedAt
            }
            nextToken
          }
          logo {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          cover {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          saverAd {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        images {
          items {
            id
            key
            name
            bucket
            region
            owner
            propertyID
            unitID
            createdAt
            updatedAt
          }
          nextToken
        }
        units {
          items {
            id
            propertyID
            managerID
            usesAppFolio
            coverImageKey
            rent
            maxRent
            minDeposit
            maxDeposit
            securityDeposit
            isOpenToLease
            dateAvailable
            rentIsBasedOnIncome
            bedrooms
            fullBaths
            halfBaths
            totalBaths
            minSquareFeet
            maxSquareFeet
            isMobilityAccesible
            isVisionAccesible
            isHearingAccesible
            amenities {
              heatIncluded
              residentPaysHeat
              electricityIncluded
              residentPaysElectricity
              gasIncluded
              residentPaysGas
              waterIncluded
              residentPaysWater
              otherUtilities
              smallDogsAllowed
              catsAllowed
              noDogsAllowed
              noCatsAllowed
              noPetsAllowed
              otherPets
              monthToMonth
              twoMonths
              threeMonths
              fourMonths
              sixMonths
              nineMonths
              tenMonths
              twelveMonths
              twentyFourMonths
              otherLease
              dishwasher
              airConditioningWall
              airConditioning
              refrigerator
              range
              oven
              garbageDisposal
              washerAndDryer
              washerAndDryerHookup
              otherAppliances
              furnished
              unfurnished
              parking
              otherAmenities
              eightMonths
              pool
              exerciseFacility
              internetIncluded
              internetAvailable
              microwave
              mealProgramAvailable
              homeHealthServicesAvailable
              transportationServiceAvailable
              elevatorInBuilding
              rampedEntry
              accessibleParking
              wideDoorways
              wideHallways
              leverStyleDoorHandles
              environmentalControls
              grabBarsInBathroom
              rollInShower
              tTurnInBathroom
              accessibleBathroomVanities
              frontLoadingKitchenAppliances
              accesibleKitchenCounters
              clearFloorAreaInFrontOfSink
              otherAccessibility
              smokeFree
              rentToOwn
              basement
              residentPaysTrashPickUp
              trashPickUpIncluded
              garage
              yard
              patio
              balcony
              deck
              washerAndDryerOnSite
              largeDogsAllowed
              offStreetParking
              emergencyMaintenance
              entranceSecured
              virtualTours
              roommateMatchingServiceAvailable
              managerOnSite
              socialEvents
              bedroomsKeyedSeparately
              dogsAllowed
              cableTVIncluded
              graniteCountertops
              individualLeases
              patioBalconyDeck
              petDetails
              shortTermLeases
            }
            photoUrls
            availableSeason
            enabled
            createdAt
            updatedAt
            images {
              nextToken
            }
            property {
              id
              appFolioPropertyID
              usesAppFolio
              managerID
              coverImageKey
              marketingName
              phoneNumber
              emailAddress
              description
              minRent
              maxRent
              type
              numberOfUnits
              photoUrls
              area
              enabled
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;