import PropTypes from 'prop-types';

import styles from '../styles/Backdrop.module.css';

const Backdrop = ({ clicked }) => {
  return (
    <div
      className={styles.backdrop}
      onClick={clicked}
      role="button"
      tabIndex={0}
      onKeyPress={clicked}
      aria-label="backdrop"
    />
  );
};

Backdrop.propTypes = {
  clicked: PropTypes.func.isRequired,
};

export default Backdrop;
