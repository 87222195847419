import PropTypes from 'prop-types';
import styles from '../../styles/Auth.module.css';

export default function ConfirmReset({
  changeAction,
  resetAction,
  username,
  passwordError,
}) {
  return (
    <div className={styles.signUpform} style={{ width: '330px' }}>
      <input
        name="username"
        onChange={changeAction}
        placeholder="Email address"
        className={styles.formInput}
        value={username}
        readOnly
      />
      <input
        name="authCode"
        onChange={changeAction}
        placeholder="Confirmation code"
        className={styles.formInput}
      />
      <div>
        <input
          name="password"
          type="password"
          onChange={changeAction}
          placeholder="New password"
          className={styles.formInput}
        />
        {passwordError && (
          <span className={styles.errorMessage}>
            Entered password doesn’t match our password policy
          </span>
        )}
        <span
          style={{
            display: 'block',
            fontSize: '10px',
            color: 'var(--saver-darkBtn)',
            textAlign: 'left',
          }}
        >
          * Password must be longer than 6 characters, include at least a
          number, special character, uppercase letter and a lowercase letters
        </span>
      </div>
      <button
        type="button"
        onClick={resetAction}
        className="mainButton"
        style={{ marginTop: '1.5rem' }}
      >
        Confirm
      </button>
    </div>
  );
}

ConfirmReset.propTypes = {
  changeAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  username: PropTypes.func.isRequired,
  passwordError: PropTypes.bool,
};

ConfirmReset.defaultProps = {
  passwordError: false,
};
