import actionTypes from '../constants/actionTypes';
import {
  addItemToFavorites,
  getFavorites,
  removeItemFromFavorites,
} from '../../api/favoritesService';
import errorHandler from '../../utils/errorHandler';

export function getFavoritesAction(userID) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.GET_FAVORITES_START));
    try {
      const favorites = await getFavorites(userID);
      dispatch(actionReducer(favorites, actionTypes.GET_FAVORITES_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.GET_FAVORITES_FAIL));
    }
  };
}

export function addItemToFavoritesAction(itemID, customerID, type) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.ADD_TO_FAVORITES_START));
    try {
      const favorite = await addItemToFavorites(itemID, customerID, type);
      dispatch(actionReducer(favorite, actionTypes.ADD_TO_FAVORITES_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.ADD_TO_FAVORITES_FAIL));
    }
  };
}

export function removeItemFromFavoritesAction(favoriteID, customerID) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.REMOVE_FROM_FAVORITES_START));
    try {
      await removeItemFromFavorites(favoriteID);
      dispatch(actionReducer(null, actionTypes.REMOVE_FROM_FAVORITES_SUCCESS));
      // dispatch(getFavoritesAction(customerID));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.REMOVE_FROM_FAVORITES_FAIL));
    }
  };
}

const actionReducer = (payload, type) => ({
  type,
  payload,
});
