/* eslint-disable class-methods-use-this */
import moment from 'moment';
import { listCoupons } from '../graphql/queries';
import requestAll from '../utils/requestAll';

export default class CouponService {
  async getCoupons() {
    const today = moment().format('YYYY-MM-DD');
    const coupons = await requestAll(listCoupons, 'listCoupons', {
      filter: {
        enabled: { eq: true },
        expirationDate: { ge: new Date(today) },
      },
    });

    return coupons;
  }
}
