/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import validator from 'validator';
import Swal from 'sweetalert2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ResetForm from '../components/auth/ResetPassword';
import SignInForm from '../components/auth/SignIn';
import SignUpForm from '../components/auth/SignUp';
import styles from '../styles/Auth.module.css';
import AuthHeader from '../components/auth/AuthHeader';
import AuthDisclaimer from '../components/auth/AuthDisclaimer';
import ConfirmSignUp from '../components/auth/ConfirmSignUp';
import ConfirmReset from '../components/auth/ConfirmReset';
import useQuery from '../hooks/useQuery';
import {
  signInUserAction,
  signUpUserAction,
} from '../redux/actions/authActions';
import 'react-phone-number-input/style.css';
import useTitle from '../hooks/useTitle';

export default function SaverAuth() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
  });
  const query = useQuery();
  const formType = query.get('formType');
  const prev = query.get('prev');
  const history = useHistory();
  const { loading, signUpVerificationUser } = useSelector(
    (state) => state.auth
  );

  const initialForm = {
    username: '',
    password: '',
    authCode: '',
    formType: formType || 'signIn',
  };

  const [formState, updateFormState] = useState(initialForm);
  const dispatch = useDispatch();

  useTitle();

  const handleChange = (e) => {
    if (
      e.target.name === 'phone_number' &&
      ((e.target.value.length === 1 && e.target.value !== '+') ||
        e.target.value.indexOf('+') === -1)
    ) {
      e.target.value = `+1${e.target.value}`;
    } else if (e.target.name !== 'phone_number') {
      e.persist();
    }
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (!signUpVerificationUser) return;
    updateFormState((state) => ({
      ...state,
      formType: 'confirmSignUp',
    }));
  }, [signUpVerificationUser]);

  useEffect(() => {
    const setAuthListener = async () => {
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn':
            if (prev) {
              history.push(decodeURIComponent(prev));
            } else {
              history.push('/');
            }
            break;
          case 'cognitoHostedUI':
            break;
          case 'cognitoHostedUI_failure':
            break;
          default:
            break;
        }
      });
    };
    setAuthListener();
  }, [formState, history, prev]);

  const onFederatedSignIn = async (socialMedia) => {
    if (!socialMedia) {
      return;
    }
    switch (socialMedia) {
      case 'Facebook':
        await Auth.federatedSignIn({ provider: 'Facebook' });
        break;
      case 'Apple':
        await Auth.federatedSignIn({ provider: 'SignInWithApple' });
        break;
      case 'Google':
        await Auth.federatedSignIn({ provider: 'Google' });
        break;
      default:
        break;
    }
  };

  const signInFn = (overrideUsername) => {
    const { username, password } = formState;
    dispatch(
      signInUserAction(
        typeof overrideUsername === 'string' ? overrideUsername : username,
        password
      )
    );
  };

  const [signUpFormValid, setsignUpFormValid] = useState({
    emailError: false,
    numberTakenError: false,
    passwordError: false,
    numberInvalidError: false,
    passwordConfirmationError: false,
  });
  const [passwordResetError, setPasswordResetError] = useState(false);

  const passwordVaildation = () => {
    const pattern = new RegExp(
      '(?=^.{8,}$)(?=.*)(?=.*[!@#$%^&*]+)(?![.n])(?=.*[A-Z])(?=.*[a-z]).*$'
    );
    return pattern.test(formState.password);
  };

  const signUpFn = async () => {
    const formValid = {
      emailTakenError: false,
      emailError: false,
      numberTakenError: false,
      passwordError: false,
      numberInvalidError: false,
      passwordConfirmationError: false,
    };

    if (
      !formState.phone_number ||
      !formState.password ||
      !formState.confirmPassword ||
      !formState.family_name ||
      !formState.name ||
      !formState.username
    ) {
      return Swal.fire({
        icon: 'error',
        text: 'You need to fill out the entire form before clicking the Sign Up button',
      });
    }
    const res = await fetch(
      `${process.env.REACT_APP_PHOENIX_API_URL}/user/auth/exists`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formState.username,
          phoneNumber: formState.phone_number,
        }),
      }
    );
    const { emailTaken, phoneTaken } = await res.json();
    const isValidEmail = validator.isEmail(formState.username);
    const isValidPhoneNumber1 = isValidPhoneNumber(formState.phone_number);

    if (!passwordVaildation()) {
      formValid.passwordError = true;
      setsignUpFormValid(formValid);
      return;
    }

    if (formState.password !== formState.confirmPassword) {
      formValid.passwordConfirmationError = true;
      setsignUpFormValid(formValid);
      return;
    }

    if (!isValidPhoneNumber1) {
      formValid.numberInvalidError = true;
      setsignUpFormValid(formValid);
      return;
    }
    if (!isValidEmail) {
      formValid.emailError = true;
      setsignUpFormValid(formValid);
      return;
    }

    if (emailTaken) {
      formValid.emailTakenError = true;
      setsignUpFormValid(formValid);
      return;
    }

    if (phoneTaken) {
      formValid.numberTakenError = true;
      setsignUpFormValid(formValid);
      return;
    }

    setsignUpFormValid(formValid);

    try {
      const { username, password, phone_number, family_name, name } = formState;
      dispatch(
        signUpUserAction({
          username,
          password,
          attributes: {
            email: username,
            phone_number,
            family_name,
            name,
          },
        })
      );
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: error.message.toString(),
      });
    }
  };

  const confirmSignUp = async () => {
    const { authCode } = formState;

    try {
      await Auth.confirmSignUp(
        signUpVerificationUser?.user?.username,
        authCode
      );
      signInFn();
    } catch (error) {
      console.error(error);
      Toast.fire({
        icon: 'error',
        title: 'Invalid confirmation code',
      });
    }
  };

  const forgotPswd = async () => {
    const { username } = formState;

    try {
      await Auth.forgotPassword(username);
      Swal.fire({
        icon: 'info',
        text: "An authentication code has been sent to your e-mail. If you don't see the e-mail in your inbox then please check your spam folder.",
      });
      updateFormState(() => ({
        ...formState,
        formType: 'forgotPasswordSubmit',
      }));
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: error.message.toString(),
      });
    }
  };

  const forgotPswdSubmit = async () => {
    const { username, authCode, password } = formState;
    setPasswordResetError(false);
    if (!passwordVaildation()) {
      setPasswordResetError(true);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(username, authCode, password);
      updateFormState(() => ({ ...formState, formType: 'signIn' }));
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: error.message.toString(),
      });
    }
  };

  const navigatetoReset = () => {
    updateFormState(() => ({
      ...formState,
      formType: 'forgotPassword',
    }));
  };

  const navigateToSignIn = () => {
    updateFormState(() => ({ ...formState, formType: 'signIn' }));
  };

  const navigateToSignUp = () => {
    updateFormState(() => ({ ...formState, formType: 'signUp' }));
  };

  const { formType: actualForm } = formState;
  return (
    <>
      <div className={styles.container}>
        <AuthHeader />
        {actualForm === 'signIn' && (
          <>
            <SignInForm
              changeAction={handleChange}
              resetAction={navigatetoReset}
              signInAction={signInFn}
              createAccountAction={navigateToSignUp}
              socialMedia={onFederatedSignIn}
              loading={loading}
              formState={formState}
            />
            <AuthDisclaimer text="By using our site" />
          </>
        )}
        {actualForm === 'signUp' && (
          <>
            <SignUpForm
              backToSignIn={navigateToSignIn}
              signUpAction={signUpFn}
              changeAction={handleChange}
              socialMedia={onFederatedSignIn}
              inputErrors={signUpFormValid}
            />
            <AuthDisclaimer text="By using our site" />
          </>
        )}
        {actualForm === 'confirmSignUp' && (
          <ConfirmSignUp
            phone={formState.phone_number}
            email={formState.username}
            confirmAction={confirmSignUp}
            changeAction={handleChange}
          />
        )}
        {actualForm === 'forgotPassword' && (
          <ResetForm resetAction={forgotPswd} changeAction={handleChange} />
        )}
        {actualForm === 'forgotPasswordSubmit' && (
          <ConfirmReset
            passwordError={passwordResetError}
            username={formState.username}
            resetAction={forgotPswdSubmit}
            changeAction={handleChange}
          />
        )}
      </div>
    </>
  );
}
