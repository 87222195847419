/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { createCustomer, updateCustomer } from '../graphql/mutations';
import { getCustomer } from '../graphql/queries';

export default class AuthService {
  async getProfile(cognitoUser) {
    let profile = '';
    const user = await API.graphql(
      graphqlOperation(getCustomer, {
        id: cognitoUser.attributes?.sub,
      })
    );

    if (user.data.getCustomer) {
      profile = user.data.getCustomer;
    } else {
      const newUser = await API.graphql(
        graphqlOperation(createCustomer, {
          input: {
            id: cognitoUser.attributes.sub || '',
            firstName: cognitoUser.attributes.name || ' ',
            lastName: cognitoUser.attributes.family_name || ' ',
            email: cognitoUser.attributes.email.trim(),
            phone: cognitoUser.attributes.phone_number,
            viaEmail: true,
            viaSMS: true,
          },
        })
      );
      profile = newUser.data.createCustomer;
    }

    if (profile.phone !== cognitoUser.attributes.phone_number) {
      await API.graphql(
        graphqlOperation(updateCustomer, {
          input: {
            id: cognitoUser.attributes.sub || '',
            phone: cognitoUser.attributes.phone_number,
          },
        })
      );
    }

    profile.phone = cognitoUser.attributes.phone_number;

    return profile;
  }

  async getSession() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const profile = await this.getProfile(cognitoUser);
    return profile;
  }

  async signIn(username, password) {
    const cognitoUser = await Auth.signIn(username, password);
    const profile = await this.getProfile(cognitoUser);
    return profile;
  }

  async signUp({
    username,
    password,
    attributes: { email, phone_number, family_name, name },
  }) {
    const cognitoUser = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        phone_number,
        family_name,
        name,
      },
    });
    return cognitoUser;
  }

  async signOut() {
    await Auth.signOut();
    return true;
  }
}
