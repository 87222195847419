import { Popover } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMerchantPropertiesAction } from '../redux/actions/propertyActions';
import styles from '../styles/MerchantListing.module.css';
import {
  formatPhoneNumber,
  getTodaySchedule,
  isOpen,
  week,
} from '../utils/generalFunctions';
import { closed, open } from '../utils/merchantConf';
import FavoriteItem from './FavoriteItem';
import Clickable from './Clickable';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { encodeCategoryName } from '../utils/categoryNameEcoder';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';

export default function MerchantCard({
  name,
  logo,
  schedule,
  phone,
  address,
  deliveryWebsite,
  category,
  merchantId,
  coupons,
  type,
}) {
  const [anchorPoint, setAnchorPoint] = useState(null);
  const popOpen = anchorPoint;
  const popID = popOpen ? 'simple-popover' : undefined;
  const orderedSchedule = schedule;
  const merchant = name;
  const dispatch = useDispatch();
  const history = useHistory();
  const { items } = useSelector((state) => state.properties);

  if (orderedSchedule) {
    orderedSchedule.sort(function sortByDay(a, b) {
      const day1 = a.day.toLowerCase();
      const day2 = b.day.toLowerCase();
      return week[day1] - week[day2];
    });
  }

  const handleClose = () => {
    setAnchorPoint(null);
  };

  const showHours = (event, hours) => {
    if (hours) {
      setAnchorPoint(event.currentTarget);
    }
  };

  const hasActiveSpecial = useMemo(() => {
    return coupons.items
      .filter((item) => new Date(item.expirationDate) >= new Date())
      .some((item) => item.enabled && item.isSpecial);
  }, [coupons.items]);
  const hasActiveCoupons = useMemo(() => {
    return coupons.items
      .filter((item) => item.enabled && !item.isSpecial)
      .some((item) => new Date(item.expirationDate) >= new Date());
  }, [coupons.items]);

  const goToProperties = () => {
    logAnalyticsEvent({
      label: `${name} Properties`,
      action: ACTIONS.CLICK,
      category: CATEGORIES.BUSINESS_LISTING,
    });

    dispatch(selectMerchantPropertiesAction(merchantId));
    history.push('/apartments/');
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.cardImage}>
          <Clickable
            onClick={() =>
              logAnalyticsEvent({
                label: name,
                action: ACTIONS.CLICK,
                category: CATEGORIES.BUSINESS_LISTING,
              })
            }
          >
            <Link
              to={`/categories/${encodeCategoryName(category)}/${merchant}`}
            >
              <img src={logo} alt="" width="100%" />
            </Link>
          </Clickable>
        </div>
        <div className={styles.cardTitle}>
          <div className={styles.topBar}>
            <Clickable
              onClick={() =>
                logAnalyticsEvent({
                  label: name,
                  action: ACTIONS.CLICK,
                  category: CATEGORIES.BUSINESS_LISTING,
                })
              }
            >
              <Link
                to={`/categories/${encodeCategoryName(category)}/${merchant}`}
              >
                <p className={styles.title}>{name}</p>
              </Link>
            </Clickable>

            <Clickable
              onClick={() =>
                logAnalyticsEvent({
                  label: name,
                  action: ACTIONS.FAVORITE,
                  category: CATEGORIES.BUSINESS_LISTING,
                })
              }
            >
              <FavoriteItem id={merchantId} type="MERCHANT" />
            </Clickable>
          </div>
          <div className={styles.subtitles}>
            <span>{isOpen(schedule) ? open : closed}</span>
            <button
              type="button"
              className={styles.schedule}
              onClick={(event) => showHours(event, schedule)}
            >
              {getTodaySchedule(schedule)}{' '}
              <img alt="" src="/icons/arrowDown.svg" />
            </button>
            <Popover
              id={popID}
              open={popOpen}
              anchorEl={anchorPoint}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={styles.dayListing}>
                {orderedSchedule
                  ? orderedSchedule.map((h) => {
                      const format = 'h:mm a';
                      const sameDay = moment().format('dddd');
                      const from = moment(h.openAt, 'H:mm').format(format);
                      const to = moment(h.openAt, 'H:mm')
                        .add(h.workingHours, 'h')
                        .format(format);
                      return (
                        <div key={h.day} className={styles.dayDetail}>
                          <div className={styles.dayListed}>{h.day}</div>
                          <div>
                            {h.closed ? (
                              <span className={styles.isClosed}>{h.label}</span>
                            ) : (
                              <span
                                style={{
                                  fontWeight: h.day === sameDay ? 600 : '',
                                }}
                              >{`${from} to ${to}`}</span>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : 'Nothing to display'}
              </div>
            </Popover>
            <span className={styles.phone}>
              <img src="/icons/phone.svg" alt="" className={styles.iconLeft} />
              {formatPhoneNumber(phone)}
            </span>
          </div>
        </div>
      </div>

      <div
        className={
          (items && items.length > 0) ||
          hasActiveSpecial ||
          hasActiveCoupons ||
          deliveryWebsite
            ? styles.location
            : styles.noLocation
        }
      >
        <a
          href={`https://maps.google.com/maps?z=18&t=m&q=${
            address ? address[0].details : '#'
          }`}
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            logAnalyticsEvent({
              label: `https://maps.google.com/maps?z=18&t=m&q=${
                address ? address[0].details : '#'
              }`,
              action: ACTIONS.MAP_VIEW_EXTERNAL,
              category: CATEGORIES.BUSINESS_LISTING,
            })
          }
        >
          <img src="/icons/location.svg" alt="" className={styles.iconLeft} />
          {address ? `${address[0].street}` : '-'}
        </a>
      </div>
      <div
        style={{
          borderTop:
            coupons.items.length === 0 &&
            deliveryWebsite &&
            type === 'APARTMENT'
              ? ''
              : '1px solid var(--saver-separator)',
          marginTop: '0.1rem',
        }}
      >
        <div className={styles.actions}>
          {type === 'APARTMENT' && items && items.length > 0 && (
            <Clickable className={styles.singleButton}>
              <button
                onClick={goToProperties}
                type="button"
                className={styles.darkAptButton}
              >
                Our Properties
              </button>
            </Clickable>
          )}
          {hasActiveCoupons && (
            <Clickable className={styles.singleButtonRight}>
              <button
                onClick={() =>
                  logAnalyticsEvent({
                    label: `${name} (Coupons)`,
                    action: ACTIONS.CLICK,
                    category: CATEGORIES.BUSINESS_LISTING,
                  })
                }
                type="button"
                className={styles.couponsButton}
              >
                <Link
                  to={`/categories/${encodeCategoryName(
                    process.env.REACT_APP_DEFAULT_CATEGORY
                  )}/${name}?selectedTab=coupons`}
                >
                  Coupons
                </Link>
              </button>
            </Clickable>
          )}
          {hasActiveSpecial && !hasActiveCoupons && (
            <Clickable className={styles.singleButtonRight}>
              <button
                onClick={() =>
                  logAnalyticsEvent({
                    event: 'MerchantCard',
                    label: `${name} (Specials)`,
                    action: ACTIONS.CLICK,
                    category: CATEGORIES.BUSINESS_LISTING,
                  })
                }
                type="button"
                className={styles.specialsButton}
              >
                <Link
                  to={`/categories/${encodeCategoryName(
                    process.env.REACT_APP_DEFAULT_CATEGORY
                  )}/${name}?selectedTab=specials`}
                >
                  Specials
                </Link>
              </button>
            </Clickable>
          )}
          {deliveryWebsite && (
            <Clickable className={styles.singleButton}>
              <button
                onClick={() =>
                  logAnalyticsEvent({
                    event: 'MerchantCard',
                    label: `${name} (Order Now)`,
                    action: ACTIONS.CLICK,
                    category: CATEGORIES.BUSINESS_LISTING,
                  })
                }
                type="button"
                className={styles.darkButton}
              >
                <a href={deliveryWebsite} target="_blank" rel="noreferrer">
                  Order Now
                </a>
              </button>
            </Clickable>
          )}
        </div>
      </div>
    </div>
  );
}

MerchantCard.propTypes = {
  merchantId: PropTypes.string.isRequired,
  coupons: PropTypes.arrayOf({
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    nextToken: PropTypes.string,
  }).isRequired,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  deliveryWebsite: PropTypes.string,
  schedule: PropTypes.arrayOf(
    PropTypes.shape({
      closed: PropTypes.bool,
      day: PropTypes.string,
      label: PropTypes.string,
      openAt: PropTypes.string,
      workingHours: PropTypes.number,
    })
  ),
  phone: PropTypes.string.isRequired,
  address: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      details: PropTypes.string,
      label: PropTypes.string,
      phone: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string,
      location: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }).isRequired,
    }).isRequired
  ),
  type: PropTypes.string.isRequired,
};

MerchantCard.defaultProps = {
  address: {},
  schedule: {},
  deliveryWebsite: null,
};
