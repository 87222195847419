import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router';
import GoogleMapReact from 'google-map-react';
import { Breadcrumbs, CircularProgress, Modal } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import { useSelector } from 'react-redux';
import { isEmpty, pickBy } from 'ramda';
import Carousel from 'react-material-ui-carousel';
import SaverMarker from '../components/SaverMarker';
import {
  activeIndicator,
  categoryNext,
  categoryPrev,
  indicatorProps,
  navButtons,
} from '../utils/carouselConf';
import { amenitiesKeys } from '../utils/object';
import styles from '../styles/Apartments.module.css';
import getImageURL from '../utils/getImageURL';
import ApartmentFilters from '../components/ApartmentFilters';
import FavoriteItem from '../components/FavoriteItem';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import Clickable from '../components/Clickable';
import { encodeCategoryName } from '../utils/categoryNameEcoder';
import ShareModule from '../components/organisms/ShareModule';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import useTitle from '../hooks/useTitle';

const getEmailText = (managerEmail, address, marketingName) => {
  const parsedAddress = address
    ? encodeURIComponent(address.street)
    : encodeURIComponent(marketingName);
  const body = `
    Hi, I found your property located at ${parsedAddress} on The Saver App. I would appreciate if you could e-mail me addition information regarding the property. I am also wondering when someone would be available to show me the unit? Thanks for your time, I Look forward to hearing back from you.
  `;
  return `mailto:${managerEmail}?subject=Your Property I found on The Saver App&body=${body}`;
};

const ApartmentDetails = () => {
  const { id } = useParams();
  const propertiesById = useSelector((state) => state.properties.indexById);
  const [property, setProperty] = useState(null);
  const [amenities, setAmenities] = useState([]);
  const [openFloorModal, setOpenFloorModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const category = process.env.REACT_APP_DEFAULT_CATEGORY;
  const iOSAppLink = 'https://apps.apple.com/us/app/the-saver/id1528921619';
  const androidAppLink =
    'https://play.google.com/store/apps/details?id=com.saverapp&hl=en&gl=US';
  const propertyLink = `${window.location.origin}/apartments/results/${id}`;
  const shareContent = `Check out this place found on The Saver's property searching app: ${propertyLink} |
  Need the app, download it here:
  ${'\n'}
  App Store: ${iOSAppLink}
  ${'\n'}
  Play Store: ${androidAppLink}`;

  const handleClose = () => {
    setOpenFloorModal(false);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
  };

  const handleOpenFloorModal = (floor) => {
    setModalContent(floor);
    setOpenFloorModal(true);
  };

  useTitle(
    property && property.manager
      ? `${property.manager.name} | ${property.marketingName}`
      : undefined
  );

  useEffect(() => {
    if (property) {
      const isTrue = (val) => val === true;
      const picked = pickBy(isTrue, property.amenities);
      const selectedAmmenities = Object.keys(picked).map(
        (key) => amenitiesKeys[key]
      );
      setAmenities(selectedAmmenities);
    }
  }, [property]);

  const shareUrl = `${window.location.origin}/apartments/results/${id}`;

  const shareWithFriend = (text) => {
    if (navigator.share) {
      navigator.share({
        title: 'The Saver',
        text,
        url: shareUrl,
      });
    } else {
      setOpenShareModal(true);
      try {
        navigator.clipboard.writeText(shareUrl);
      } catch (err) {
        alert(`Could not copy to clipboard. Error : ${err}`);
      }
    }
    logAnalyticsEvent({
      label: `${property.manager.name} → Share`,
      action: ACTIONS.CLICK,
      category: CATEGORIES.PROPERTY,
    });
  };

  useEffect(() => {
    if (propertiesById) {
      setProperty(propertiesById[id]);
    }
  }, [id, propertiesById]);

  const handleGAEventClick = (type, name) => {
    logAnalyticsEvent({
      label: `${name} → ${type}`,
      action: ACTIONS.CLICK,
      category: CATEGORIES.PROPERTY,
    });
  };

  return (
    <div className={styles.container}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={openFloorModal}
        onClose={handleClose}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.floorPlansModal}>
          {modalContent && modalContent.images.items.length > 0 ? (
            <Carousel
              navButtonsAlwaysVisible
              animation="slide"
              autoPlay={false}
              style={{ margin: '0 auto' }}
              NextIcon={categoryNext}
              PrevIcon={categoryPrev}
              indicatorIconButtonProps={indicatorProps}
              activeIndicatorIconButtonProps={activeIndicator}
              navButtonsProps={navButtons}
            >
              {property &&
                modalContent &&
                modalContent.images.items.map((p) => {
                  return (
                    <div
                      key={p.id}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '0 auto',
                      }}
                    >
                      <img alt="" src={getImageURL(p.key, 500)} width="80%" />
                    </div>
                  );
                })}
            </Carousel>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0 auto',
              }}
            >
              <img
                alt=""
                src="/images/default-property.png"
                className={styles.singlePhoto}
              />
            </div>
          )}
        </div>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={openShareModal}
        onClose={handleCloseShareModal}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.floorPlansModal}>
          <p>
            We&apos;ve copied a link to this page in your clipboard. <br />
            Please paste the link into an e-mail, sms message or use
          </p>
          <ShareModule shareUrl={shareUrl} />
        </div>
      </Modal>
      <div className={styles.filtersContainer}>
        <ApartmentFilters />
      </div>
      {property ? (
        <div className={styles.resultsContainer}>
          <div className={styles.breadcrumb} style={{ marginTop: '0' }}>
            <Breadcrumbs listClassName={styles.breadcrumbList} separator="›">
              <Link color="inherit" to="/">
                Home
              </Link>
              <Link color="inherit" to="/apartments">
                Apartments
              </Link>
              <Link color="inherit" to="/apartments">
                Search results
              </Link>
              <Link
                color="inherit"
                to={`/apartments/${process.env.REACT_APP_DEFAULT_APARTMENTS}/${id}`}
              >
                {property?.marketingName || ''}
              </Link>
            </Breadcrumbs>
          </div>

          <div className={styles.tabsContainer}>
            <div
              id="singleAptHeader"
              className={styles.singleAptContainer}
              style={{ marginTop: '0.75rem' }}
            >
              <div className={styles.headerRow}>
                <div>
                  <p className={styles.singleAptTitle}>
                    {property.address.street}
                  </p>
                </div>
                <Clickable
                  onClick={() =>
                    logAnalyticsEvent({
                      label: property.manager.name,
                      action: ACTIONS.FAVORITE,
                      category: CATEGORIES.PROPERTY,
                    })
                  }
                >
                  <FavoriteItem id={id} type="PROPERTY" />
                </Clickable>
              </div>
              <p
                className={styles.singleAptTitle}
                style={{ marginTop: '0.25rem' }}
              >
                Area:{' '}
                <span className={styles.singleAptDetail}>{property.area}</span>
              </p>
              <p
                className={styles.singleAptTitle}
                style={{ marginTop: '0.25rem' }}
              >
                Property Type:{' '}
                <span className={styles.singleAptDetail}>{property.type}</span>
              </p>

              <div id="imageCarousel" className={styles.carouselContainer}>
                {property.images.items.length > 1 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      overflowX: 'auto',
                    }}
                  >
                    {property &&
                      property.images.items &&
                      property.images.items.map((image) => {
                        return (
                          <img
                            alt={image.name}
                            src={getImageURL(image.key, 500)}
                            className={styles.carouselPhoto}
                          />
                        );
                      })}
                  </div>
                ) : (
                  <img
                    alt=""
                    src={
                      !property.usesAppFolio
                        ? (property.images.items[0] &&
                            property.images.items.length > 0) ||
                          property.coverImageKey
                          ? getImageURL(
                              property.coverImageKey ||
                                property.images.items[0].key,
                              500
                            )
                          : '/images/default-property.png'
                        : property.photoUrls[0]
                    }
                    className={styles.singlePhoto}
                  />
                )}
              </div>

              <div id="propertyDescription">
                <p className={styles.singleAptTitle}>Description</p>
                <span className={styles.singleAptDescription}>
                  {property.description
                    ? property.description
                    : 'Contact us to get more information.'}
                </span>
              </div>
            </div>

            <div id="floorPlans" className={styles.singleAptContainer}>
              <p className={styles.singleAptTitle}>Floor Plans</p>
              <div>
                <table className={styles.floorPlans}>
                  <tr>
                    <td>BR</td>
                    <td>BA</td>
                    <td>RENT</td>
                    <td>SQ FT</td>
                    <td />
                  </tr>

                  {property &&
                    property.units.items.map((floor) => {
                      const area =
                        floor.minSquareFeet ||
                        floor.maxSquareFeet === 0 ||
                        floor.minSquareFeet === null ||
                        floor.maxSquareFeet === null
                          ? 'Contact us'
                          : floor.minSquareFeet === floor.maxSquareFeet
                          ? `${floor.minSquareFeet} ft`
                          : `${floor.minSquareFeet - floor.maxSquareFeet} ft`;
                      return (
                        <tr className={styles.floor}>
                          <td>{floor.bedrooms}</td>
                          <td>{floor.totalBaths}</td>
                          <td>{`$${floor.rent}`}</td>
                          <td>
                            {area === 'Contact us' ? (
                              <li
                                style={{
                                  listStyle: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                <Scroll to="propertyManager" smooth>
                                  {area}
                                </Scroll>
                              </li>
                            ) : (
                              area
                            )}
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={() => handleOpenFloorModal(floor)}
                              style={{
                                color: 'var(--saver-red)',
                                outline: 0,
                                border: 'none',
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                                fontFamily: 'Montserrat',
                                fontWeight: '500',
                              }}
                            >
                              View Photo(s)
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>

            <div id="amenities" className={styles.singleAptContainer}>
              <p className={styles.singleAptTitle}>Amenities</p>

              <div className={styles.amenitiesColumn}>
                {!isEmpty(amenities) &&
                  amenities.map((amenity) => (
                    <div key={amenity?.value} className={styles.amenities}>
                      <img
                        alt={amenity?.value || ''}
                        src={amenity?.image}
                        className={styles.amenitiesImages}
                      />
                      <p style={styles.amenitieText}>{amenity?.label}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div id="policy" className={styles.finalDetailContainer}>
              <div>
                <p className={styles.singleAptTitle}>Pet Policy</p>
                <div className={styles.petPolicy}>
                  <img
                    src="/apartmentIcons/pets.png"
                    alt=""
                    className={styles.amenitiesImages}
                  />
                  <h5>Dogs Welcome: </h5>
                  <h5>{property.amenities.dogsAllowed ? 'Yes' : 'No'}</h5>
                </div>
                <div className={styles.petPolicy}>
                  <img
                    src="/apartmentIcons/pets.png"
                    alt=""
                    className={styles.amenitiesImages}
                  />
                  <h5>Cats Welcome:</h5>
                  <h5>{property.amenities.catsAllowed ? 'Yes' : 'No'}</h5>
                </div>
                <p>{property.amenities.petDetails}</p>

                <div style={{ marginTop: '1rem' }}>
                  <button
                    type="button"
                    onClick={() => shareWithFriend(shareContent)}
                    className={styles.shareBtn}
                  >
                    <p>
                      <img alt="" src="/icons/shareWFriend.svg" />
                      Share with friend
                      <img alt="" src="/icons/redArrow.svg" />
                    </p>
                  </button>
                </div>
              </div>

              <div className={styles.mapContainer}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.REACT_APP_GEOCODING }}
                  center={{
                    lat: property.address.location.lat,
                    lng: property.address.location.lng,
                  }}
                  zoom={15}
                >
                  <SaverMarker
                    key={property.address.city}
                    saverTag={property.address.label}
                    label={`${property.address.street}, ${property.address.city}`}
                    lng={property.address.location.lng}
                    lat={property.address.location.lat}
                    name={property.manager.name}
                    street={property.address.street}
                    city={property.address.city}
                  />
                </GoogleMapReact>
              </div>
            </div>

            <div
              id="propertyManager"
              className={styles.singleAptManagerContainer}
            >
              <p className={styles.singleAptDarkTxt}>
                This property is managed by
              </p>
              <span className={styles.singleAptDetail2}>
                {property.manager.name}
              </span>
              <img
                alt="manager_logo"
                style={{ marginTop: '1.5rem' }}
                src={
                  property.manager.logo
                    ? getImageURL(property.manager.logo.key, 800)
                    : ''
                }
                className={styles.managerLogo}
              />
              <p style={{ marginTop: '1rem' }}>
                {property.manager.socialMedia.website && (
                  <>
                    <a
                      href={property.manager.socialMedia.website}
                      target="_blank"
                      rel="noreferrer"
                      className={
                        property.manager.socialMedia.website
                          ? styles.linktoMerchant
                          : styles.linktoSite
                      }
                      onClick={() =>
                        logAnalyticsEvent({
                          label: `${property.manager.name} → Company Website`,
                          action: ACTIONS.CLICK,
                          category: CATEGORIES.PROPERTY,
                        })
                      }
                    >
                      Visit our site
                    </a>
                    <span> | </span>
                  </>
                )}
                <Link
                  to={`/categories/${encodeCategoryName(category)}/${
                    property.manager.name
                  }`}
                  className={styles.linktoMerchant}
                >
                  More info about us
                </Link>
              </p>

              <div
                className={styles.contactButtons}
                style={{
                  justifyContent: isMobile ? 'space-between' : 'center',
                }}
              >
                {isMobile && (
                  <button
                    type="button"
                    className={styles.callManagerBtn}
                    onClick={() =>
                      handleGAEventClick('call', property.manager.name)
                    }
                  >
                    <a href={`tel:${property.manager.phone}`}>Call</a>
                  </button>
                )}
                <button type="button" className={styles.contactManagerBtn}>
                  <a
                    href={getEmailText(
                      property.manager.email,
                      property.address,
                      property.marketingName
                    )}
                    onClick={() =>
                      handleGAEventClick('email', property.manager.name)
                    }
                  >
                    Send E-mail
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress size={50} color="#e11b22" />
      )}
    </div>
  );
};

export default ApartmentDetails;
