import actionTypes from '../constants/actionTypes';

export const initialState = {
  list: [],
  indexById: {},
  loading: false,
  error: null
};

function merchantsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_MERCHANTS_START: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case actionTypes.GET_MERCHANTS_SUCCESS: {
      const byIdTable = action.payload.reduce((obj, merchant, i) => {
        obj[merchant.id] = merchant
        return obj
      }, {})
      const byNameTable = action.payload.reduce((obj, merchant, i) => {
        obj[merchant.name] = merchant
        return obj
      }, {})
      return {
        ...state,
        list: action.payload,
        indexById: byIdTable,
        indexByName: byNameTable,
        loading: false,
      };
    }
    case actionTypes.GET_MERCHANTS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
}

export default merchantsReducer;
