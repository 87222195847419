import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import styles from '../../styles/Auth.module.css';

const ConfirmSignUp = ({ changeAction, confirmAction, phone, email }) => {
  const signUpVerificationUser = useSelector(
    (state) => state.auth.signUpVerificationUser
  );
  const [time, setTime] = useState(120);

  useEffect(() => {
    // exit early when we reach 0
    if (!time) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [time]);

  const onResendCode = async () => {
    if (!time) {
      setTime(120);
      await Auth.resendSignUp(email);
    }
  };

  return (
    <div className={styles.signUpform}>
      <p>
        To confirm your information enter the code we have sent to your{' '}
        {(
          signUpVerificationUser?.codeDeliveryDetails?.DeliveryMedium ||
          'email or phone'
        ).toLowerCase()}{' '}
        {signUpVerificationUser?.codeDeliveryDetails?.Destination && (
          <strong>
            at{' '}
            {signUpVerificationUser?.codeDeliveryDetails?.Destination.toLowerCase()}
          </strong>
        )}
      </p>
      <input
        name="authCode"
        onChange={changeAction}
        placeholder="Confirmation code"
        className={styles.formInput}
      />
      <div className={styles.resendBlock}>
        <p>If you didn&apos;t receive a code </p>
        <button
          type="button"
          disabled={time}
          onClick={!time ? onResendCode : null}
        >
          <span>Resend {time ? `(${time})` : ''}</span>
        </button>
      </div>
      <button type="button" onClick={confirmAction} className="mainButton">
        Confirm
      </button>
    </div>
  );
};

export default ConfirmSignUp;

ConfirmSignUp.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  changeAction: PropTypes.func.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string,
};

ConfirmSignUp.defaultProps = {
  phone: '',
  email: '',
};
