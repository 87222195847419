import actionTypes from '../constants/actionTypes';
import errorHandler from '../../utils/errorHandler';
import { getProperties, filterAllProperties } from '../../api/propertyService';

export function getPropertiesAction() {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.GET_PROPERTIES_START));
    try {
      const items = await getProperties();
      dispatch(actionReducer(items, actionTypes.GET_PROPERTIES_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.GET_PROPERTIES_FAIL));
    }
  };
}

export function selectMerchantPropertiesAction(merchantId) {
  return (dispatch) => {
    dispatch(actionReducer(merchantId, actionTypes.SELECT_MERCHANT_PROPERTIES));
  };
}

export function resetFiltersAction() {
  return (dispatch) => {
    dispatch(actionReducer(null, actionTypes.RESET_FILTERS_PROPERTIES));
  };
}

export function filterAllPropertiesAction(
  minPrice = 0,
  maxPrice = 3500,
  leaseStarting = 'FALL_22',
  bedrooms = [0, 1, 2, 3, 4, 5, 6],
  bathrooms = [1, 2, 3, 4, 5],
  areas = [
    'NORTH_OF_LANE_AVE',
    'BETWEEN_15TH_AND_LANE_AVE',
    'BETWEEN_10TH_AND_14TH_AVE',
    'SOUTH_OF_10TH_AVE',
    'OFF_CAMPUS',
    'WEST_CAMPUS',
  ],
  rentRangeType = 'UNIT',
  merchantId,
  amenities = []
) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.FILTER_PROPERTIES_START));
    try {
      const filteredItems = await filterAllProperties(
        minPrice,
        maxPrice,
        leaseStarting,
        bedrooms,
        bathrooms,
        areas,
        rentRangeType,
        merchantId,
        amenities
      );
      dispatch(
        actionReducer(filteredItems, actionTypes.FILTER_PROPERTIES_SUCCESS)
      );
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.FILTER_PROPERTIES_FAIL));
    }
  };
}

const actionReducer = (payload, type) => ({
  type,
  payload,
});
