import { useEffect, useLayoutEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { graphqlOperation, API, Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Modal } from '@material-ui/core';
import SaverInput from '../components/SaverInput';
import styles from '../styles/Profile.module.css';
import SaverSwitch from '../components/SaverSwitch';
import { updateCustomer } from '../graphql/mutations';
import {
  signOutUserAction,
  getUserSessionAction,
} from '../redux/actions/authActions';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import useTitle from '../hooks/useTitle';

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { profile } = useSelector((state) => state.auth);
  const [showResetModal, setShowResetModal] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useTitle('Profile');

  useLayoutEffect(() => {
    if (!profile) {
      history.push('/');
    }
  }, [history, profile]);

  const initialForm = {
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    email: profile?.email || '',
    phone: profile?.phone || '',
    viaEmail: profile?.viaEmail || false,
    viaSMS: profile?.viaSMS || false,
    details: profile && profile.address ? profile.address[0].details : '',
    street: profile && profile.address ? profile.address[0].street : '',
    country: profile && profile.address ? profile.address[0].country : '',
    city: profile && profile.address ? profile.address[0].city : '',
    state: profile && profile.address ? profile.address[0].state : '',
    zip: profile && profile.address ? profile.address[0].zip : '',
    sendMagazine: false,
  };

  const [formState, updateFormState] = useState(initialForm);

  const handleChange = (e) => {
    if (e.target.name !== 'phone') {
      e.persist();
    }
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  const handleUpdate = async () => {
    try {
      const formUser = {
        id: profile.id,
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email,
        phone: formState.phone,
        address: [
          {
            details: formState.details,
            city: formState.city || 'Columbus',
            street: formState.street,
            state: formState.state || 'OH',
            country: formState.country || 'US',
            zip: formState.zip,
          },
        ],
        viaEmail: formState.viaEmail,
        viaSMS: formState.viaSMS,
      };
      await API.graphql(graphqlOperation(updateCustomer, { input: formUser }));
      dispatch(getUserSessionAction());
      logAnalyticsEvent({
        label: `${formUser.id} → Profile`,
        action: ACTIONS.UPDATE,
        category: CATEGORIES.USER,
      });
      alert('User information updated successfully.');
    } catch (error) {
      alert(error.errors[0].message);
    }
  };

  useEffect(() => {
    API.graphql(
      graphqlOperation(updateCustomer, {
        input: {
          id: profile?.id,
          viaEmail: formState.viaEmail,
          viaSMS: formState.viaSMS,
        },
      })
    );
  }, [formState.viaEmail, formState.viaSMS, profile?.id]);

  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(signOutUserAction());
    history.push('/');
  };

  const passwordVaildation = () => {
    const pattern = new RegExp(
      '(?=^.{8,}$)(?=.*)(?=.*[!@#$%^&*]+)(?![.n])(?=.*[A-Z])(?=.*[a-z]).*$'
    );
    return pattern.test(formState.password);
  };

  const changePasswordAction = async (e) => {
    e.preventDefault();
    const { oldPassword, password, confirmPassword } = formState;

    setOldPasswordError(false);
    setPasswordError(false);
    if (!password || !confirmPassword || !oldPassword) {
      return;
    }
    if (!passwordVaildation()) {
      setPasswordError(true);
      return;
    }

    if (formState.password !== formState.confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }
    setConfirmPasswordError(false);
    try {
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword, password);
        })
        .then(() => {
          setPasswordChanged(true);
        })
        .catch((err) => {
          setOldPasswordError(true);
          console.log(err);
        });
    } catch (err) {
      alert(err.message);
    }
  };
  const dismissResetModal = () => {
    setShowResetModal(false);
    setPasswordChanged(false);
  };

  const [deleting, setDeleting] = useState(false);
  const onDelete = async () => {
    setDeleting(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      const { jwtToken: accessToken } = user.signInUserSession.accessToken;

      const response = await fetch(
        `${process.env.REACT_APP_PHOENIX_API_URL}/user/account`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.json();

      const isSuccess = data.success === true;

      if (!isSuccess) {
        throw new Error("Response wasn't successful");
      } else {
        onLogOut({ preventDefault: () => {} });
      }
    } catch (e) {
      console.error(e);
      window.alert('Failed to delete account. Contact support@thesaver.com');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.largeTitle}>Account profile</p>
      <Modal
        className={styles.modal}
        open={deleteModalOpen}
        BackdropProps={{
          style: { backdropFilter: 'blur(6px)' },
        }}
      >
        <div className="flex items-center justify-center h-screen w-screen">
          <div className="bg-white rounded-md shadow-md px-6 py-4 lg:max-w-xl">
            <h1 className="m-0 mb-4 text-center text-2xl">Delete Account?</h1>
            <p className="text-center">
              Are you sure you want to delete your account? This can&apos;t be
              undone and you will lose all your saved data.
            </p>
            <div className="flex items-center justify-center">
              {deleting ? (
                <div className="text-center text-saver-red">
                  Deleting... Please Wait
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    className="flex-1 mr-4 bg-transparent border-none text-lg font-bold cursor-pointer"
                    onClick={() => setDeleteModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="flex-1 mr-4 bg-saver-red text-white border-none text-lg rounded-full cursor-pointer"
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <img
        src="/icons/profile.svg"
        alt=""
        data-testid="profile"
        width="48px"
        style={{ marginBottom: '2rem' }}
      />

      <div className={styles.profileCard}>
        <p className={styles.cardTitle}>Contact information</p>
        <div className={styles.profileInputs}>
          <div className={styles.row}>
            <SaverInput
              placeholder={formState.firstName === '' ? 'First Name' : ''}
              value={formState.firstName}
              type="text"
              name="firstName"
              changeAction={handleChange}
              icon="/icons/userLogin.svg"
            />
            <SaverInput
              placeholder={formState.phone === '' ? '999-999-9999' : ''}
              value={formState.phone}
              type="text"
              name="phone"
              changeAction={handleChange}
              icon="/icons/phoneNumber.svg"
            />
          </div>
          <div className={styles.row}>
            <SaverInput
              placeholder={formState.lastName === '' ? 'Last Name' : ''}
              value={formState.lastName}
              type="text"
              name="lastName"
              changeAction={handleChange}
              icon="/icons/userLogin.svg"
            />
            <SaverInput
              placeholder={formState.email === '' ? 'Email address' : ''}
              value={formState.email}
              type="text"
              name="email"
              changeAction={handleChange}
              icon="/icons/emailAuth.svg"
            />
          </div>
        </div>

        <div className={styles.switches}>
          <FormControlLabel
            control={
              <SaverSwitch
                checked={formState.viaEmail}
                onChange={(e) =>
                  updateFormState(() => ({
                    ...formState,
                    [e.target.name]: e.target.checked,
                  }))
                }
                name="viaEmail"
                checkedByDefault
              />
            }
            label={
              <span>Include me in your special e-mail only promotions</span>
            }
          />
          <FormControlLabel
            control={
              <SaverSwitch
                checked={formState.viaSMS}
                onChange={(e) =>
                  updateFormState(() => ({
                    ...formState,
                    [e.target.name]: e.target.checked,
                  }))
                }
                name="viaSMS"
                checkedByDefault
              />
            }
            label={<span>Include me in your special text only promotions</span>}
          />
        </div>
      </div>

      <div className={styles.profileCard} style={{ marginTop: '3rem' }}>
        <p className={styles.cardTitle}>My addresses</p>
        <div className={styles.profileInputs}>
          {/* className={styles.row} */}
          <div className={styles.row}>
            <SaverInput
              placeholder={formState.details === '' ? 'Street Address' : ''}
              value={formState.details}
              type="text"
              name="details"
              changeAction={handleChange}
              icon="/icons/locationProfile.svg"
            />
            <SaverInput
              placeholder={formState.city === '' ? 'City' : ''}
              value={formState.city}
              type="text"
              name="city"
              changeAction={handleChange}
              icon="/icons/locationProfile.svg"
            />
          </div>
          <div className={styles.row}>
            <SaverInput
              placeholder={formState.state === '' ? 'State' : ''}
              value={formState.state}
              type="text"
              name="state"
              changeAction={handleChange}
              icon="/icons/ohio.svg"
            />
            {/* <SaverInput
              placeholder={formState.city === '' ? 'City' : ''}
              value={formState.city}
              type="text"
              name="city"
              changeAction={handleChange}
              icon="/icons/locationProfile.svg"
            /> */}
            <SaverInput
              placeholder={formState.zip === '' ? 'Zip code' : ''}
              value={formState.zip}
              type="text"
              name="zip"
              changeAction={handleChange}
              icon="/icons/zip.svg"
            />
          </div>
        </div>
        {/* <div className={styles.switches}>
          <FormControlLabel
            control={
              <SaverSwitch
                checked={formState.postalMailing}
                onChange={handleChange}
                name="postalMailing"
              />
            }
            label={
              <span>I´d like to add my address to your postal mailing*.</span>
            }
          />
          <p className={styles.description}>
            *If multiple addresses have been added then only the first address
            listed can be added to our postal mailing.
          </p>
          <button type="button" className={styles.addAddress}>
            Add another address
          </button>
        </div> */}
      </div>

      <div className={styles.actions}>
        <button
          type="button"
          className="mainButton"
          onClick={handleUpdate}
          style={{
            width: '40%',
            padding: '10px',
            marginBottom: '1rem',
            fontSize: 'large',
          }}
        >
          Save
        </button>

        <button
          onClick={() => setShowResetModal(true)}
          type="button"
          className={styles.resetPswd}
          style={{ marginTop: '1rem' }}
          data-testid="reset-password-button"
        >
          Reset my password
        </button>

        <button type="button" className={styles.logOut} onClick={onLogOut}>
          Log out
        </button>

        <button
          type="button"
          className={`${styles.logOut} ${styles.deleteAccount}`}
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete Account
        </button>
        <span className={styles['deleteAccount-warning']}>
          Warning! This is permanent and cannot be reversed!
        </span>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showResetModal}
        className="resetModal"
        onClose={() => setShowResetModal(false)}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.resetModalBody}>
          {!passwordChanged ? (
            <form onSubmit={changePasswordAction} className={styles.resetBody}>
              <div className={styles.inputContainer}>
                <input
                  name="oldPassword"
                  type="password"
                  onChange={handleChange}
                  placeholder="Old Password"
                  className={
                    styles.formInput +
                    (oldPasswordError ? ` ${styles.formInputError}` : '')
                  }
                  required
                />
                {oldPasswordError && (
                  <span className={styles.errorMessage}>
                    Please enter your old password correctly
                  </span>
                )}
              </div>
              <div className={styles.inputContainer}>
                <input
                  name="password"
                  type="password"
                  onChange={handleChange}
                  placeholder="Password"
                  className={styles.formInput}
                  required
                />
                {passwordError && (
                  <span className={styles.errorMessage}>
                    Entered password doesn’t match our password policy
                  </span>
                )}
                <span
                  style={{
                    display: 'block',
                    fontSize: '10px',
                    color: 'var(--saver-darkBtn)',
                    textAlign: 'left',
                  }}
                >
                  * Password must be longer than 6 characters, include at least
                  a number, special character, uppercase letter and a lowercase
                  letters
                </span>
              </div>
              <div className={styles.inputContainer}>
                <input
                  name="confirmPassword"
                  type="password"
                  onChange={handleChange}
                  placeholder="Confirm Password*"
                  className={styles.formInput}
                />
                {confirmPasswordError && (
                  <span className={styles.errorMessage}>
                    The passwords entered do not match.
                  </span>
                )}
              </div>
              <div className={styles.resetButtonsContainer}>
                <button
                  type="submit"
                  className="mainButton"
                  style={{
                    width: '56%',
                    padding: '2%',
                    marginBottom: '1rem',
                    marginTop: '1rem',
                  }}
                >
                  Reset Password
                </button>
              </div>
            </form>
          ) : (
            <p className={styles.resetSuccess}>
              Thank You! <br /> Your password is changed
            </p>
          )}
          <div className={styles.resetButtonsContainer}>
            <button
              type="button"
              onClick={dismissResetModal}
              className="mainButton"
              style={{
                width: '56%',
                padding: '2%',
                marginBottom: '1rem',
                marginTop: '1rem',
              }}
            >
              {passwordChanged ? 'Close' : 'Cancel'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
