import { useState, useEffect, useRef } from 'react';
import { Breadcrumbs, Modal } from '@material-ui/core';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CategoriesList from '../components/CategoriesListing';
import { sortASCEnabled } from '../utils/generalFunctions';
import getImageURL from '../utils/getImageURL';
import styles from '../styles/Categories.module.css';
import SingleMerchant from '../components/SingleMerchant';
import useQuery from '../hooks/useQuery';
import RedeemModal from '../utils/redeemModalContent';
import { encodeCategoryName } from '../utils/categoryNameEcoder';
import useTitle from '../hooks/useTitle';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Merchant = () => {
  const { merchant, category } = useParams();
  const query = useQuery();
  const location = useLocation();
  const selectedTab = query.get('selectedTab');
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const couponsByMerchant = useSelector(
    (state) => state.coupons.indexByMerchantId
  );
  const merchantsIndexByName = useSelector(
    (state) => state.merchants.indexByName
  );

  const tempCategories = useSelector((state) => state.categories.list);
  const categories = sortASCEnabled(tempCategories);
  const [modalOpen, setOpen] = useState(false);
  const anchor = document.querySelector('#details');

  useTitle(merchant);

  useEffect(() => {
    if (merchantsIndexByName) {
      setSelectedMerchant(merchantsIndexByName[merchant]);
    }
  }, [merchant, merchantsIndexByName]);

  useEffect(() => {
    if (selectedTab && anchor && selectedMerchant) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [anchor, selectedMerchant, selectedTab]);

  const prevLocation = usePrevious(location);
  useEffect(() => {
    if (prevLocation && prevLocation.search !== location.search) {
      window.location.reload();
    }
  }, [location, prevLocation]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterCouponsOrSpecials = (selector, getSpecials = false) =>
    selector
      ? selector.filter((coupon) => coupon.isSpecial === getSpecials)
      : [];

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="redeemModal"
        open={modalOpen}
        onClose={handleClose}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.modalContent}>
          <RedeemModal closeAction={handleClose} />
        </div>
      </Modal>

      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.options}>
            <div className={styles.fixedTitle}>
              <p className={styles.titles}>
                <img
                  src="/icons/categoriesIcon.svg"
                  alt=""
                  className={styles.icon}
                />
                Our Categories
              </p>
            </div>
            <CategoriesList categories={categories} />
          </div>
          <div className={styles.content}>
            <div className={styles.breadcrumb}>
              <Breadcrumbs listClassName={styles.breadcrumbList} separator="›">
                <Link color="inherit" to="/">
                  Home
                </Link>
                <Link
                  color="inherit"
                  to={`/categories/${encodeCategoryName(
                    process.env.REACT_APP_DEFAULT_CATEGORY
                  )}`}
                >
                  Categories
                </Link>
                <Link
                  color="inherit"
                  to={`/categories/${encodeCategoryName(category)}`}
                >
                  {category}
                </Link>
                <Link color="inherit" to={`/categories//${merchant}`}>
                  {merchant}
                </Link>
              </Breadcrumbs>
            </div>
            <div id="details">
              {selectedMerchant && (
                <SingleMerchant
                  id={selectedMerchant.id}
                  name={selectedMerchant.name}
                  logo={getImageURL(selectedMerchant.logo.key, 1500, 'contain')}
                  banner={getImageURL(
                    selectedMerchant.cover.key,
                    1500,
                    'contain'
                  )}
                  schedule={selectedMerchant.schedule}
                  address={selectedMerchant.address}
                  phone={selectedMerchant.phone}
                  email={selectedMerchant.email}
                  specials={filterCouponsOrSpecials(
                    couponsByMerchant[selectedMerchant.id],
                    true
                  )}
                  coupons={filterCouponsOrSpecials(
                    couponsByMerchant[selectedMerchant.id]
                  )}
                  saverAd={getImageURL(
                    selectedMerchant.saverAd.key,
                    1500,
                    'contain'
                  )}
                  facebook={selectedMerchant.socialMedia.facebook}
                  website={selectedMerchant.socialMedia.website}
                  instagram={selectedMerchant.socialMedia.instagram}
                  twitter={selectedMerchant.socialMedia.twitter}
                  selectedTab={selectedTab}
                  openAction={handleOpen}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Merchant;
