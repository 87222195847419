import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styles from '../styles/SingleMerchant.module.css';
import SaverMarker from './SaverMarker';
import ApartmentCard from './ApartmentCard';
import getImageURL from '../utils/getImageURL';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';

export default function ApartmentTabs({ selectedTab, properties }) {
  const [activeTab, setActiveTab] = useState(selectedTab || 'mapView');
  const [aptCardVisible, setAptCardVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const getModal = (p) => {
    setAptCardVisible(true);
    setModalContent(p);
  };

  useEffect(() => {
    if (properties.length > 0) {
      properties.forEach((property) => {
        logAnalyticsEvent({
          nonInteraction: true,
          label: property.manager.name,
          action: ACTIONS.LOADED,
          category: CATEGORIES.PROPERTY,
        });
      });
    }
  }, [properties]);

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabsHeader}>
        <button
          type="button"
          className={`${styles.tabTitle} ${
            activeTab === 'mapView' ? styles.active : ''
          }`}
          onClick={() => setActiveTab('mapView')}
        >
          Map View
        </button>
        <button
          type="button"
          className={`${styles.tabTitle} ${
            activeTab === 'listView' ? styles.active : ''
          }`}
          onClick={() => setActiveTab('listView')}
        >
          List View
        </button>
      </div>

      <div
        className={styles.tabContent}
        style={{ backgroundColor: activeTab === 'listView' ? 'white' : '' }}
      >
        {activeTab === 'mapView' && (
          <div className={styles.apartmentMapContainer}>
            <div
              className={styles.apartmentCardOnMap}
              style={{ display: aptCardVisible ? 'block' : 'none' }}
            >
              <button
                type="button"
                onClick={() => setAptCardVisible(false)}
                className="closeAptButton"
                style={{ float: 'left', zIndex: 999 }}
              >
                <img alt="" src="/icons/saverClose.svg" />
              </button>
              {modalContent ? (
                <ApartmentCard
                  id={modalContent.id}
                  merchantName={modalContent.manager.name}
                  address={`${modalContent.address.street}, ${modalContent.address.city}`}
                  backgroundImage={
                    !modalContent.usesAppFolio
                      ? (modalContent.images.items[0] &&
                          modalContent.images.items.length > 0) ||
                        modalContent.coverImageKey
                        ? getImageURL(
                            modalContent.coverImageKey ||
                              modalContent.images.items[0].key,
                            500,
                            'cover'
                          )
                        : '/images/default-property.png'
                      : modalContent.photoUrls[0]
                  }
                  bedrooms={modalContent.units.items[0]?.bedrooms}
                  bathrooms={modalContent.units.items[0]?.fullBaths}
                  priceRange={
                    modalContent.minRent === modalContent.maxRent
                      ? `$${modalContent.minRent}`
                      : `$${modalContent.minRent} - ${modalContent.maxRent}`
                  }
                />
              ) : (
                'modal'
              )}
            </div>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GEOCODING }}
              center={{ lat: 40.0007694, lng: -83.0074814 }}
              // center={{ lat: 40.0036, lng: -83.0125 }}
              zoom={properties && properties.length > 50 ? 14.025 : 12.5}
            >
              {properties.map((p) => {
                return (
                  <SaverMarker
                    key={p.id}
                    label={p.address.details}
                    lng={p.address.location.lng}
                    lat={p.address.location.lat}
                    name={p.marketingName}
                    actionType="modal"
                    modalFunction={() => getModal(p)}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
        )}
        {activeTab === 'listView' && (
          <div className={styles.listViewContainer}>
            {properties.map((p) => {
              return (
                <ApartmentCard
                  key={p.id}
                  id={p.id}
                  merchantName={p.manager.name}
                  address={`${p.address.street}, ${p.address.city}`}
                  backgroundImage={
                    !p.usesAppFolio
                      ? (p.images.items[0] && p.images.items.length > 0) ||
                        p.coverImageKey
                        ? getImageURL(
                            p.coverImageKey || p.images.items[0].key,
                            500,
                            'cover'
                          )
                        : '/images/default-property.png'
                      : p.photoUrls[0]
                  }
                  bedrooms={
                    p.units.items[0]?.bedrooms ? p.units.items[0].bedrooms : '-'
                  }
                  bathrooms={
                    p.units.items[0]?.fullBaths
                      ? p.units.items[0].fullBaths
                      : '-'
                  }
                  priceRange={
                    p.minRent
                      ? p.minRent === p.maxRent
                        ? `$${p.minRent}`
                        : `$${p.minRent} - ${p.maxRent}`
                      : 'Contact us'
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

ApartmentTabs.propTypes = {
  selectedTab: PropTypes.string,
  properties: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

ApartmentTabs.defaultProps = {
  selectedTab: 'mapView',
};
