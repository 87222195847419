import { Base64 } from 'js-base64';
import amplifyConfig from '../amplifyConfig';

const { aws_user_files_s3_bucket: bucket } = amplifyConfig;

const getImageURL = (imageKey, height, objectFit) => {
  const defaultHeight = height || 1500;
  const imageRequest = JSON.stringify({
    bucket,
    key: `public/${imageKey}`,
    edits: {
      resize: {
        height: defaultHeight <= 800 ? 800 : defaultHeight,
        fit: objectFit || 'contain',
      },
    },
  });
  const url = `${process.env.REACT_APP_RESIZE_ENDPOINT}/${Base64.encode(
    imageRequest
  )}`;
  return url;
};
export default getImageURL;
