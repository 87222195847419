import { API, graphqlOperation } from 'aws-amplify';
import requestAll from '../utils/requestAll';
import { getProperty, listPropertys } from '../graphql/queries';

export async function getProperties() {
  const properties = await requestAll(listPropertys, 'listPropertys');
  const table = {};
  properties.forEach((p) => {
    const previousValues = table[p.managerID] || [];
    table[p.managerID] = [...previousValues, p];
  });
  return { properties, table };
}

export async function getMerchantProperties(merchantId) {
  const properties = await requestAll(listPropertys, 'listPropertys', {
    filter: { managerID: { eq: merchantId } },
  });
  return properties;
}

export async function getSingleProperty(propertyId) {
  const property = await API.graphql(
    graphqlOperation(getProperty, { id: propertyId })
  );
  return property.data.getProperty;
}

export async function filterAllProperties(
  minPrice = 0,
  maxPrice = 3500,
  leaseStarting = 'FALL_22',
  bedrooms = [0, 1, 2, 3, 4, 5, 6],
  bathrooms = [1, 2, 3, 4, 5],
  areas = [
    'NORTH_OF_LANE_AVE',
    'BETWEEN_15TH_AND_LANE_AVE',
    'BETWEEN_10TH_AND_14TH_AVE',
    'SOUTH_OF_10TH_AVE',
    'OFF_CAMPUS',
    'WEST_CAMPUS',
  ],
  rentRangeType = 'UNIT',
  merchantId = '',
  amenities = []
) {
  const filtersObject = {
    types: '',
    areas: areas.join(','),
    amenities: amenities.join(','),
    bedrooms: bedrooms.join(','),
    bathrooms: bathrooms.join(','),
    minPrice: parseFloat(minPrice),
    maxPrice: parseFloat(maxPrice),
    leaseStarting,
    rentRangeType,
    merchantId,
    searchText: '',
  };
  const result = await API.get('propertiesapi', '/properties', {
    queryStringParameters: filtersObject,
  });
  return result.data;
}
