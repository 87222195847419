import actionTypes from '../constants/actionTypes';

export const initialState = {
  list: [],
  category: null,
};

function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case actionTypes.GET_CATEGORIES_FAIL: {
      return {
        ...state,
      };
    }
    case actionTypes.SELECT_CATEGORY_SUCCESS: {
      return {
        ...state,
        category: action.category,
      };
    }
    default:
      return state;
  }
}

export default categoriesReducer;
