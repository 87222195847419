import { Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styles from '../styles/SingleMerchant.module.css';

export default function SaverMarker({
  lat,
  lng,
  name,
  street,
  // city,
  label,
  saverTag,
  actionType,
  modalFunction,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const popOverHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopOverHandler = () => {
    setAnchorEl(null);
  };

  const openInGoogle = (latitude, longitude) => {
    window.open(
      `https://maps.google.com/maps?z=18&t=m&q=${latitude},${longitude}`
    );
  };

  const functionHandler = (type, event) => {
    switch (type) {
      case 'popOver':
        popOverHandler(event);
        break;
      case 'modal':
        modalFunction();
        break;
      default:
        break;
    }
  };

  return (
    <div className="marker" title={name}>
      <button
        type="button"
        className={styles.popOverButton}
        onClick={(event) => functionHandler(actionType, event)}
      >
        <div className="saverMarkerContainer">
          <img src="/icons/marker.svg" alt="" width="32px" />
          <span className="saverMarkerDetail">Latitude: {lat}</span>
          <span className="saverMarkerDetail">Longitude: {lng}</span>
        </div>
        <span className="saverMarkerTitle">{street}</span>
      </button>
      <Popover
        id="simple-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopOverHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ minWidth: '164px' }}
      >
        <span className={styles.popOver}>
          <h4>{name}</h4>
          <p style={{ color: '#5c6269', fontWeight: '600' }}>{saverTag}</p>
          <p>{label}</p>
          {lat && lng && (
            <button
              type="button"
              onClick={() => openInGoogle(lat, lng)}
              className={styles.popOverLink}
            >
              View on Google Maps
            </button>
          )}
        </span>
      </Popover>
    </div>
  );
}

SaverMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  name: PropTypes.string,
  street: PropTypes.string,
  // city: PropTypes.string,
  label: PropTypes.string,
  saverTag: PropTypes.string,
  actionType: PropTypes.string,
  modalFunction: PropTypes.func,
};

SaverMarker.defaultProps = {
  lat: '',
  lng: '',
  name: '',
  street: '',
  // city: '',
  label: '',
  saverTag: '',
  actionType: 'popOver',
  modalFunction: () => {},
};
