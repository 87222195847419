import actionTypes from '../constants/actionTypes';
import MerchantService from '../../api/merchantService';
import errorHandler from '../../utils/errorHandler';

const merchantService = new MerchantService();

export function getMerchantsAction() {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.GET_MERCHANTS_START));
    try {
      const merchants = await merchantService.getMerchants();
      dispatch(actionReducer(merchants, actionTypes.GET_MERCHANTS_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.GET_MERCHANTS_FAIL));
    }
  };
}

const actionReducer = (payload, type) => ({
  type,
  payload,
});
