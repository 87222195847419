import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../styles/Apartments.module.css';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import Clickable from './Clickable';
import FavoriteItem from './FavoriteItem';

export default function ApartmentCard({
  backgroundImage,
  address,
  bedrooms,
  bathrooms,
  priceRange,
  id,
  merchantName,
}) {
  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.cardFav}>
        <Clickable
          onClick={() =>
            logAnalyticsEvent({
              label: merchantName,
              action: ACTIONS.FAVORITE,
              category: CATEGORIES.PROPERTY,
            })
          }
        >
          <FavoriteItem id={id} type="PROPERTY" />
        </Clickable>
      </div>
      <Link to={`/apartments/results/${id}`}>
        <Clickable
          onClick={() =>
            logAnalyticsEvent({
              label: merchantName,
              action: ACTIONS.CLICK,
              category: CATEGORIES.PROPERTY,
            })
          }
        >
          <div
            className={styles.card}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className={styles.bottomBanner}>
              <p style={{ fontSize: '14px', fontWeight: '600' }}>{address}</p>
              <p>
                <span style={{ marginRight: '1rem' }}>
                  <img alt="" src="/icons/numOfBeds.svg" />
                  {bedrooms} beds
                </span>
                <span>
                  <img alt="" src="/icons/numOfBaths.svg" />
                  {bathrooms} baths
                </span>
                <span
                  style={{
                    float: 'right',
                    fontWeight: '700',
                    fontSize: '14px',
                  }}
                >
                  {priceRange}
                </span>
              </p>
            </div>
          </div>
        </Clickable>
      </Link>
    </div>
  );
}

ApartmentCard.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  bedrooms: PropTypes.string.isRequired,
  bathrooms: PropTypes.string.isRequired,
  priceRange: PropTypes.string.isRequired,
  merchantName: PropTypes.string.isRequired,
};
