import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from '../styles/SingleMerchant.module.css';
import { formatPhoneNumber, week } from '../utils/generalFunctions';
import Coupon from './Coupon';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';

export default function SaverTabs({
  selectedTab,
  location,
  phone,
  email,
  schedule,
  coupons,
  specials,
  openAction,
  merchantName,
}) {
  const [activeTab, setActiveTab] = useState(selectedTab || 'info');
  const orderedSchedule = schedule;

  const onClickTab = (tab) => {
    setActiveTab(tab);
    logAnalyticsEvent({
      label: `${merchantName} → ${tab}`,
      action: ACTIONS.CLICK,
      category: CATEGORIES.BUSINESS_LISTING,
    });
  };

  if (orderedSchedule) {
    orderedSchedule.sort(function sortByDay(a, b) {
      const day1 = a.day.toLowerCase();
      const day2 = b.day.toLowerCase();
      return week[day1] - week[day2];
    });
  }

  const handleGAEventClick = (type) => {
    logAnalyticsEvent({
      label: `${merchantName} → ${type}`,
      action: ACTIONS.CLICK,
      category: CATEGORIES.BUSINESS_LISTING,
    });
  };

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabsHeader}>
        <button
          type="button"
          className={`${styles.tabTitle} ${
            activeTab === 'info' ? styles.active : ''
          }`}
          onClick={() => onClickTab('info')}
        >
          Store Information
        </button>
        <button
          type="button"
          className={`${styles.tabTitle} ${
            activeTab === 'coupons' ? styles.active : ''
          }`}
          onClick={() => onClickTab('coupons')}
        >
          Coupons
        </button>
        <button
          type="button"
          className={`${styles.tabTitle} ${
            activeTab === 'specials' ? styles.active : ''
          }`}
          onClick={() => onClickTab('specials')}
        >
          Specials
        </button>
      </div>
      <div className={styles.tabContent}>
        {activeTab === 'info' && (
          <div className={styles.mainInfo}>
            <>
              {location && location.length ? (
                <>
                  <p className={styles.sectionTitle}>Location</p>
                  <span className={styles.sectionDetail}>
                    <img
                      src="/icons/location.svg"
                      alt=""
                      className="iconLeft"
                    />
                    {location[0].street}
                  </span>
                </>
              ) : (
                <span className={styles.sectionDetail}>
                  <img src="/icons/location.svg" alt="" className="iconLeft" />-
                </span>
              )}
            </>

            <div
              className={styles.mainInfo}
              style={{ marginBottom: '-0.2rem' }}
            >
              <p className={styles.sectionTitle}>Contact us</p>
              {phone && (
                <a
                  className={styles.sectionDetail}
                  onClick={() => handleGAEventClick('call')}
                  role="button"
                  tabIndex="0"
                  aria-hidden="true"
                  href={`tel:${formatPhoneNumber(phone)}`}
                >
                  <img src="/icons/phone.svg" alt="" className="iconLeft" />
                  {formatPhoneNumber(phone)}
                </a>
              )}
              {email && (
                <a
                  className={styles.sectionDetail}
                  href={`mailto:${email}`}
                  onClick={() => handleGAEventClick('email')}
                >
                  <img src="/icons/Email.svg" alt="" className="iconLeft" />
                  {email}
                </a>
              )}
            </div>

            <div className={styles.mainInfo} style={{ marginBottom: '-1rem' }}>
              <p className={styles.sectionTitle}>Hours of Operation</p>
              <div className={styles.scheduleHolder}>
                {orderedSchedule
                  ? orderedSchedule.map((h) => {
                      const format = 'h:mm a';
                      const sameDay = moment().format('dddd');
                      const from = moment(h.openAt, 'H:mm').format(format);
                      const to = moment(h.openAt, 'H:mm')
                        .add(h.workingHours, 'h')
                        .format(format);
                      return (
                        <div key={h.day} className={styles.dayDetail}>
                          <div
                            className={styles.dayListed}
                            style={{
                              fontWeight: h.day === sameDay ? 600 : '',
                            }}
                          >
                            {h.day}
                          </div>
                          <div className={styles.dailyHours}>
                            {h.closed ? (
                              <span className={styles.isClosed}>{h.label}</span>
                            ) : (
                              <span
                                style={{
                                  fontWeight: h.day === sameDay ? 600 : '',
                                }}
                                className={styles.hoursListed}
                              >{`${from} to ${to}`}</span>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </div>
            </div>
          </div>
        )}
        {activeTab === 'coupons' && (
          <div style={{ padding: '1rem' }}>
            {coupons && coupons.length > 0 ? (
              <div className={styles.couponGrid}>
                {coupons.map((c) => {
                  return (
                    <Coupon
                      key={c.id}
                      couponId={c.id}
                      title={c.title}
                      disclaimer={c.disclaimer}
                      description={c.content}
                      redeem={openAction}
                      merchantName={merchantName}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={styles.noData}>
                <img src="/icons/noMerchants.svg" alt="" width="40px" />
                <p>
                  Unfortunately we don&apos;t currently have any coupons.
                  <br />
                  Check to see if we currently have specials.
                </p>
              </div>
            )}
          </div>
        )}
        {activeTab === 'specials' && (
          <div style={{ padding: '1rem' }}>
            {specials && specials.length > 0 ? (
              <div className={styles.couponGrid}>
                {specials.map((s) => {
                  return (
                    <Coupon
                      key={s.id}
                      couponId={s.id}
                      title={s.title}
                      disclaimer={s.disclaimer}
                      description={s.content}
                      redeem={openAction}
                      merchantName={merchantName}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={styles.noData}>
                <img src="/icons/noMerchants.svg" alt="" width="40px" />
                <p>
                  Unfortunately we don&apos;t currently have any specials.
                  <br />
                  Check to see if we currently have coupons.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

SaverTabs.propTypes = {
  selectedTab: PropTypes.string,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  openAction: PropTypes.func.isRequired,
  merchantName: PropTypes.string,
  location: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      detials: PropTypes.string,
      label: PropTypes.string,
      phone: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string,
      location: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }).isRequired,
    }).isRequired
  ),
  schedule: PropTypes.arrayOf(
    PropTypes.shape({
      closed: PropTypes.bool,
      day: PropTypes.string,
      label: PropTypes.string,
      openAt: PropTypes.string,
      workingHours: PropTypes.number,
    })
  ),
  specials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      merchantID: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      disclaimer: PropTypes.string,
      expirationDate: PropTypes.string,
      useUniversalExpirationDate: PropTypes.bool,
      isSpecial: PropTypes.bool,
      valueType: PropTypes.string,
      redeemable: PropTypes.string,
      enabled: PropTypes.bool,
      featured: PropTypes.bool,
      value: PropTypes.number,
      redemptions: PropTypes.arrayOf(
        PropTypes.shape({
          items: PropTypes.string,
          nextToken: PropTypes.string,
        })
      ),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }).isRequired
  ),
  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      merchantID: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      disclaimer: PropTypes.string,
      expirationDate: PropTypes.string,
      useUniversalExpirationDate: PropTypes.bool,
      isSpecial: PropTypes.bool,
      valueType: PropTypes.string,
      redeemable: PropTypes.string,
      enabled: PropTypes.bool,
      featured: PropTypes.bool,
      value: PropTypes.number,
      redemptions: PropTypes.arrayOf(
        PropTypes.shape({
          items: PropTypes.string,
          nextToken: PropTypes.string,
        })
      ),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }).isRequired
  ),
};

SaverTabs.defaultProps = {
  selectedTab: 'info',
  location: {},
  schedule: {},
  coupons: {},
  specials: {},
  merchantName: 'merchant',
};
