import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../styles/SaverCategory.module.css';
import { encodeCategoryName } from '../utils/categoryNameEcoder';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';

const SaverCategory = ({ name: category, image }) => {
  const handleOnClick = (categoryEvent) => {
    logAnalyticsEvent({
      label: categoryEvent,
      action: ACTIONS.CLICK,
      category: CATEGORIES.CATEGORY,
    });
  };

  return (
    <Link to={`/categories/${encodeCategoryName(category)}`}>
      <div
        role="button"
        tabIndex="0"
        className={styles.imageCard}
        onClick={() => handleOnClick(category)}
        aria-hidden="true"
      >
        <div className={styles.imageContainer}>
          <div
            className={styles.categoryIcon}
            style={{ backgroundImage: `url(${image})` }}
          />
        </div>
        <p>{category}</p>
      </div>
    </Link>
  );
};

SaverCategory.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default SaverCategory;
