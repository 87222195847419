import authActionsTypes from '../constants/authActionTypes';
import Swal from 'sweetalert2';
import AuthService from '../../api/authService';
import errorHandler from '../../utils/errorHandler';
import { Auth } from 'aws-amplify';

const actionReducer = (payload, type) => ({
  type,
  payload,
});

const authService = new AuthService();

export function signInUserAction(username, password) {
  return async (dispatch) => {
    dispatch(actionReducer(null, authActionsTypes.SIGN_IN_START));
    if (password) {
      try {
        const user = await authService.signIn(username, password);
        dispatch(actionReducer(user, authActionsTypes.SIGN_IN_SUCCESS));
      } catch (error) {
        console.log('sign in error: ', error.message.toString());
        console.error(error);
        alert(error.message.toString());
        dispatch(actionReducer(error, authActionsTypes.SIGN_IN_FAIL));
      }
    } else {
      try {
        const user = await Auth.signIn(username);
        const {
          challengeParam: { sendTo },
        } = user;
        dispatch(
          actionReducer(
            {
              responseUser: user,
              sendTo,
            },
            authActionsTypes.SIGN_IN_SMS_PHONE_NUMBER
          )
        );
        Swal.fire({
          icon: 'success',
          text: `A code was sent to your number ending in ${sendTo}`,
          customClass: {
            container: 'swal2-small',
          },
        });

        setTimeout(() => Swal.close(), 5000);
      } catch (error) {
        console.error('sign in error (custom auth): ', error);
        dispatch(actionReducer(error, authActionsTypes.SIGN_IN_FAIL));
      }
    }
  };
}

export function sendAuthCode(code) {
  return async (dispatch, getState) => {
    try {
      const {
        auth: {
          customAuth: { responseUser },
        },
      } = getState();

      const user = await Auth.sendCustomChallengeAnswer(responseUser, code);
      dispatch(actionReducer(user, authActionsTypes.SIGN_IN_SUCCESS));
      dispatch(getUserSessionAction());
    } catch (error) {
      console.error('sign in error (custom auth - code verification): ', error);
      dispatch(actionReducer(error, authActionsTypes.SIGN_IN_FAIL));
    }
  };
}

export function signOutUserAction() {
  return async (dispatch) => {
    dispatch(actionReducer(null, authActionsTypes.SIGN_OUT_START));
    try {
      await authService.signOut();
      dispatch(actionReducer(null, authActionsTypes.SIGN_OUT_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, authActionsTypes.SIGN_OUT_FAIL));
    }
  };
}

export function getUserSessionAction() {
  return async (dispatch) => {
    dispatch(actionReducer(null, authActionsTypes.GET_SESSION_START));
    try {
      const session = await authService.getSession();
      dispatch(actionReducer(session, authActionsTypes.GET_SESSION_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, authActionsTypes.GET_SESSION_FAIL));
    }
  };
}

export function signUpUserAction({
  username,
  password,
  attributes: { email, phone_number, family_name, name },
}) {
  return async (dispatch) => {
    dispatch(actionReducer(null, authActionsTypes.SIGN_UP_START));
    try {
      const cognitoUser = await authService.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number,
          family_name,
          name,
        },
      });
      if ('codeDeliveryDetails' in cognitoUser) {
        const { DeliveryMedium, Destination } = cognitoUser.codeDeliveryDetails;
        Swal.fire({
          icon: 'info',
          text: `For security reasons, a verification code has been sent to your ${DeliveryMedium.toLowerCase()} at ${Destination.toLowerCase()}.`,
        });

        dispatch(
          actionReducer(
            cognitoUser,
            authActionsTypes.SIGN_UP_CONFIRMATION_CODE_SENT
          )
        );
      } else {
        const profile = await authService.getProfile(cognitoUser);
        dispatch(actionReducer(profile, authActionsTypes.SIGN_UP_SUCCESS));
      }
    } catch (error) {
      {
        error.message.toString() === 'No current user'
          ? console.log(error)
          : Swal.fire({
              icon: 'error',
              text: error.message.toString(),
            });
      }
      dispatch(actionReducer(error, authActionsTypes.SIGN_UP_FAIL));
    }
  };
}

export default {
  signInUserAction,
  signOutUserAction,
  getUserSessionAction,
};
