import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumbs } from '@material-ui/core';
import styles from '../styles/Categories.module.css';
import { sortASCEnabled } from '../utils/generalFunctions';
import CategoriesList from '../components/CategoriesListing';
import MerchantListing from '../components/MerchantListing';
import {
  decodeCategoryName,
  encodeCategoryName,
} from '../utils/categoryNameEcoder';
import useTitle from '../hooks/useTitle';

const Categories = () => {
  const { category } = useParams();
  let Cname = category;
  Cname = decodeCategoryName(Cname);
  const tempCategories = useSelector((state) => state.categories.list);
  const tempMerchants = useSelector((state) => state.merchants.list);

  useTitle(Cname);

  const categories = sortASCEnabled(tempCategories);
  let merchants;
  if (Cname !== process.env.REACT_APP_DEFAULT_CATEGORY) {
    merchants = tempMerchants.filter(({ categories: merchantCategories }) => {
      const allMerchantCategoryNames = merchantCategories.items.map(
        (merchantCategory) => merchantCategory.category.name
      );
      return allMerchantCategoryNames.includes(Cname);
    });
  } else {
    merchants = tempMerchants;
  }

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.options}>
          <div className={styles.fixedTitle}>
            <p className={styles.titles}>
              <img
                src="../icons/categoriesIcon.svg"
                alt=""
                className={styles.icon}
              />
              Our Categories
            </p>
          </div>
          <CategoriesList categories={categories} />
        </div>
        <div className={styles.content}>
          <div className={styles.breadcrumb}>
            <Breadcrumbs listClassName={styles.breadcrumbList} separator="›">
              <Link color="inherit" to="/">
                Home
              </Link>
              <Link
                color="inherit"
                to={`/categories/${encodeCategoryName(
                  process.env.REACT_APP_DEFAULT_CATEGORY
                )}`}
              >
                Categories
              </Link>
              <Link
                color="inherit"
                to={`/categories/${encodeCategoryName(Cname)}`}
              >
                {Cname}
              </Link>
            </Breadcrumbs>
          </div>
          <MerchantListing merchants={merchants} category={category} />
        </div>
      </div>
    </div>
  );
};

export default Categories;
