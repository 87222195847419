const { useEffect } = require('react');

const useTitle = (additionalString) => {
  useEffect(() => {
    const postFix = additionalString ? ` | ${additionalString}` : '';
    document.title = `The Saver${postFix}`;
  }, [additionalString]);
};

export default useTitle;
