import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useContext } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import SearchContext from '../context/SearchContext';

function Layout({ children }) {
  const { clearSearchHandler } = useContext(SearchContext);
  const currentPage = useLocation();
  const route = currentPage.pathname;
  return (
    <div onClick={clearSearchHandler} aria-hidden="true">
      {route.startsWith('/auth') ? (
        <>{children}</>
      ) : (
        <>
          <NavBar />
          <div>
            {children}
            <Footer />
          </div>
        </>
      )}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
