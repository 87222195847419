import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../styles/Categories.module.css';
import { encodeCategoryName } from '../utils/categoryNameEcoder';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';

export default function CategoriesList({ categories }) {
  const handleGAEventClick = (category) => {
    logAnalyticsEvent({
      label: category,
      category: CATEGORIES.CATEGORY,
      action: ACTIONS.CLICK,
    });
  };

  return (
    <div className={styles.listView}>
      {categories.map((category) => {
        let { name } = category;
        name = encodeCategoryName(name);

        return (
          <Link to={`/categories/${name}`} key={category.id}>
            <div
              onClick={() => handleGAEventClick(name)}
              role="button"
              tabIndex="0"
              aria-hidden="true"
            >
              <p className={styles.listItem}>
                {category.name}

                <img
                  src="../icons/arrow.png"
                  alt=""
                  className={styles.iconRight}
                />
              </p>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      image: PropTypes.shape({
        id: PropTypes.string,
        key: PropTypes.string,
        name: PropTypes.string,
        owner: PropTypes.string,
      }).isRequired,
    }).isRequired
  ).isRequired,
};
