import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Modal } from '@material-ui/core';
import styles from '../styles/Favorites.module.css';
import getImageURL from '../utils/getImageURL';
import FavoriteCoupon from '../components/FavoriteCoupon';
import RedeemModal from '../utils/redeemModalContent';
import useTitle from '../hooks/useTitle';

const Offers = () => {
  const allCoupons = useSelector((state) => state.coupons.items);
  const [modalOpen, setOpen] = useState(false);

  useTitle('Offers');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.container}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="redeemModal"
        open={modalOpen}
        onClose={handleClose}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.modalContent}>
          <RedeemModal closeAction={handleClose} />
        </div>
      </Modal>
      <div
        className={styles.header}
        style={{ marginTop: '1.5rem', marginBottom: '2.5rem' }}
      >
        <div className={styles.logo}>
          <img alt="" src="/icons/featured-offers.png" width="44px" />
        </div>
        <div className={styles.slogan}>
          <p className={styles.title}>Check Out All Our Offers</p>
          <span>We&apos;re all about saving you money!</span>
        </div>
      </div>

      <div className={styles.tabsWrapper} style={{ marginBottom: '1.5rem' }}>
        <div className={styles.grid}>
          {allCoupons && allCoupons.length > 0 ? (
            allCoupons.map((coupon) => {
              return (
                <div
                  key={coupon.id}
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <FavoriteCoupon
                    couponId={coupon.id}
                    key={coupon.id}
                    name={coupon.merchant.name}
                    title={coupon.title}
                    description={coupon.content}
                    disclaimer={coupon.disclaimer}
                    logo={getImageURL(coupon.merchant.logo.key)}
                    icon="isFavorite"
                    redeemAction={handleOpen}
                  />
                </div>
              );
            })
          ) : (
            <div className={styles.noData}>
              <img src="/icons/noMerchants.svg" alt="" width="40px" />
              <p>
                We&apos;re looking for all our offers.
                <br />
                If it is taking too long, try refreshing the page.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Offers;
