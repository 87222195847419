import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { BarLoader } from 'react-spinners';
import styles from '../styles/ApartmentSearch.module.css';
import { searchPropertiesAction } from '../redux/actions/aptSearchActions';
import { resetFiltersAction } from '../redux/actions/propertyActions';

const ApartmentsSearchBar = () => {
  const { loading, items, filtered } = useSelector((state) => state.properties);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const dispatch = useDispatch();
  const color = '#E11B22';

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 12px;
    background: #ffffff;
    width: 50%;
  `;

  const handleInputChange = (e) => {
    e.preventDefault();
    const newValue = e.target.value;

    if (newValue !== '') {
      setSearchText(newValue);
    } else {
      setSearchText('');
      dispatch(resetFiltersAction());
      setSearchResults(null);
    }
  };

  useEffect(() => {
    if (searchText.length >= 3 && items) {
      dispatch(searchPropertiesAction(searchText, items));
    }
  }, [dispatch, searchText, items]);

  useEffect(() => {
    if (filtered.length === items.length) {
      setSearchText('');
      setSearchResults(null);
    } else {
      setSearchResults(filtered.length);
    }
  }, [filtered.length, items.length]);

  return (
    <>
      <div className={styles.searchBar}>
        <input
          type="search"
          value={searchText}
          placeholder="Search properties by street or property name"
          onChange={handleInputChange}
        />
        {searchResults !== null && (
          <span
            className={styles.searchDetails}
          >{`${searchResults} properties found`}</span>
        )}
        <BarLoader color={color} css={override} loading={loading} />
      </div>
    </>
  );
};

export default ApartmentsSearchBar;
