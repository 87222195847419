import actionTypes from '../constants/actionTypes';

export const initialState = {
  items: [],
  editItem: null,
  loading: false,
  error: false,
  indexByMerchantId: [],
  indexById: [],
};

function couponReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_COUPON_START:
    case actionTypes.CREATE_COUPON_START:
    case actionTypes.GET_COUPONS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.SET_COUPON_EDIT: {
      return {
        ...state,
        editItem: action.payload,
      };
    }
    case actionTypes.GET_COUPONS_SUCCESS: {
      const byIdTable = action.payload.reduce((obj, coupon, i) => {
        obj[coupon.id] = coupon;
        return obj;
      }, {});
      const byMerchantTable = action.payload.reduce((obj, coupon, i) => {
        obj[coupon.merchant.id] = [...obj[coupon.merchant.id] ?? [], coupon];
        return obj;
      }, {});
      return {
        ...state,
        items: action.payload,
        indexById: byIdTable,
        indexByMerchantId: byMerchantTable,
        loading: false,
        error: false,
      };
    }
    case actionTypes.CREATE_COUPON_SUCCESS: {
      return {
        ...state,
        items: [...state.items, action.payload],
        loading: false,
        error: false,
      };
    }
    case actionTypes.UPDATE_COUPON_SUCCESS: {
      const filtered = state.items.filter(
        (coupon) => coupon.id !== action.payload.id
      );
      return {
        ...state,
        editItem: action.payload,
        items: [...filtered, action.payload],
        loading: false,
        error: false,
      };
    }
    case actionTypes.UPDATE_COUPON_FAIL:
    case actionTypes.CREATE_COUPON_FAIL:
    case actionTypes.GET_COUPONS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}

export default couponReducer;
