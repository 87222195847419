import PropTypes from 'prop-types';
import styles from '../../styles/Auth.module.css';

export default function ResetForm({ changeAction, resetAction }) {
  return (
    <div className={styles.signUpform} style={{ marginTop: '1.5rem' }}>
      <input
        name="username"
        onChange={changeAction}
        placeholder="Email address"
        className={styles.formInput}
        style={{ width: '300px' }}
      />
      <button
        type="button"
        onClick={resetAction}
        className="mainButton"
        style={{ margin: '1.5rem 0' }}
      >
        Send reset code
      </button>
    </div>
  );
}

ResetForm.propTypes = {
  changeAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
};
