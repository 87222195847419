/* eslint-disable */

export const listSaverCategorys = /* GraphQL */ `
  query listSaverCategorys {
    listCategorys {
      items {
        id
        name
        enabled
        image {
          id
          name
          owner
          key
        }
      }
    }
  }
`;

export const getSingleMerchant = /* GraphQL */ `
  query getSingleMerchant($name: String!) {
    listMerchants(filter: { name: { eq: $name } }) {
      items {
        id
        name
        email
        phone
        deliveryWebsite
        imageID
        type
        cover {
          key
        }
        saverAd {
          key
        }
        socialMedia {
          twitter
          facebook
          instagram
          website
        }
        address {
          phone
          city
          details
          label
          state
          street
          zip
          location {
            lng
            lat
          }
        }
        schedule {
          day
          openAt
          workingHours
          closed
          label
        }
        enabled
        sort
        logo {
          id
          key
        }
        coupons {
          items {
            id
            merchantID
            title
            content
            disclaimer
            expirationDate
            useUniversalExpirationDate
            isSpecial
            valueType
            redeemable
            enabled
            featured
            value
            redemptions {
              items {
                id
                customerID
                couponID
                expirationDate
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const getSingleCoupon = /* GraphQL */ `
  query getSingleCoupon($id: ID!) {
    getCoupon(filter: { id: { eq: $id } }) {
      items {
        id
        merchantID
        title
        content
        disclaimer
        expirationDate
        useUniversalExpirationDate
        isSpecial
        valueType
        redeemable
        enabled
        featured
        value
        merchant {
          id
          name
          email
          phone
          deliveryWebsite
          imageID
          type
          cover {
            key
          }
          saverAd {
            key
          }
          socialMedia {
            twitter
            facebook
            instagram
            website
          }
          address {
            location {
              lng
              lat
            }
            phone
            city
            details
            label
            state
            street
            zip
          }
          schedule {
            day
            openAt
            workingHours
            closed
            label
          }
          enabled
          sort
          logo {
            id
            key
          }
          coupons {
            items {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              redemptions {
                items {
                  id
                  customerID
                  couponID
                  expirationDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        redemptions {
          items {
            id
            customerID
            couponID
            expirationDate
            coupon {
              id
              merchantID
              title
              content
              disclaimer
              expirationDate
              useUniversalExpirationDate
              isSpecial
              valueType
              redeemable
              enabled
              featured
              value
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
