const open = (
  <span className="open">
    {' '}
    <img src="/icons/isOpen.svg" className="iconLeft" alt="" />
    OPEN NOW
  </span>
);

const closed = (
  <span className="closed">
    {' '}
    <img src="/icons/isClosed.svg" className="iconLeft" alt="" />
    NOW CLOSED
  </span>
);

export { open, closed };
