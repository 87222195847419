import { useState } from 'react';
import { Modal } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from '../styles/Navbar.module.css';
import SideDrawer from './SideDrawer';
import NavLinks from './NavLinks';
import Backdrop from './Backdrop';
import SearchBar from './SearchBar';

const NavBar = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const { profile } = useSelector((state) => state.auth);
  const [modalOpen, setOpen] = useState(false);
  const [openModal, setModal] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeModalHandler = () => {
    setModal(false);
  };

  const DrawerHandler = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };

  const router = useHistory();

  const favPage = () => {
    if (profile) {
      router.push('/favorites');
    } else {
      setModal(true);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={styles.modal}
        open={openModal}
        onClose={closeModalHandler}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.loginModal}>
          <h1>Must Sign In</h1>
          <div style={{ padding: '1rem', margin: '0rem' }}>
            <p className={styles.modalP}>
              You need to sign in to create your favorites.
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '90%',
              marginTop: '-0.25rem',
              fontWeight: '700',
            }}
          >
            <Link
              to={`/auth?formType=signIn&prev=${encodeURIComponent(
                `${router.location.pathname}${router.location.search}`
              )}`}
              className={styles.modalLink}
              style={{ marginRight: '1rem' }}
            >
              SIGN IN NOW
            </Link>
            <p
              className={styles.closeModal}
              onClick={closeModalHandler}
              aria-hidden="true"
            >
              NO THANKS
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={modalOpen}
        onClose={handleClose}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.modalContent}>
          <SearchBar />
        </div>
      </Modal>
      {drawerIsOpen && <Backdrop clicked={DrawerHandler} />}
      <SideDrawer show={drawerIsOpen} drawerToggle={DrawerHandler}>
        <nav>
          <NavLinks drawerToggle={DrawerHandler} />
        </nav>
      </SideDrawer>
      <nav className={styles.topNavBar}>
        <NavLinks />
      </nav>
      <div className={styles.navWrapper} data-testid="navbar">
        <div className={styles.navContent}>
          <div className={styles.logoContainer}>
            <Link to="/">
              <img
                alt="saverLogo"
                src="/icons/saverLogo.svg"
                data-testid="saver-logo"
              />
            </Link>
          </div>
          <div className={styles.mobileIcons}>
            <button
              type="button"
              onClick={() => handleOpen()}
              style={{ backgroundColor: 'transparent' }}
            >
              <img
                src="/icons/search.svg"
                alt=""
                data-testid="search-button"
                width="44px"
              />
            </button>

            <Link to={profile ? '/profile' : '/auth'} type="button">
              <img
                src="/icons/profile.svg"
                alt=""
                data-testid="profile"
                className={styles.profile}
              />
            </Link>

            <button
              type="button"
              onClick={DrawerHandler}
              style={{
                backgroundColor: 'transparent',
              }}
            >
              <img
                src="/icons/menu.svg"
                alt=""
                data-testid="hamburger-drawer"
                width="44px"
              />
            </button>
          </div>
          <div className={styles.optionsContainer}>
            <div className={styles.buttonGroup}>
              <button type="button" onClick={favPage} className={styles.heart}>
                <img
                  src="/icons/heart.svg"
                  alt="heart"
                  data-testid="heart"
                  width="32px"
                />
              </button>
              {profile ? (
                <Link
                  style={{
                    border: 'none',
                    padding: 0,
                    outline: 0,
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '1rem',
                  }}
                  to={profile ? '/profile' : '/auth'}
                  type="button"
                >
                  <img
                    src="/icons/profile.svg"
                    alt=""
                    data-testid="profile"
                    className={styles.profile}
                    width="34px"
                  />
                </Link>
              ) : (
                <>
                  <Link
                    to={`/auth?formType=signIn&prev=${router.location.pathname}`}
                    className="mainButton"
                    style={{
                      marginRight: '1rem',
                      marginLeft: '1.25rem',
                      height: '32px',
                      marginTop: '-0.25rem',
                      textAlign: 'center',
                    }}
                    data-testid="login-button"
                  >
                    Login
                  </Link>

                  <Link
                    to="/auth?formType=signUp"
                    className="secondaryButton"
                    data-testid="sign-up-button"
                    style={{
                      height: '32px',
                      marginTop: '-0.25rem',
                      textAlign: 'center',
                    }}
                  >
                    Sign up
                  </Link>
                </>
              )}
            </div>
            <SearchBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
