import { Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styles from '../styles/SingleMerchant.module.css';
import { getTodaySchedule, isOpen } from '../utils/generalFunctions';
import { closed, open } from '../utils/merchantConf';
import SaverMarker from './SaverMarker';
import SaverTabs from './SaverTabs';
import FavoriteItem from './FavoriteItem';
import logAnalyticsEvent from '../utils/logAnalyticsEvent';
import { ACTIONS, CATEGORIES } from '../utils/GAConstants';

export default function SingleMerchant({
  name,
  logo,
  banner,
  schedule,
  address,
  phone,
  email,
  coupons,
  specials,
  saverAd,
  facebook,
  instagram,
  twitter,
  website,
  selectedTab,
  openAction,
  id,
}) {
  const [modalOpen, setOpen] = useState(false);

  const canBeMarked =
    address && address.length > 0
      ? address.map((l) => {
          return !!(l.location.lat && l.location.lng);
        })
      : false;

  const handleOpen = () => {
    setOpen(true);
    logAnalyticsEvent({
      label: `${name} → Saver Add`,
      action: ACTIONS.CLICK,
      category: CATEGORIES.BUSINESS_LISTING,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.container}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={modalOpen}
        onClose={handleClose}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className={styles.modalContent}>
          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <div className={styles.tools}>
                  <button
                    className={styles.toolItem}
                    type="button"
                    onClick={() => zoomIn()}
                    title="Zoom in"
                  >
                    +
                  </button>
                  <button
                    className={styles.toolItem}
                    type="button"
                    onClick={() => zoomOut()}
                    title="Zoom out"
                  >
                    -
                  </button>
                  <button
                    className={styles.toolItem}
                    type="button"
                    onClick={() => resetTransform()}
                  >
                    Reset
                  </button>
                  <button
                    className={styles.toolItemClose}
                    type="button"
                    onClick={() => handleClose()}
                  >
                    Close
                  </button>
                </div>
                <TransformComponent>
                  <img src={saverAd} alt="" width="100%" />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      </Modal>
      <img alt="" src={banner} className={styles.bannerBackground} />

      <div className={styles.header}>
        <img src={logo} alt="" className={styles.merchantLogo} />
        <div className={styles.topBar}>
          <span className={styles.title}>
            {name || 'merchant'}{' '}
            <div className={styles.icon}>
              <FavoriteItem id={id} type="MERCHANT" name={name} />
            </div>
          </span>
          <span className={styles.businessStatus}>
            {isOpen(schedule) ? open : closed}
          </span>
          <span className={styles.schedule}>{getTodaySchedule(schedule)}</span>
        </div>
      </div>

      <SaverTabs
        selectedTab={selectedTab}
        location={address}
        phone={phone}
        email={email}
        schedule={schedule}
        coupons={coupons}
        specials={specials}
        openAction={openAction}
        merchantName={name}
      />

      <div className={styles.addDetails}>
        <p className={styles.sectionTitle}>Want to know more about us</p>
        {saverAd && (
          <div>
            <button
              type="button"
              className={styles.detailLink}
              onClick={handleOpen}
              style={{ marginTop: '1rem' }}
            >
              <img
                src="/icons/saverAd.svg"
                alt=""
                className={styles.iconLeft}
                style={{ marginTop: '-0.2rem' }}
              />
              See our Saver ad
            </button>
          </div>
        )}
        {website && (
          <div>
            <button type="button" className={styles.detailLink}>
              <a
                onClick={() =>
                  logAnalyticsEvent({
                    label: `${name} → Company Website`,
                    action: ACTIONS.CLICK,
                    category: CATEGORIES.BUSINESS_LISTING,
                  })
                }
                href={website}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/icons/website.svg"
                  alt=""
                  className={styles.iconLeft}
                  style={{ marginTop: '0.3rem' }}
                />
                <span> Visit our site</span>
              </a>
            </button>
          </div>
        )}
        {(instagram || facebook || twitter) && (
          <div className={styles.socialSection}>
            <p className={styles.sectionTitle} style={{ marginBottom: '1rem' }}>
              Follow us
            </p>
            <div className={styles.socialMediaWrapper}>
              {facebook && (
                <button type="button" className={styles.detailLink}>
                  <a
                    onClick={() =>
                      logAnalyticsEvent({
                        label: `${name} → Company FaceBook`,
                        action: ACTIONS.CLICK,
                        category: CATEGORIES.BUSINESS_LISTING,
                      })
                    }
                    href={facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src="/icons/fbLogo.svg" />
                  </a>
                </button>
              )}
              {instagram && (
                <button type="button" className={styles.detailLink}>
                  <a
                    onClick={() =>
                      logAnalyticsEvent({
                        label: `${name} → Company Instagram`,
                        action: ACTIONS.CLICK,
                        category: CATEGORIES.BUSINESS_LISTING,
                      })
                    }
                    href={instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src="/icons/igLogo.svg" />
                  </a>
                </button>
              )}
              {twitter && (
                <button type="button" className={styles.detailLink}>
                  <a
                    onClick={() =>
                      logAnalyticsEvent({
                        label: `${name} → Company Twitter`,
                        action: ACTIONS.CLICK,
                        category: CATEGORIES.BUSINESS_LISTING,
                      })
                    }
                    href={twitter}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src="/icons/twitterLogo.svg" />
                  </a>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles.addDetails} style={{ marginTop: '-1.5rem' }}>
        {address && address.length > 0 && (
          <>
            <p className={styles.sectionTitle}>
              {' '}
              <img
                src="/icons/locations.svg"
                alt=""
                className={styles.iconLeft}
                style={{ marginTop: '0.1rem' }}
              />
              {address.length > 1
                ? 'Also visit us at'
                : 'Looking for directions to us, click on the thumbtack within the map.'}
            </p>
            {address.length > 1 && (
              <div
                className={styles.locationsContainer}
                style={{ marginTop: '1rem' }}
              >
                {address.map((a) => {
                  return (
                    <div className={styles.locationDetail} key={a.label}>
                      <span>
                        <img
                          src="/icons/location.svg"
                          alt=""
                          className="iconLeft"
                        />
                        {a.street}
                      </span>
                      <span className={styles.locationItem}>
                        <img
                          src="/icons/phone.svg"
                          alt=""
                          className="iconLeft"
                        />
                        {a.phone}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
      {canBeMarked && (
        <div className={styles.mapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GEOCODING }}
            center={{
              lat: address[0].location.lat,
              lng: address[0].location.lng,
            }}
            zoom={address.length > 1 ? 11 : 17}
          >
            {address.map((a) => {
              return (
                <SaverMarker
                  key={a.label}
                  label={`${a.street}, ${a.city}`}
                  lng={a.location.lng}
                  lat={a.location.lat}
                  name={name}
                  street={a.street}
                  city={a.city}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
}

SingleMerchant.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  banner: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  openAction: PropTypes.func.isRequired,
  selectedTab: PropTypes.string,
  schedule: PropTypes.arrayOf(
    PropTypes.shape({
      closed: PropTypes.bool,
      day: PropTypes.string,
      label: PropTypes.string,
      openAt: PropTypes.string,
      workingHours: PropTypes.number,
    })
  ),
  address: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      details: PropTypes.string,
      label: PropTypes.string,
      phone: PropTypes.string,
      state: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string,
      location: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }).isRequired,
    }).isRequired
  ),
  specials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      merchantID: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      disclaimer: PropTypes.string,
      expirationDate: PropTypes.string,
      useUniversalExpirationDate: PropTypes.bool,
      isSpecial: PropTypes.bool,
      valueType: PropTypes.string,
      redeemable: PropTypes.string,
      enabled: PropTypes.bool,
      featured: PropTypes.bool,
      value: PropTypes.number,
      redemptions: PropTypes.arrayOf(
        PropTypes.shape({
          items: PropTypes.string,
          nextToken: PropTypes.string,
        })
      ),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }).isRequired
  ),
  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      merchantID: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      disclaimer: PropTypes.string,
      expirationDate: PropTypes.string,
      useUniversalExpirationDate: PropTypes.bool,
      isSpecial: PropTypes.bool,
      valueType: PropTypes.string,
      redeemable: PropTypes.string,
      enabled: PropTypes.bool,
      featured: PropTypes.bool,
      value: PropTypes.number,
      redemptions: PropTypes.arrayOf(
        PropTypes.shape({
          items: PropTypes.string,
          nextToken: PropTypes.string,
        })
      ),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }).isRequired
  ),
  saverAd: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  website: PropTypes.string,
};

SingleMerchant.defaultProps = {
  schedule: {},
  address: {},
  coupons: {},
  specials: {},
  facebook: '',
  instagram: '',
  twitter: '',
  website: '',
  selectedTab: 'info',
};
