import PropTypes from 'prop-types';
import { outboundLinks } from '../../globalConfig';
import styles from '../../styles/Auth.module.css';
import { ACTIONS, CATEGORIES } from '../../utils/GAConstants';
import logAnalyticsEvent from '../../utils/logAnalyticsEvent';

const AuthDisclaimer = ({ text }) => {
  return (
    <div className={styles.disclaimerWrapper}>
      <span className={styles.disclaimer}>
        {text}, you are agreeing to our{' '}
        <a
          href={outboundLinks.termsAndConditions}
          target="_blank"
          className={styles.privacyLink}
          rel="noreferrer"
          onClick={() =>
            logAnalyticsEvent({
              label: 'Terms and Conditions',
              action: ACTIONS.CLICK,
              category: CATEGORIES.EXTERNAL_LINK,
            })
          }
        >
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a
          href={outboundLinks.privacyPolicy}
          target="_blank"
          className={styles.privacyLink}
          rel="noreferrer"
          onClick={() =>
            logAnalyticsEvent({
              label: 'Privacy Policy',
              action: ACTIONS.CLICK,
              category: CATEGORIES.EXTERNAL_LINK,
            })
          }
        >
          Privacy Policy
        </a>
      </span>
    </div>
  );
};

AuthDisclaimer.propTypes = {
  text: PropTypes.string.isRequired,
};

export default AuthDisclaimer;
