import actionTypes from '../constants/actionTypes';

export const initialState = {
  loading: false,
  error: false,
  results: [],
};

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SEARCH_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        results: action.payload,
      };
    }
    case actionTypes.SEARCH_FAIL: {
      return {
        ...state,
        loading: false,
        results: [],
        error: action.payload
      };
    }
    default:
      return state;
  }
}

export default searchReducer;
