import { API, graphqlOperation } from 'aws-amplify';
import { createFavorite, deleteFavorite } from '../graphql/mutations';
import { listFavorites } from '../graphql/queries';
import requestAll from '../utils/requestAll';

export async function getFavorites(customerId) {
  const result = await requestAll(listFavorites, 'listFavorites', {
    filter: { customerID: { eq: customerId } },
  });

  const allFavorites = [];
  const check = {};
  result.forEach((fav) => {
    if (!check[fav.itemID]) {
      check[fav.itemID] = true;
      allFavorites.push(fav);
    }
  });

  return { filtered: allFavorites, raw: result };
}

export async function addItemToFavorites(itemID, customerID, type) {
  const favoriteObject = {
    itemID,
    customerID,
    type,
  };
  const result = await API.graphql(
    graphqlOperation(createFavorite, { input: favoriteObject })
  );
  return result.data.createFavorite;
}

export async function removeItemFromFavorites(favoriteID) {
  const itemToDelete = {
    id: favoriteID,
  };
  const result = await API.graphql(
    graphqlOperation(deleteFavorite, { input: itemToDelete })
  );
  return result.data;
}

export default {
  addItemToFavorites,
  removeItemFromFavorites,
  getFavorites,
};
