import actionTypes from '../constants/actionTypes';
import errorHandler from '../../utils/errorHandler';
import CouponService from '../../api/couponService';

const service = new CouponService();

export function getCouponsAction() {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.GET_COUPONS_START));
    try {
      const items = await service.getCoupons();
      dispatch(actionReducer(items, actionTypes.GET_COUPONS_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.GET_COUPONS_FAIL));
    }
  };
}

const actionReducer = (payload, type) => ({
  type,
  payload,
});
