import actionTypes from '../constants/actionTypes';
import CategoryService from '../../api/categoryService';
import errorHandler from '../../utils/errorHandler';

const categoryService = new CategoryService();

// Get Categories Action
export function getCategoriesAction() {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.GET_CATEGORIES_START));
    try {
      const categories = await categoryService.getCategories();
      dispatch(actionReducer(categories, actionTypes.GET_CATEGORIES_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.GET_CATEGORIES_FAIL));
    }
  };
}

export function getCategoryAction(categoryId) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.GET_CATEGORY_START));
    try {
      const category = await categoryService.getSingleCategory(categoryId);
      dispatch(actionReducer(category, actionTypes.GET_CATEGORY_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.GET_CATEGORY_FAIL));
    }
  };
}

const actionReducer = (payload, type) => ({
  type,
  payload,
});
