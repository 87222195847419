const indicatorProps = {
  style: {
    color: 'var(--saver-navbar)',
  },
};

const activeIndicator = {
  style: {
    color: 'var(--saver-red)',
  },
};

const navButtons = {
  style: {
    backgroundColor: 'transparent',
    borderRadius: 0,
    marginTop: '-50%',
  },
};

const categoryNext = (
  <img
    src="/icons/next.png"
    alt=""
    style={{ marginRight: '-1.5rem' }}
    data-testid="next-icon-button"
  />
);

const categoryPrev = (
  <img
    src="/icons/prev.png"
    alt=""
    style={{ marginLeft: '-1.5rem' }}
    data-testid="prev-icon-button"
  />
);

export {
  indicatorProps,
  activeIndicator,
  navButtons,
  categoryNext,
  categoryPrev,
};
