import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../styles/FooterElement.module.css';

const FooterElement = ({ path, dataTestId, label, title }) => (
  <Link
    to={path}
    data-testid={dataTestId}
    className={styles.footerLink}
    title={title}
  >
    {label}
  </Link>
);

FooterElement.propTypes = {
  path: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
};

FooterElement.defaultProps = { dataTestId: 'footer-element', title: '' };

export default FooterElement;
