import { useSelector } from 'react-redux';
import { Breadcrumbs, Modal } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ApartmentTabs from '../components/ApartmentTabs';
import ApartmentFilters from '../components/ApartmentFilters';
import styles from '../styles/Apartments.module.css';
import ApartmentsSearchBar from '../components/ApartmentsSearchBar';
import useTitle from '../hooks/useTitle';

const Apartments = () => {
  const items = useSelector((state) => state.properties.filtered);
  const [modalOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useTitle('Apartments');

  return (
    <div className={styles.container}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        className="filtersContainerSmall"
        onClose={handleClose}
        disableAutoFocus
        BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
      >
        <div className="filtersContainerSmallContent">
          <ApartmentFilters closeModal={handleClose} />
        </div>
      </Modal>

      <div className={styles.filtersContainer}>
        <ApartmentFilters />
      </div>
      <div className={styles.resultsContainer}>
        <div className={styles.tabsContainer}>
          <div
            className={styles.breadcrumb}
            style={{
              marginTop: '1rem',
              marginBottom: '0.5rem',
            }}
          >
            <Breadcrumbs listClassName={styles.breadcrumbList} separator="›">
              <Link color="inherit" to="/">
                Home
              </Link>
              <Link color="inherit" to="/apartments">
                Apartments
              </Link>
            </Breadcrumbs>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <ApartmentsSearchBar />
            <div>
              <button
                type="button"
                className={styles.openFilters}
                onClick={() => handleOpen()}
              >
                <img
                  alt="filters"
                  src="/icons/filters.png"
                  className="iconLeft"
                />{' '}
                Filters
              </button>
            </div>
          </div>
          <ApartmentTabs properties={items} />
        </div>
      </div>
    </div>
  );
};

export default Apartments;
