/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const STAGING_AND_PROD = {
  aws_project_region: 'us-east-2',
  aws_cognito_identity_pool_id:
    'us-east-2:b5fa6fce-3f59-41a1-8b84-40044d54d3d7',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_hNZljz0l6',
  aws_user_pools_web_client_id: '5suttmus4lon6q4ug2152q5h29',
  oauth: {
    domain: 'thesaver-production.auth.us-east-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'thesaver://,https://www.thesaver.com/',
    redirectSignOut: 'thesaver://,https://www.thesaver.com/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE', 'APPLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://ytm56eqjmrg3dhpb4aemkgdbva.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-oafb5krwqnabjjdoiawbzokwfy',
  aws_cloud_logic_custom: [
    {
      name: 'stripeAPI',
      endpoint:
        'https://gwbw2qbyid.execute-api.us-east-2.amazonaws.com/production',
      region: 'us-east-2',
    },
    {
      name: 'AdminQueries',
      endpoint:
        'https://nbkmmq9shh.execute-api.us-east-2.amazonaws.com/production',
      region: 'us-east-2',
    },
    {
      name: 'propertiesapi',
      endpoint:
        'https://vp5tv607h6.execute-api.us-east-2.amazonaws.com/production',
      region: 'us-east-2',
    },
    {
      name: 'appfolioAPI',
      endpoint:
        'https://mbaidud7i9.execute-api.us-east-2.amazonaws.com/production',
      region: 'us-east-2',
    },
    {
      name: 'searchEngineAPI',
      endpoint:
        'https://ccjml5tpqi.execute-api.us-east-2.amazonaws.com/production',
      region: 'us-east-2',
    },
  ],
  aws_user_files_s3_bucket: 'thesaverbucket150502-production',
  aws_user_files_s3_bucket_region: 'us-east-2',
  aws_mobile_analytics_app_id: '5fe40120e8fb4ab284bb7f4d37fa7bd5',
  aws_mobile_analytics_app_region: 'us-east-1',
  aws_cognito_login_mechanisms: [],
};

const MAPPING = {
  DEVELOPMENT: {
    aws_project_region: 'us-east-2',
    aws_cognito_identity_pool_id:
      'us-east-2:7e4daa8e-f653-45a8-b530-6990049ffb41',
    aws_cognito_region: 'us-east-2',
    aws_user_pools_id: 'us-east-2_dRCA6QGbN',
    aws_user_pools_web_client_id: '433ub7sb7tbqb7lngkiuq2516d',
    oauth: {
      domain: 'thesaver-dev.auth.us-east-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn:
        'thesaver://,https://www.thesaver.com/,https://staging.d2p7etfzomzq6s.amplifyapp.com/,http://localhost:3000/,https://develop.d2p7etfzomzq6s.amplifyapp.com/',
      redirectSignOut:
        'thesaver://,https://www.thesaver.com/,https://staging.d2p7etfzomzq6s.amplifyapp.com/,http://localhost:3000/,https://develop.d2p7etfzomzq6s.amplifyapp.com/',
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE', 'APPLE'],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OPTIONAL',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_appsync_graphqlEndpoint:
      'https://xdnjcvkq5rawzdzm5xfabivayu.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-ouxemc3iafai7kiuihlm55624a',
    aws_cloud_logic_custom: [
      {
        name: 'stripeAPI',
        endpoint: 'https://fwvyq3sgme.execute-api.us-east-2.amazonaws.com/dev',
        region: 'us-east-2',
      },
      {
        name: 'AdminQueries',
        endpoint: 'https://kccbxua2g4.execute-api.us-east-2.amazonaws.com/dev',
        region: 'us-east-2',
      },
      {
        name: 'propertiesapi',
        endpoint: 'https://v02ype9qf5.execute-api.us-east-2.amazonaws.com/dev',
        region: 'us-east-2',
      },
      {
        name: 'appfolioAPI',
        endpoint: 'https://1kqg1jjtog.execute-api.us-east-2.amazonaws.com/dev',
        region: 'us-east-2',
      },
      {
        name: 'searchEngineAPI',
        endpoint: 'https://lp4w3y30w4.execute-api.us-east-2.amazonaws.com/dev',
        region: 'us-east-2',
      },
    ],
    aws_user_files_s3_bucket: 'thesaverbucket120624-dev',
    aws_user_files_s3_bucket_region: 'us-east-2',
    aws_mobile_analytics_app_id: '4c8633221dc34c719740d09167e6037d',
    aws_mobile_analytics_app_region: 'us-east-1',
    aws_cognito_login_mechanisms: [],
  },
  STAGING: STAGING_AND_PROD,
  PRODUCTION: STAGING_AND_PROD,
};

if (!process.env.REACT_APP_STAGE) {
  console.warn(`Missing REACT_APP_STAGE variable - defaulting to DEVELOPMENT`);
}

export default MAPPING[process.env.REACT_APP_STAGE || 'DEVELOPMENT'];
