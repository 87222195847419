import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FavoriteItem from './FavoriteItem';
import styles from '../styles/MerchantListing.module.css';
import { encodeCategoryName } from '../utils/categoryNameEcoder';

export default function FavoriteCoupon({
  name,
  title,
  description,
  disclaimer,
  logo,
  category,
  couponId,
  redeemAction,
}) {
  return (
    <div className={styles.favCard}>
      <div className={styles.header}>
        <div className={styles.cardImage}>
          <img src={logo} alt="" width="100%" />
        </div>
        <div className={styles.cardTitle}>
          <div className={styles.topBar}>
            <Link
              to={`/categories/${category}/${encodeCategoryName(
                name
              )}?selectedTab=specials`}
            >
              <p className={styles.favCTitle}>{name}</p>
            </Link>
            <div>
              <FavoriteItem id={couponId} type="COUPON" />
            </div>
          </div>
          <p className={styles.favCPromo}>{title}</p>
          <div className={styles.subtitles} />
        </div>
      </div>
      <div className={styles.favBody}>
        <p className={styles.favContent}>{description}</p>
        <p className={styles.favDisclaimer}>{disclaimer}</p>
      </div>

      <div className={styles.singleFavButton}>
        <button
          type="button"
          className={styles.redeemButton}
          onClick={redeemAction}
        >
          Redeem now
        </button>
      </div>
    </div>
  );
}

FavoriteCoupon.propTypes = {
  couponId: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
  redeemAction: PropTypes.func.isRequired,
};
