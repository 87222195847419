import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Routes from './router/Routes';
import { getCategoriesAction } from './redux/actions/categoryActions';
import { getMerchantsAction } from './redux/actions/merchantActions';
import { getCouponsAction } from './redux/actions/couponActions';
import { getPropertiesAction } from './redux/actions/propertyActions';
import { getUserSessionAction } from './redux/actions/authActions';
import { getFavoritesAction } from './redux/actions/favoriteActions';
// import PhoneNumberConfirmation from './components/PhoneNumberConfirmation';

function App() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);

  useEffect(() => {
    dispatch(getCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMerchantsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCouponsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPropertiesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserSessionAction());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      dispatch(getFavoritesAction(profile.id));
    }
  }, [dispatch, profile]);

  return (
    <>
      {/* <PhoneNumberConfirmation /> */}
      <Routes />
    </>
  );
}

export default App;
