import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { includes, isEmpty } from 'ramda';
import {
  filterAllPropertiesAction,
  resetFiltersAction,
} from '../redux/actions/propertyActions';
import SaverSwitch from './SaverSwitch';
import styles from '../styles/Apartments.module.css';
import {
  buttonsObject,
  availableOptions,
  getDefaultOption,
} from '../utils/object';

const initialState = {
  range: [0, 5000],
  availableSeason: getDefaultOption().value,
  bedrooms: [],
  bathrooms: [],
  area: [],
  managerId: 'All',
  rentRangeType: 'UNIT',
  amenities: {
    airConditioning: false,
    offStreetParking: false,
    roommateMatchingServiceAvailable: false,
    dishwasher: false,
    furnished: false,
    dogsAllowed: false,
    catsAllowed: false,
    washerAndDryer: false,
    shortTermLeases: false,
    patioBalconyDeck: false,
    bedroomsKeyedSeparately: false,
    rampedEntry: false,
    exerciseFacility: false,
    pool: false,
  },
};

const ApartmentFilters = ({ closeModal }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const allMerchants = useSelector((state) => state.merchants.list);
  const [availableSeason, setAvailableSeason] = useState(
    initialState.availableSeason
  );
  const [range, setRange] = useState(initialState.range);
  const [bedrooms, setBedrooms] = useState(initialState.bedrooms);
  const [bathrooms, setBathrooms] = useState(initialState.bathrooms);
  const [area, setArea] = useState(initialState.area);
  const [managerId, setManagerId] = useState(id || initialState.managerId);
  const [checkedAmmenities, setCheckedAmmenities] = useState(
    initialState.amenities
  );
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false);

  const [managerOptions, setManagerOptions] = useState([]);
  const [maxPriceRange, setMaxPriceRange] = useState(initialState.range[1]);
  const [rentRangeType, setRentRangeType] = useState(
    initialState.rentRangeType || 'UNIT'
  );

  const onRentRangeTypeChange = (type) => {
    setRentRangeType(type);
    if (type === 'UNIT') {
      setMaxPriceRange(5000);
      setRange([initialState.range[0], 5000]);
    } else if (type === 'PERSON') {
      setMaxPriceRange(2000);
      setRange([initialState.range[0], 2000]);
    }
  };

  useEffect(() => {
    if (!isEmpty(allMerchants)) {
      const options = allMerchants.map((merchant) => {
        if (merchant.type === 'APARTMENT') {
          return {
            label: merchant.name,
            value: merchant.id,
          };
        }
        return null;
      });
      setManagerOptions([
        ...options.filter((op) => op !== null),
        {
          label: 'All Merchants',
          value: 'All',
        },
      ]);
    }
  }, [allMerchants]);

  const marks = [
    {
      value: 0,
      label: `$${range[0]}`,
    },
    {
      value: maxPriceRange,
      label: `$${range[1]}${range[1] === maxPriceRange ? '+' : ''}`,
    },
  ];

  const onResetAll = () => {
    setRange(initialState.range);
    setAvailableSeason(initialState.availableSeason);
    setBedrooms(initialState.bedrooms);
    setBathrooms(initialState.bathrooms);
    setArea(initialState.area);
    setManagerId(id || initialState.managerId);
    setCheckedAmmenities(initialState.amenities);
    setRentRangeType(initialState.rentRangeType);
    dispatch(resetFiltersAction());
    closeModal();
    history.push('/apartments');
  };

  const onSearchNow = () => {
    const ammenitiesSelected = Object.keys(checkedAmmenities).filter(
      (item) => checkedAmmenities[item] === true
    );
    dispatch(
      filterAllPropertiesAction(
        range[0],
        range[1],
        availableSeason,
        bedrooms,
        bathrooms,
        area,
        rentRangeType,
        managerId === 'All' ? '' : managerId,
        ammenitiesSelected
      )
    );
    closeModal();
    history.push('/apartments');
  };
  return (
    <>
      <div className={styles.filterHeader}>
        FILTERS
        <button type="button" className={styles.resetAll} onClick={onResetAll}>
          Reset all
        </button>
      </div>
      <div className={styles.filtersWrapper}>
        <div
          className={styles.singleAptTopContainer}
          style={{ width: '100%', marginTop: '1rem' }}
        >
          <span>Rent Range Per</span>
          <div
            className={styles.filterOptions}
            style={{ marginTop: '0.75rem' }}
          >
            <FormControl component="fieldset">
              <RadioGroup
                value={rentRangeType}
                onChange={(e, val) => onRentRangeTypeChange(val)}
                aria-label="rentType"
                name="rentType"
                row
              >
                <FormControlLabel
                  value="UNIT"
                  control={<Radio />}
                  label="Unit"
                />
                <FormControlLabel
                  value="PERSON"
                  control={<Radio />}
                  label="Person"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className={styles.filterOptions}>
            <Slider
              value={range}
              onChange={(e, val) => setRange(val)}
              valueLabelDisplay="auto"
              color="secondary"
              aria-labelledby="range-slider"
              min={initialState.range[0]}
              max={maxPriceRange}
              marks={marks}
            />
          </div>
        </div>

        <div className={styles.singleAptContainer} style={{ width: '100%' }}>
          <p>
            <img
              alt=""
              src="/icons/leasingStarts.svg"
              width="12px"
              style={{ marginTop: '0.1rem', marginRight: '0.5rem' }}
            />
            Leases starting
          </p>
          <div className={styles.filterOptions}>
            <FormControl
              className={styles.selectLeasing}
              style={{ marginTop: '1rem' }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                value={availableSeason}
                onChange={(e) => setAvailableSeason(e.target.value)}
              >
                {availableOptions.map((option) => {
                  return (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div
          className={styles.singleAptContainer}
          style={{ width: '100%', marginTop: '-1rem' }}
        >
          <p className={styles.aptSection}>
            <img
              alt=""
              src="/icons/bedrooms-dark.svg"
              width="12px"
              style={{ marginTop: '0.1rem', marginRight: '0.5rem' }}
            />
            Bedrooms
          </p>
          <div className={styles.buttonGroup} style={{ marginBottom: '1rem' }}>
            {buttonsObject.bedButtons.map((bButton) => {
              return (
                <button
                  key={bButton.value}
                  onClick={(e) => {
                    e.preventDefault();
                    if (includes(bButton.value, bedrooms)) {
                      setBedrooms(
                        bedrooms.filter((el) => el !== bButton.value)
                      );
                    } else {
                      setBedrooms((state) => [...state, bButton.value]);
                    }
                  }}
                  type="button"
                  className={
                    includes(bButton.value, bedrooms)
                      ? styles.selectedButton
                      : styles.defaultUnselected
                  }
                  style={{
                    fontSize: bButton.label === 'Studio' ? '10px' : '',
                    textAlign: 'center',
                  }}
                >
                  {bButton.label}
                </button>
              );
            })}
          </div>

          <p style={{ margin: 0 }}>
            <img
              alt=""
              src="/icons/bathrooms-dark.svg"
              width="12px"
              style={{ marginTop: '0.1rem', marginRight: '0.5rem' }}
            />
            Bathrooms
          </p>
          <div className={styles.buttonGroup}>
            {buttonsObject.bathButtons.map((bButton) => {
              return (
                <button
                  key={bButton.value}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (includes(bButton.value, bathrooms)) {
                      setBathrooms(
                        bathrooms.filter((el) => el !== bButton.value)
                      );
                    } else {
                      setBathrooms((state) => [...state, bButton.value]);
                    }
                  }}
                  className={
                    includes(bButton.value, bathrooms)
                      ? styles.selectedButtonLarge
                      : styles.defaultUnselectedLarge
                  }
                >
                  {bButton.label}
                </button>
              );
            })}
          </div>
        </div>

        <div
          className={styles.singleAptContainer}
          style={{ width: '100%', marginTop: '-1rem', marginBottom: '1rem' }}
        >
          <p style={{ marginBottom: 0 }}>
            <img
              alt=""
              src="/icons/area-dark.svg"
              width="12px"
              style={{ marginTop: '0.1rem', marginRight: '0.5rem' }}
            />
            Area
          </p>
          <div className={styles.buttonGroup} style={{ marginTop: '-1rem' }} />
          {buttonsObject.areaButtons.map((button) => (
            <div className={styles.areasContainer}>
              {button.section.map((item) => (
                <button
                  type="button"
                  key={item.value}
                  onClick={(e) => {
                    e.preventDefault();
                    if (includes(item.value, area)) {
                      setArea(area.filter((el) => el !== item.value));
                    } else {
                      setArea((state) => [...state, item.value]);
                    }
                  }}
                  className={
                    includes(item.value, area)
                      ? styles.selectedAreaBtn
                      : styles.areaBtn
                  }
                >
                  {item.label}
                </button>
              ))}
            </div>
          ))}
        </div>

        <div className={styles.buttonGroup} style={{ flexDirection: 'column' }}>
          <button
            type="button"
            className={styles.moreFilters}
            style={{ display: !moreOptionsVisible ? 'block' : 'none' }}
            onClick={() => setMoreOptionsVisible(!moreOptionsVisible)}
          >
            <img
              alt=""
              src="/icons/more-filters.svg"
              className="iconLeft"
              style={{ marginTop: '0.1rem' }}
            />
            More Filters
          </button>

          <div style={{ display: moreOptionsVisible ? 'block' : 'none' }}>
            <p>Property Management Company</p>
            <FormControl
              className={styles.selectLeasing}
              style={{ marginTop: '1rem', marginBottom: '1.5rem' }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                value={managerId}
                onChange={(e) => setManagerId(e.target.value)}
              >
                {managerOptions.map((option) => {
                  return (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <p>Search for these Amenities</p>
            <div style={{ marginTop: '1rem' }}>
              {buttonsObject.amenities.map((amenity) => {
                return (
                  <div key={amenity.value} className={styles.amenityInFilter}>
                    <div>
                      <p>{amenity.label}</p>
                    </div>
                    <div style={{ marginTop: '0.5rem' }}>
                      <SaverSwitch
                        checked={checkedAmmenities[amenity.value]}
                        onChange={(e) => {
                          setCheckedAmmenities({
                            ...checkedAmmenities,
                            [amenity.value]: e.target.checked,
                          });
                        }}
                        name={amenity.value}
                        checkedByDefault={false}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className={styles.lessFilters}
              style={{
                display: moreOptionsVisible ? 'block' : 'none',
              }}
              onClick={() => setMoreOptionsVisible(!moreOptionsVisible)}
            >
              Show less
            </button>
          </div>

          <button
            type="button"
            onClick={onSearchNow}
            className={styles.searchBtn}
          >
            Search now
          </button>
        </div>
      </div>
    </>
  );
};

ApartmentFilters.propTypes = {
  closeModal: PropTypes.func,
};

ApartmentFilters.defaultProps = {
  closeModal: () => {},
};

export default ApartmentFilters;
