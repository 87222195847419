import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GlassMagnifier } from 'react-image-magnifiers';
import HTMLFlipBook from 'react-pageflip';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import styles from '../styles/Magazine.module.css';
import { magazinePages } from '../utils/magazinePages';
import SaverSwitch from '../components/SaverSwitch';
import useTitle from '../hooks/useTitle';

const Magazine = () => {
  const [zoomLevel, setZoomLevel] = useState('100px');
  const [magnifier, setMagnifier] = useState(false);

  useTitle('Magazine');

  const zoomOptions = [
    { value: '250px', label: 'Largest' },
    { value: '200px', label: 'Large' },
    { value: '100px', label: 'Default' },
  ];

  const handleZoom = () => {
    setMagnifier(!magnifier);
  };

  useEffect(() => {
    if (magnifier === false) {
      setZoomLevel('0px');
    }
  }, [magnifier]);

  const Page = ({ page }) => {
    return (
      <div className={styles.page}>
        <GlassMagnifier
          imageAlt={page.name}
          imageSrc={page.src}
          magnifierBorderColor="rgba(255, 255, 255, .5)"
          magnifierSize={zoomLevel}
        />
      </div>
    );
  };

  Page.propTypes = {
    page: PropTypes.shape().isRequired,
  };

  return (
    <div className={styles.magazineWrapper}>
      <div className={styles.toolbar}>
        <FormControlLabel
          control={
            <SaverSwitch
              checked={magnifier}
              onChange={() => handleZoom()}
              name="magnifuer"
            />
          }
          label={<p>Zoom</p>}
        />
        {magnifier && (
          <FormControl size="small">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ marginRight: '0.5rem' }}>Magnifier size</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                value={zoomLevel}
                defaultValue="100px"
                size="sm"
                onChange={(e) => setZoomLevel(e.target.value)}
              >
                {zoomOptions.map((option) => {
                  return (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </FormControl>
        )}
      </div>
      <HTMLFlipBook
        width={400}
        height={600}
        size="stretch"
        minWidth={315}
        maxWidth={800}
        minHeight={400}
        maxHeight={900}
        drawShadow={false}
        showCover
        disableFlipByClick
        style={{ marginBottom: 0 }}
      >
        {magazinePages &&
          magazinePages.map((page) => {
            return (
              <div>
                <Page page={page} key={page.name} />
              </div>
            );
          })}
      </HTMLFlipBook>
      <span className={styles.disclaimer}>
        For a better experience viewing the magazine, rotate your device or take
        a look at it on a larger device.
      </span>
    </div>
  );
};

export default Magazine;
