/* eslint-disable class-methods-use-this */
import { API, graphqlOperation } from 'aws-amplify';
import { listSaverCategorys } from '../graphql/custom-queries';
import { getCategory } from '../graphql/queries';

export default class CategoryService {
  async getCategories() {
    const categories = await API.graphql({
      query: listSaverCategorys,
      authMode: 'API_KEY',
    });
    return categories.data.listCategorys.items;
  }

  async getSingleCategory(categoryId) {
    const category = await API.graphql(
      graphqlOperation(getCategory, { id: categoryId })
    );
    return category.data.getCategory;
  }
}
