import actionTypes from '../constants/actionTypes';
import errorHandler from '../../utils/errorHandler';
import { search } from '../../api/searchService';

export function searchAction(query) {
  return async (dispatch) => {
    dispatch(actionReducer(null, actionTypes.SEARCH_START));
    try {
      const results = await search(query);
      dispatch(actionReducer(results, actionTypes.SEARCH_SUCCESS));
    } catch (error) {
      errorHandler(error);
      dispatch(actionReducer(error, actionTypes.SEARCH_FAIL));
    }
  };
}

const actionReducer = (payload, type) => ({
  type,
  payload,
});
