import useTitle from '../hooks/useTitle';

const Maintenance = () => {
  useTitle('Maintenance');
  return (
    <div className="underConstruction">
      <img alt="" src="/images/maintenance.svg" />
      <span>
        Sorry, but this page is currently under maintenance.
        <br />
        It will be up and running again soon.
      </span>
    </div>
  );
};

export default Maintenance;
