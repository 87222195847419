import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import BarLoader from 'react-spinners/BarLoader';
import styles from '../../styles/Auth.module.css';
import { sendAuthCode } from '../../redux/actions/authActions';

export default function SignInForm({
  changeAction,
  resetAction,
  signInAction,
  createAccountAction,
  loading,
  socialMedia,
  formState,
}) {
  const dispatch = useDispatch();
  const sentToCode = useSelector((state) => state.auth.customAuth?.sendTo);
  const color = '#E11B22';
  const customStyle = css`
    display: flex;
    margin-bottom: 1rem;
    margin-top: -1rem;
    width: 80%;
    border-color: red;
    background: #ffffff;
    z-index: 1002;
  `;

  const [inputType, setInputType] = useState(null);
  const [invalidInput, setInvalidInput] = useState(false);

  const checkAuthType = useCallback(
    (user) => {
      setInvalidInput(false);
      setInputType(null);
      fetch(`${process.env.REACT_APP_PHOENIX_API_URL}/user/auth/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user }),
      })
        .then((res) => res.json())
        .then((res) => {
          const { userExists, authenticationType } = res;

          if (!userExists) setInvalidInput(true);
          else {
            setInputType(authenticationType);
            if (authenticationType === 'sms') {
              const { email } = res;
              changeAction({
                target: { name: 'email', value: email },
                persist: () => {},
              });
              signInAction(email);
            }
          }
        });
    },
    [signInAction, changeAction]
  );

  const continueDisabled = useMemo(() => {
    if ((formState.username || '').length < 6) return true;
    if (inputType === null) return false;
    if (inputType === 'sms' && (formState.code || '').length !== 6) return true;
    if (inputType === 'password' && (formState.password || '').length < 8)
      return true;
    return false;
  }, [formState, inputType]);

  useEffect(() => {
    setInputType(null);
    setInvalidInput(false);
  }, [formState.username]);

  const onAuthCodeSubmit = useCallback(() => {
    dispatch(sendAuthCode(formState.code));
  }, [formState, dispatch]);

  return (
    <div className={styles.signInform} style={{ width: '300px' }}>
      <h2 className={styles.primaryTitle}>Welcome Back</h2>
      <div className={styles.formData}>
        <input
          name="username"
          id="username"
          onChange={changeAction}
          placeholder="Phone Number or Email"
          className={styles.formInput}
        />
        {invalidInput && (
          <span className={styles.errorMessage}>
            Invalid Phone Number or Email
          </span>
        )}
        {inputType &&
          (inputType === 'password' ? (
            <>
              <input
                name="password"
                type="password"
                id="password"
                onChange={changeAction}
                placeholder="Password"
                className={styles.formInput}
              />
              <button
                type="button"
                onClick={resetAction}
                className={styles.resetPswd}
              >
                Forgot password?
              </button>
            </>
          ) : (
            sentToCode && (
              <>
                <input
                  name="code"
                  type="password"
                  id="code"
                  onChange={changeAction}
                  placeholder="Verification Code"
                  className={styles.formInput}
                  maxLength={6}
                />
                <span className={styles.sentCodeText}>
                  A code was sent to your number ending in {sentToCode}
                </span>
              </>
            )
          ))}
      </div>
      <button
        type="button"
        disabled={continueDisabled}
        onClick={
          inputType === null || invalidInput
            ? () => checkAuthType(formState.username)
            : sentToCode
            ? onAuthCodeSubmit
            : signInAction
        }
        className="mainButton"
        style={{
          width: '100%',
          padding: '3%',
          marginBottom: `${loading ? '1rem' : '0'}`,
        }}
      >
        {inputType ? 'Login' : 'Continue'}
      </button>
      {loading ? (
        <BarLoader
          loading={loading}
          color={color}
          override={customStyle}
          width="90%"
        />
      ) : (
        <>
          <p className={styles.signinLink} style={{ marginTop: '20px' }}>
            <span>Don’t already have an account?</span>
            <span
              onClick={createAccountAction}
              aria-hidden="true"
              role="button"
              className={styles.signUpLink}
            >
              sign up here
            </span>
          </p>
        </>
      )}

      <div>
        <div className={styles.divider}>
          <img alt="" src="/icons/divider.svg" width="70%" />
          <p className={styles.dividerLine}>or</p>
          <img alt="" src="/icons/divider.svg" width="70%" />
        </div>

        <p style={{ fontWeight: '500' }}>Log in with a social media account</p>
        <div className={styles.socialMediaContainer}>
          <button
            className={styles.mediaIcon}
            type="button"
            onClick={() => socialMedia('Google')}
          >
            <img
              alt=""
              src="/icons/googleColored.svg"
              className={styles.accountIcon}
            />
          </button>
          <button
            className={styles.mediaIcon}
            type="button"
            onClick={() => socialMedia('Facebook')}
          >
            <img
              alt=""
              src="/icons/fbColored.svg"
              className={styles.accountIcon}
            />
          </button>
          <button
            className={styles.mediaIcon}
            type="button"
            onClick={() => socialMedia('Apple')}
          >
            <img
              alt=""
              src="/icons/appleLogo.svg"
              className={styles.accountIcon}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

SignInForm.propTypes = {
  changeAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  signInAction: PropTypes.func.isRequired,
  createAccountAction: PropTypes.func.isRequired,
  socialMedia: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

SignInForm.defaultProps = {
  loading: false,
};
