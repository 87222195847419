import { Modal } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FavoritesTabs from '../components/FavoritesTabs';
import styles from '../styles/Favorites.module.css';
import RedeemModal from '../utils/redeemModalContent';
import { getFavoritesAction } from '../redux/actions/favoriteActions';
import { getUserSessionAction } from '../redux/actions/authActions';
import useTitle from '../hooks/useTitle';

const Favorites = () => {
  const [modalOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.auth);
  const favoritesIndexByType = useSelector(
    (state) => state.favorites.indexByType
  );

  useEffect(() => {
    dispatch(getUserSessionAction());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      dispatch(getFavoritesAction(profile.id));
    }
  }, [dispatch, profile]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useTitle('Favorites');

  return (
    <>
      <div className={styles.container}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="redeemModal"
          open={modalOpen}
          onClose={handleClose}
          disableAutoFocus
          BackdropProps={{ filter: 'blur(80px) brightness(10%)' }}
        >
          <div className={styles.modalContent}>
            <RedeemModal closeAction={handleClose} />
          </div>
        </Modal>

        <div className={styles.header}>
          <div className={styles.logo}>
            <img alt="" src="/icons/favoritesPage.svg" width="44px" />
          </div>
          <div className={styles.slogan}>
            <p className={styles.title}>My favorites</p>
            <span>
              You pick your favorites, we&apos;ll conveniently store them in one
              place for you.
            </span>
          </div>
        </div>

        <div className={styles.tabsWrapper}>
          <FavoritesTabs
            merchants={favoritesIndexByType.MERCHANT}
            coupons={favoritesIndexByType.COUPON}
            properties={favoritesIndexByType.PROPERTY}
            openModal={handleOpen}
          />
        </div>
      </div>
    </>
  );
};

export default Favorites;
