import ReactGA from 'react-ga4';

export default function logAnalyticsEvent({
  event,
  label,
  action,
  category,
  nonInteraction,
}) {
  ReactGA.event({
    action: action || event,
    category,
    label,
    nonInteraction,
  });
}
