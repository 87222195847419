import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input/input';

export default function SaverInput({
  type,
  changeAction,
  placeholder,
  icon,
  name,
  value,
  css,
  required,
}) {
  const inputStyle = {
    background: `url(${icon}) no-repeat scroll 7px 2px`,
    display: 'inline-block',
    paddingLeft: '30px',
    paddingBottom: '0.2rem',
    outline: 0,
    border: 'none',
    width: '100%',
    fontFamily: 'Montserrat, sans-serif',
    borderBottom: '1px solid var(--saver-underline)',
    margin: '0.75rem 1rem',
  };

  return name === 'phone' ? (
    <PhoneInput
      style={inputStyle}
      smartCaret={false}
      country="US"
      withCountryCallingCode
      international
      value={value}
      onChange={(phone) => changeAction({ target: { name, value: phone } })}
      placeholder="Mobile Phone Number*"
      iconStyle={{ width: 18, height: 18 }}
    />
  ) : (
    <input
      type={type}
      min={0}
      name={name}
      value={value}
      onChange={changeAction}
      placeholder={placeholder}
      className={css}
      required={required}
      style={inputStyle}
    />
  );
}

SaverInput.propTypes = {
  required: PropTypes.bool,
  css: PropTypes.string,
  type: PropTypes.string.isRequired,
  changeAction: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

SaverInput.defaultProps = {
  placeholder: '',
  icon: '',
  css: '',
  required: false,
};
