import { API } from 'aws-amplify';

export async function search(query) {
  const result = await API.get('searchEngineAPI', '/search', {
    queryStringParameters: { searchText: query },
  });
  return result.data;
}

export async function searchWith(query) {
  const result = await API.get('searchEngineAPI', '/search', {
    queryStringParameters: { searchText: query },
  });
  return result.data;
}
