import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { useEffect } from 'react';
import Home from '../views/Home';
import Auth from '../views/Auth';
import Apartments from '../views/Apartments';
import Layout from '../components/Layout';
import Categories from '../views/Categories';
import Merchant from '../views/Merchant';
import About from '../views/About';
import Contact from '../views/Contact';
import SaverAd from '../views/SaverAd';
import Favorites from '../views/Favorites';
import Profile from '../views/Profile';
import ApartmentDetails from '../views/ApartmentDetails';
import Magazine from '../views/Magazine';
import Subscribe from '../views/Subscribe';
import Offers from '../views/Offers';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/categories/:category" component={Categories} />
          <Route path="/categories/:category/:merchant" component={Merchant} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/saverAd" component={SaverAd} />
          <Route path="/auth" component={Auth} />
          <Route exact path="/apartments" component={Apartments} />
          <Route exact path="/favorites" component={Favorites} />
          <Route exact path="/profile" component={Profile} />
          <Route path="/apartments/results/:id" component={ApartmentDetails} />
          <Route exact path="/magazine" component={Magazine} />
          <Route exact path="/subscribe" component={Subscribe} />
          <Route exact path="/offers" component={Offers} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default Routes;
