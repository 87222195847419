import PropTypes from 'prop-types';

const Clickable = ({ onClick, dataTestId, children, className }) => (
  <span
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
  >
    {children}
  </span>
);

Clickable.propTypes = {
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
};

Clickable.defaultProps = {
  dataTestId: 'clickable',
  className: '',
};

export default Clickable;
